import styles from './patient-info-card.module.css';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../components/Buttons';
import { useState } from 'react';
import { InfoForm } from './info-form';
import { EditForm } from './edit-form';

const cx = classNames.bind(styles);

export const PatientInfoCard = () => {
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState(false);

  console.log('isEditing', isEditing);

  const cancelEdit = () => setIsEditing(false);

  return (
    <div className={cx('card-container', 'bs-20')}>
      {!isEditing ? <InfoForm /> : <EditForm cancelEdit={cancelEdit} />}

      {/* TODO: use the update user hook here and disable this button when loading */}
      <Button
        styleType='secondary'
        fullWidth={true}
        onClick={() => setIsEditing(curVal => !curVal)}
      >
        {isEditing ? t('cancel') : t('edit')}
      </Button>
    </div>
  );
};
