import styles from './notification-card.module.css';
import classNames from 'classnames/bind';
import Card from '../../../../components/Cards/Card';
import { GlobalService } from '../../../../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

const cx = classNames.bind(styles);

interface NotificationCardProps {
  id: number;
  title: string;
  message: string;
  path: string;
  seen: boolean;
  createdAt: string;
  onClickNotificationLink: (id: number) => void;
}

export const NotificationCard: FC<NotificationCardProps> = ({
  id,
  title,
  message,
  path,
  seen,
  createdAt,
  onClickNotificationLink,
}) => {
  const { t } = useTranslation();

  const handleClickNotification = (id: number) => {
    // console.log(id);
  };

  return (
    <Card
      name={id.toString()}
      width={500}
      numberItems={2}
      padding='1rem'
      borderRadius='10px'
      backgroundColor={!seen ? '#e96e810d' : '#fff'}
      border={`1px solid ${!seen ? '#FC0E0E' : '#EEEEEE'}`}
      action={() => handleClickNotification(id)}
      className='bs-30'
    >
      <div className={cx('notification-container')}>
        <div className={cx('card-header')}>
          <p className={cx('title')}>{GlobalService.limitText(title, 30)}</p>

          <p className={cx('date-text')}>
            {GlobalService.parseDateForNotification(
              createdAt,
              t('pages.topMenu.lt1hourtime'),
              t('pages.topMenu.1hourtime'),
              t('pages.topMenu.morehourtime'),
              t('pages.topMenu.at'),
            )}
          </p>
        </div>

        <p className='Notificaciones-Comment'>{GlobalService.limitText(message, 100)}</p>

        {path == '' ? null : (
          <a
            className='Notificaciones-Link'
            href={path}
            target='_blank'
            onClick={() => onClickNotificationLink(id)}
            rel='noreferrer'
          >
            {GlobalService.uppercaseFirstLetter(t('pages.topMenu.access'))}
          </a>
        )}
      </div>
    </Card>
  );
};
