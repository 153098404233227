import { GlobalService } from '../../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import {
  useSelectedPatient,
  useSelectedQuoteValue,
  useSetSelectedPatient,
  useSetSelectedQuote,
  useSetUrlToReturn,
  useUpdateDealAfterPayment,
  useViewport,
} from '../../hooks';
import { useSessionValue } from '../../hooks';
import { GoBackArrow, PaymentForm, TopMenu } from '../../components';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, PaymentIntent } from '@stripe/stripe-js';
import { tiendaRepositoryInstance } from '../../hexagonal-architecture-frontend-base/src/infrastructure/instances/tiendaRepository';
import { tiendaServices } from '../../hexagonal-architecture-frontend-base/src/infrastructure/services/tienda.service';
import { IAddressData } from 'src/hexagonal-architecture-frontend-base/src/domain/models/IAddressItem';
import ModalInfo from '../../components/Modals/ModalInfo';
import classNames from 'classnames/bind';
import styles from './visa-payment.module.css';

const key = process.env.REACT_APP_PUBLIC_STRIPE_KEY;
const cx = classNames.bind(styles);

const VisaPayment = () => {
  const { viewportWidth } = useViewport();
  const { selectedPatient, setSelectedPatient } = useSelectedPatient();
  const { userInfo } = useSessionValue();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { patientId, reportId } = useParams();
  const { selectedQuote } = useSelectedQuoteValue();
  const { updateDealAfterPayment } = useUpdateDealAfterPayment();
  const { setUrlToReturn } = useSetUrlToReturn();
  const { removeSelectedQuote } = useSetSelectedQuote();
  const { removeSelectedPatient } = useSetSelectedPatient();

  const [address, setAddress] = useState<IAddressData>();
  const [errorText, setErrorText] = useState<string>('');
  const [billingCycle] = useState<number>(1);
  const [isSubscription] = useState<boolean>(false);
  const [totalAmount] = useState<number>(selectedQuote?.Grand_Total || 0);
  const [infoText, setInfoText] = useState<string>('');
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
  const [showInfoModal, setShowInfoModal] = useState<boolean>(false);

  const stripe = useMemo(() => {
    if (key) {
      return loadStripe(key);
    }
  }, [key]);

  const userData = userInfo
    ? {
        id: userInfo.id,
        name: userInfo.name!,
        email: userInfo.email!,
        phone: userInfo.phone,
        stripeId: userInfo.stripeId!,
      }
    : undefined;
  const textBase = 'pages.informeDetallado';

  const isScreenBig = viewportWidth > 768;

  const handlePaymentResult = async (paymentResult: PaymentIntent | string) => {
    if (typeof paymentResult === 'string') {
      setErrorText('paymentConfirmationError');
      setShowErrorModal(true);
      return;
    }
    setInfoText('pages.tienda.successfulPayment');
    if (reportId && selectedPatient.title) {
      updateDealAfterPayment({
        DealId: selectedPatient.dealDetails.id,
        Deal_Name: selectedPatient.dealDetails.Deal_Name,
      });
    }
    setUrlToReturn(`/informes/${patientId}`);
    setShowInfoModal(true);
  };

  const fetchAddressData = async () => {
    tiendaServices(tiendaRepositoryInstance)
      .getBasketAddress('addressenviamentcomanda')
      .then(addresses => {
        if (typeof addresses === 'object') {
          const addressAux = addresses as unknown as IAddressData;
          setAddress({
            ...addressAux,
            Direcci_n: addressAux.Direcci_n || '',
            Codigo_postal: addressAux.Codigo_postal || '',
            Ciudad: addressAux.Ciudad || '',
            Pa_s: addressAux.Pa_s || '',
            Name: addressAux.Name || 'Principal',
            id: addressAux.id || '',
          });
        } else {
          setErrorText('genericError');
          setShowErrorModal(true);
        }
      })
      .catch(() => {
        setErrorText('genericError');
        setShowErrorModal(true);
      });
  };

  useEffect(() => {
    setSelectedPatient(patientId as string);
    fetchAddressData();
  }, [selectedPatient]);

  const handleOnCloseInfoModal = () => {
    setShowInfoModal(false);
    finishPayment();
  };

  const handleOnCloseErrorModal = () => {
    setShowErrorModal(false);
  };

  const finishPayment = () => {
    removeSelectedPatient();
    removeSelectedQuote();
    navigate(`/personas`);
  };

  // Info Modal
  const modalInfo = (
    <ModalInfo bodyText={infoText} buttonText={'close'} onClickOk={handleOnCloseInfoModal} />
  );

  // Error modal
  const modalError = (
    <ModalInfo
      bodyText={`errors.${errorText}`}
      onClickOk={handleOnCloseErrorModal}
      showXCloseButton={false}
      titleText={'error'}
    />
  );

  return (
    <div className={cx('main-container')}>
      <TopMenu
        title={
          isScreenBig
            ? `${GlobalService.uppercaseFirstLetter(t(`${textBase}.mainTitle`))}: ${
                selectedPatient.title
              }`
            : GlobalService.uppercaseFirstLetter(t(`pages.paymentMethod.paymentvisa`))
        }
        leftIcon={!isScreenBig}
        leftIconLink={-1}
      />
      {isScreenBig ? (
        <>
          <div className={cx('title--container')}>
            <GoBackArrow />

            <h3 className={cx('title--text')}>
              {GlobalService.uppercaseFirstLetter(t('pages.paymentMethod.paymentvisa'))}
            </h3>
          </div>
        </>
      ) : null}

      <div className={cx('body-container-main')}>
        <div className={cx('body-container', 'bs-20')}>
          <p>{t(`pages.pagos.completNecessaryData`)}</p>
          <p>
            {t(`modal.transferencia.amount`)} {totalAmount} €
          </p>

          {address && userData && stripe && (
            <div className={cx('form-container')}>
              <Elements stripe={stripe}>
                <PaymentForm
                  userData={userData}
                  addressData={address}
                  totalAmount={totalAmount}
                  handlePaymentResult={handlePaymentResult}
                  isLoading={false}
                  billingCycle={billingCycle}
                  isSubscription={isSubscription}
                  service={selectedPatient.dealDetails.id}
                />
              </Elements>
            </div>
          )}
          {showInfoModal ? modalInfo : null}
          {showErrorModal ? modalError : null}
        </div>
      </div>
    </div>
  );
};

export default VisaPayment;
