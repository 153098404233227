import { useAtomValue } from 'jotai';
import { afterUpdatePatientEffect, updatePatientAtom } from '../../../store/global/atoms';

export const useUpdatePatient = () => {
  const updatePatientBase = useAtomValue(updatePatientAtom);

  useAtomValue(afterUpdatePatientEffect);

  return {
    updatePatientResult: updatePatientBase.data,
    updatePatient: updatePatientBase.mutate,
    updatePatientAsync: updatePatientBase.mutateAsync,
    error: updatePatientBase.error,
    isLoading: updatePatientBase.isPending,
  };
};
