import { useTranslation } from 'react-i18next';
import { NOTIFICATION_ID_TO_NAME } from '../../consts';
import { Notification } from '../../ts';
import { usePatientsValue } from '../patients';

export const useGetNotificationExtraInfo = () => {
  const { t } = useTranslation();
  const { patients } = usePatientsValue();

  const getNotificationExtraInfo = (notification: Notification) => {
    const notificationName = NOTIFICATION_ID_TO_NAME[notification.notificationType];
    const patientName = patients.find(patient => patient.id === notification.patientId)?.title;
    const title = t(`notifications.${notificationName}.title`);
    const message = t(`notifications.${notificationName}.body`, { patientName });
    const shortMessage = t(`notifications.${notificationName}.shortBody`, { patientName });
    const pathDocumento = `/documentos/${notification.patientId}`;
    const pathQuote = `/informes/${notification.patientId}`;
    const notificationToPath = {
      1: pathQuote,
      2: pathDocumento,
    };
    const path = notificationToPath[notification.notificationType];

    return {
      title,
      message,
      shortMessage,
      path,
    };
  };

  return { getNotificationExtraInfo };
};
