import { faDisplay, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const VideoCallIcon = ({ color = '#E96E81' }) => {
  return (
    <span className='fa-layers fa-fw'>
      <FontAwesomeIcon color={color} icon={faDisplay} />
      <FontAwesomeIcon color={color} icon={faUser} size='xs' transform='shrink-4' />
    </span>
  );
};
