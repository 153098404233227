import React from 'react';
import Loader from '../Loaders/Loader';

interface Props {
  size?: string;
  border?: string;
}

export const RegularSpinner: React.FC<Props> = ({ size = '50px', border = '8px' }) => {
  return (
    <div className='w-full flex justify-center'>
      <Loader type='regular' size={size} border={border} />
    </div>
  );
};

export default RegularSpinner;
