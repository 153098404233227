import { useAtom, useAtomValue } from 'jotai';
import { useEffect, useLayoutEffect } from 'react';
import {
  betweenSMSIntervalAtom,
  lastSMSTimestampAtom,
  timeLeftForNextSMSAtom,
} from '../../../../store/global/atoms';
import { TIME_BETWEEN_SMS } from '../../../../consts';

export const useLastSMSIntervalEffect = () => {
  const [betweenSMSInterval, setBetweenSMSInterval] = useAtom(betweenSMSIntervalAtom);
  const [timeLeftForNextSMS, setTimeLeftForNextSMS] = useAtom(timeLeftForNextSMSAtom);
  const lastSMSTimestamp = useAtomValue(lastSMSTimestampAtom);

  useLayoutEffect(() => {
    if (lastSMSTimestamp == null) return;

    const interval = setInterval(() => {
      const now = new Date().getTime();
      const lastSMSTimestampDate = new Date(lastSMSTimestamp).getTime();
      const timeSinceLastSMS = now - lastSMSTimestampDate;
      let timeLeft = TIME_BETWEEN_SMS - timeSinceLastSMS;
      timeLeft = Math.floor(timeLeft / 1000);
      if (timeLeft < 0) timeLeft = 0;

      setTimeLeftForNextSMS(timeLeft);
    }, 100);

    setBetweenSMSInterval(interval);

    return () => clearInterval(interval);
  }, [lastSMSTimestamp]);

  useEffect(() => {
    if ((lastSMSTimestamp == null || timeLeftForNextSMS === 0) && betweenSMSInterval != null) {
      clearInterval(betweenSMSInterval);
      setBetweenSMSInterval(null);
    }
  }, [lastSMSTimestamp, timeLeftForNextSMS]);

  useEffect(() => {
    if (lastSMSTimestamp == null && betweenSMSInterval == null) {
      setTimeLeftForNextSMS(-1);
    }
  }, [lastSMSTimestamp, betweenSMSInterval]);
};
