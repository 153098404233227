import styles from './popup-iframe.module.css';
import classNames from 'classnames/bind';
import { FC } from 'react';

const cx = classNames.bind(styles);

interface PopupIframeProps {
  url: string | URL;
}

export const PopupIframe: FC<PopupIframeProps> = ({ url }) => {
  url = url.toString();

  return <iframe src={url} className={cx('iframe')}></iframe>;
};
