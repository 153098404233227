import { useAtomValue, useSetAtom } from 'jotai';
import {
  mobileVerificationTimestampsAtom,
  timeLeftForNextBlockAtom,
  timeLeftForNextSMSAtom,
} from '../../../../store/global/atoms/mobile/mobile-verification-timestamps-atoms';
import { useEffect } from 'react';

export const resetTimestampWhenAllTimesAreZeroEffect = () => {
  const timeLeftForNextBlock = useAtomValue(timeLeftForNextBlockAtom);
  const timeLeftForNextSMS = useAtomValue(timeLeftForNextSMSAtom);
  const setMobileVerificationTimestamps = useSetAtom(mobileVerificationTimestampsAtom);

  useEffect(() => {
    if (timeLeftForNextBlock !== 0 || timeLeftForNextSMS !== 0) return;

    setMobileVerificationTimestamps(null);
  }, [timeLeftForNextBlock, timeLeftForNextSMSAtom]);
};
