import axios from 'axios';
import { UserSignData } from '../../ts/interfaces/user';

interface SaveUserSignDataParams {
  data: UserSignData;
  tutorId: number;
  patientId: string;
  quoteId: string;
}

export const saveUserSignDataService = async ({
  data,
  tutorId,
  patientId,
  quoteId,
}: SaveUserSignDataParams) => {
  console.log('SAVE SIGN DATA', data);
  let returnValue;
  try {
    const response = await axios.post(`/users/savesigndata`, { tutorId, patientId, quoteId, data });

    console.log('response', response.data);

    returnValue = response.data.Prisma as UserSignData;
  } catch (err) {
    return 'verifyInvalid';
  }
  return returnValue;
};
