import axios from 'axios';
import { ApiResponse } from 'src/ts';

interface SendMobileVerificationCodeResponseData {
  dateCreated: string;
  sid: string;
  status: string;
  valid: boolean;
}

type SendMobileVerificationCodeResponse = ApiResponse<SendMobileVerificationCodeResponseData>;

export const sendMobileVerificationCode = async (mobile: string) => {
  mobile = sanitizeMobile(mobile);

  try {
    const response = await axios.post<SendMobileVerificationCodeResponse>(
      '/users/send-mobile-verification-code',
      { mobile },
    );
    return response;
  } catch (e: any) {
    throw new Error(e.message);
  }
};

const sanitizeMobile = (mobile: string) => {
  if (mobile[0] === '+') {
    return mobile;
  }

  if (mobile.substring(0, 2) === '34') return `+${mobile}`;

  return `+34${mobile}`;
};
