import { atom } from 'jotai';
import { allQuotesAtom } from '../getters/get-patients-quotes-atom';

const QUOTE_STAGES = {
  ACEPTADO: 'presupuesto aceptado',
};

export const paidAndUnpaidItemsAtom = atom(get => {
  const allQuotes = get(allQuotesAtom);

  if (allQuotes == null) return { paidItems: [], unpaidItems: [] };

  const itQuotes = allQuotes.filter(quote => quote.Tipo_de_informe.toLocaleLowerCase() === 'it');

  const paidItems = itQuotes
    .filter(
      quote =>
        quote.Quote_Stage.toLocaleLowerCase() === QUOTE_STAGES.ACEPTADO &&
        quote.Estado_del_pago === 'pagado',
    )
    .sort((a, b) => (a.Subject > b.Subject ? 1 : -1));
  const unpaidItems = itQuotes
    .filter(
      quote =>
        quote.Quote_Stage.toLocaleLowerCase() !== QUOTE_STAGES.ACEPTADO ||
        quote.Estado_del_pago !== 'pagado',
    )
    .sort((a, b) => (a.Subject > b.Subject ? 1 : -1));

  return {
    paidItems,
    unpaidItems,
  };
});
