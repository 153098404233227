import { GlobalService } from '../../../../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import { useSelectedPatientValue } from '../../../../hooks';
import { useTranslation } from 'react-i18next';

export const InfoForm = () => {
  const { t } = useTranslation();
  const { selectedPatient } = useSelectedPatientValue();

  const fieldsDefinition = [
    {
      label: 'fullName',
      value: selectedPatient.title,
      field: 'name',
      // onChange: (e: string) => setSelectedPatient(patient => ({ ...patient, title: e }))
    },
    {
      label: 'dni',
      value: selectedPatient.dni,
      field: 'dni',
      canBeEdited: true,
    },
    {
      label: 'residence',
      value: selectedPatient.residence,
      field: 'residence',
      // onChange: (e: string) => setSelectedPatient(patient => ({ ...patient, residence: e }))
    },
    {
      label: 'extras',
      value: selectedPatient.pendingDocs
        ? GlobalService.uppercaseFirstLetter(t('pages.personas.pendingProcedures'))
        : GlobalService.uppercaseFirstLetter(t('pages.documentos.noPendingProcedures')),
      field: 'pendingDocs',
      // onChange: (e: boolean) => setSelectedPatient(patient => ({ ...patient, pendingDocs: e }))
    },
  ];

  return (
    <div className={'flex flex-col rounded-md w-full relative gap-2'}>
      {fieldsDefinition.map((field, index) => {
        return (
          <div key={index}>
            <p className={`Personas-Label`}>{`${GlobalService.uppercaseFirstLetter(
              t(field.label),
              false,
            )}:`}</p>
            <p className='Personas-Info'>{field.value}</p>
          </div>
        );
      })}
    </div>
  );
};
