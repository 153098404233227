import { atomWithQuery } from 'jotai-tanstack-query';
import { DEFAULT_ADDRESS, QUERY_KEYS } from '../../../../../consts';
import { getUserAddressService } from '../../../../../services';

export const getUserAddressAtom = atomWithQuery(get => ({
  queryKey: [QUERY_KEYS.PAYMENT.USER.ADDRESS],
  queryFn: getUserAddressService(get),
  staleTime: Infinity,
  refetchInterval: false,
  initialData: DEFAULT_ADDRESS,
}));
