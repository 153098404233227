import '../../styles/Help/Help.css';
import styles from './payment-method.module.css';
import classNames from 'classnames/bind';

import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// Components
import { Button, TopMenu, GoBackArrow } from '../../components';

// Services
import { GlobalService } from '../../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import { useTranslation } from 'react-i18next';

// Interfaces

// Images
import Callcenter from '../../img/HelpImg/helpLuracare.png';

// Hooks
import { useViewport } from '../../hooks/use-viewport';
import { useSelectedPatient, useSessionValue, useSetSelectedQuote } from '../../hooks';

const cx = classNames.bind(styles);

const PaymentMethod = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { patientId, reportId } = useParams();
  const textBase = 'pages.paymentMethod';
  const total = localStorage.getItem('tot');
  const { userInfo } = useSessionValue();
  const { selectedPatient, setSelectedPatient } = useSelectedPatient();
  const { setSelectedQuote, removeSelectedQuote } = useSetSelectedQuote();

  const tutorName = userInfo?.name;

  /**********
   * States *
   **********/
  const { isScreenBig } = useViewport();

  /*********
   * Hooks *
   *********/
  useEffect(() => {
    setSelectedPatient(patientId as string);
  }, [patientId]);

  useEffect(() => {
    if (reportId == null) {
      removeSelectedQuote();
      return;
    }
    setSelectedQuote(reportId);
  }, [reportId]);

  const handleNagitaePaymentMethod = (key: string) => {
    navigate(`/informeDetallado/${patientId}/${reportId}/paymentmethod/${key}payment`);
  };

  const handleOnClickSelectPaymentMethod = (key: string) => {
    if (total) {
      switch (key) {
        case 'visa':
          handleNagitaePaymentMethod(key);
          break;
        case 'transfer':
          handleNagitaePaymentMethod(key);
          break;
        case 'split':
          handleNagitaePaymentMethod(key);
          break;
      }
    }
  };

  /**************
   * JSX Values *
   **************/
  document.title = `LuraCare - ${GlobalService.uppercaseFirstLetter(
    t(`${textBase}.selectPaymentMethod`),
  )}`;

  // Buttons definitions
  const buttonsDefinition: string[] = ['visa', 'transfer', 'split'];
  const isFinancAvailable = (button: string) => {
    return total && button === 'split' ? parseInt(total.replace('.', '')) >= 300 : true;
  };

  // Los botones
  const helpButtons = buttonsDefinition
    .filter(button => button !== 'split' || isFinancAvailable(button))
    .map((button, index) => (
      <Button
        key={index}
        onClick={() => handleOnClickSelectPaymentMethod(buttonsDefinition[index])}
        fullWidth={true}
      >
        {GlobalService.uppercaseFirstLetter(
          t(`${textBase}.${button === 'split' ? button + 'Short' : button}`),
        )}
      </Button>
    ));

  const backLink = `/informeDetallado/${patientId}/${reportId}`;

  return (
    <div className={cx('main-container')}>
      <TopMenu
        title={GlobalService.uppercaseFirstLetter(t(`${textBase}.title`))}
        userName={isScreenBig ? tutorName : undefined}
        leftIcon={!isScreenBig}
        leftIconLink={backLink}
      />

      <div className={cx('title--container')}>
        {isScreenBig ? <GoBackArrow to={backLink} /> : null}

        <h3
          className={cx('title--text')}
          style={{
            display: 'block',
            overflowWrap: 'break-word',
            maxWidth: '100%',
            lineClamp: 2,
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
          }}
        >
          {GlobalService.uppercaseFirstLetter(t(`${textBase}.selectPaymentMethod`))}
        </h3>
      </div>

      <div className={cx('body-container')}>
        <div className={cx('buttons-container')}>{helpButtons}</div>

        <div className='w-[497px] hidden lg:flex'>
          <img src={Callcenter} width='100%' alt='help image' />
        </div>
      </div>
    </div>
  );
};

export default PaymentMethod;
