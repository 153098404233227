import styles from './all-patients.module.css';
import classNames from 'classnames/bind';

// Components
import Input from '../../components/Inputs/InputOld';
import RegularSpinner from '../../components/Spinner/RegularSpinner';
import { TopMenu } from '../../components';

// Services
import { GlobalService } from '../../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import { useTranslation } from 'react-i18next';

// CSS
import '../../styles/Personas/Personas.css';

// Images
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Hooks
import { useViewport } from '../../hooks/use-viewport';
import { usePatientsPagination, usePatientsValue } from '../../hooks';
import { PatientCard } from '../../components/patients';

const cx = classNames.bind(styles);

export const AllPatients = () => {
  const { t } = useTranslation();
  const { isLoading: isPersonasLoading } = usePatientsValue();
  const { patientsToShow, searchString, setSearchString, ref } = usePatientsPagination();
  const { viewportWidth } = useViewport();

  // TODO - remove in the future
  localStorage.removeItem('AllPatients');
  localStorage.removeItem('tot');

  /************
   * Handlers *
   ************/
  const handleChangeSearchString = (e: string) => {
    setSearchString(e);
  };

  /***************
   *  JSX Values *
   ***************/
  const isScreenBig = viewportWidth > 768;

  return (
    <div className={cx('main-container')}>
      <TopMenu title={GlobalService.uppercaseFirstLetter(t('people'))} />
      <div className={cx('patients-list')}>
        <div className={cx('patients-list__container')}>
          <label
            className={`search-input-container flex items-center bg-white min-w-min cursor-text ${
              isScreenBig ? '' : 'mt-20'
            }`}
          >
            <FontAwesomeIcon icon={faMagnifyingGlass} />
            <Input
              id='searchInput'
              type='text'
              placeholder={GlobalService.uppercaseFirstLetter(t('search'))}
              value={searchString}
              customClass='search-input'
              name='searchInput'
              onChange={handleChangeSearchString}
            />
          </label>

          {isPersonasLoading ? (
            <RegularSpinner />
          ) : patientsToShow.length ? (
            <>
              <div className='grid 3xs:grid-cols-0 md:grid-cols-2 3xs:gap-2 mt-4 md:gap-x-8 md:gap-y-2'>
                {patientsToShow.map(item => (
                  <PatientCard key={item.id} patientInfo={item} />
                ))}
              </div>
              <div ref={ref}></div>
            </>
          ) : (
            <p>{GlobalService.uppercaseFirstLetter(t('nodata'))}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default AllPatients;
