import axios from 'axios';

interface VerifyMobileVerificationCodeServiceParams {
  mobile: string;
  code: string;
}
export const verifyMobileVerificationCodeService = async ({
  mobile,
  code,
}: VerifyMobileVerificationCodeServiceParams) => {
  if (mobile[0] !== '+') mobile = `+${mobile}`;

  try {
    const response = await axios.post('/users/verify-mobile-verification-code', { mobile, code });
    console.log('response', response);
    return response;
  } catch (e: any) {
    throw new Error(e.message);
  }
};
