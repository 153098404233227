// CSS
import styles from './login-signup-layout.module.css';
import React, { type FC } from 'react';
import classNames from 'classnames/bind';
import { NavbarLoginSignup } from '../navbar-login-signup';
import { LuraCareLogo } from '../../logos';
import { GlobalService } from '../../../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import { useTranslation } from 'react-i18next';

interface LoginSignupLayoutProps {
  type?: 'login' | 'signup' | 'signupVerifyMobile';
  children: React.ReactNode;
}

const cx = classNames.bind(styles);

const typeToLocale = {
  login: 'pages.login.title',
  signup: 'pages.signup.title',
  signupVerifyMobile: 'pages.signup.titleVerifyMobile',
};

export const LoginSignupLayout: FC<LoginSignupLayoutProps> = ({ type = 'login', children }) => {
  const { t } = useTranslation();

  return (
    <div className={cx('main-login-container')} style={{ zIndex: 0 }}>
      <NavbarLoginSignup />

      <div className={cx('login-wrapper')}>
        <div className={cx('login-container')}>
          <div className={cx('video-container')}>
            <video
              autoPlay
              muted
              playsInline
              className={cx('video')}
              loop
              src={'https://luracare.es/wp-content/uploads/2023/09/AdobeStock_240914212.mov'}
            />
          </div>

          <div className={cx('login-textContent-container')}>
            <div className={cx('login-title-container')}>
              <LuraCareLogo className={{ svg: cx('lura-care-logo') }} hideFooterText={true} />

              <h2 className={cx('login-text')}>
                {GlobalService.uppercaseFirstLetter(t(typeToLocale[type]))}
              </h2>
            </div>

            <div className={cx('login-signup-body-container')}>{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
