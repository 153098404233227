import { useLocation } from 'react-router-dom';
import { LoginSignupType } from 'src/ts';

const typeToHref = {
  login: '/signup',
  signup: '/login',
};

export const useLoginSignupSwitchLink = (type: LoginSignupType) => {
  const location = useLocation();

  const link = `${typeToHref[type]}${location.search}`;

  return { link };
};
