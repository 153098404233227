import styles from './notifications-container.module.css';
import classNames from 'classnames/bind';
import { GlobalService } from '../../../../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { NotificationCard } from '../notification-card';
import { FC } from 'react';
import { Notification } from '../../../../ts';
import { Button } from '../../button';
import { useGetNotificationExtraInfo } from '../../../../hooks';
import { useTranslation } from 'react-i18next';

const cx = classNames.bind(styles);

interface NotificationsContainerProps {
  notifications: Notification[];
  closeNotifications: () => void;
  onClickNotificationLink: (id: number) => void;
  handleSeeAllNotifications: () => void;
}

export const NotificationsContanier: FC<NotificationsContainerProps> = ({
  notifications,
  closeNotifications,
  onClickNotificationLink,
  handleSeeAllNotifications,
}) => {
  const { t } = useTranslation();
  const { getNotificationExtraInfo } = useGetNotificationExtraInfo();

  return (
    <div className={cx('notifications-container')}>
      <div className={cx('notifications-header')}>
        <p>
          <strong>{GlobalService.uppercaseFirstLetter(t('pages.topMenu.notifications'))}</strong>
        </p>
        <FontAwesomeIcon
          className={cx('close_icon')}
          icon={faXmark}
          onClick={() => closeNotifications()}
        />
      </div>

      <div
        className={cx('notifications_body', {
          'notifications-body__few-notifications': notifications.length <= 2,
        })}
      >
        {notifications.length > 0 ? (
          <div className={cx('notifications-scroll-container')}>
            {notifications.map((notification: Notification) => {
              const { title, shortMessage, path } = getNotificationExtraInfo(notification);

              return (
                <NotificationCard
                  key={`${notification.id}${notification.createdAt}`}
                  id={notification.id}
                  title={title}
                  message={shortMessage}
                  path={path}
                  seen={notification.seen}
                  createdAt={notification.createdAt}
                  onClickNotificationLink={onClickNotificationLink}
                />
              );
            })}
          </div>
        ) : null}

        <Button
          styleType='onlyText'
          onClick={handleSeeAllNotifications}
          className={cx('link-notificaciones')}
        >
          {GlobalService.uppercaseFirstLetter(t('viewAll'))}
        </Button>
      </div>
    </div>
  );
};
