import { PopupOptionsSetter } from 'src/ts';
import { usePopup } from '../use-popup';
import { PopupIframe } from '../../../components';

export const useIframePopup = () => {
  const { openPopup } = usePopup();

  const showIframePopup = (url: string | URL, title = '') => {
    const iframePopupSettings: PopupOptionsSetter = {
      title: title,
      children: <PopupIframe url={url} />,
      disableClickOutside: true,
      isXl: true,
    };

    openPopup(iframePopupSettings);
  };

  return { showIframePopup };
};
