import axios from 'axios';
import type { ApiResponse, UpdateQuotePaymentTypeParams } from 'src/ts';

export const updateQuotePaymentTypeService = async (data: UpdateQuotePaymentTypeParams) => {
  let result: ApiResponse<any>;

  try {
    const response = await axios.patch<ApiResponse<any>>(
      '/patients/update-quote-payment-type',
      data,
    );

    result = response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }

  if (!result.isOk) {
    throw new Error(result.message);
  }

  return result;
};
