import styles from './button.module.css';
import { FC } from 'react';
import { ButtonBase } from '../button-base';
import { ButtonProps } from '../../../ts';
import classNames from 'classnames/bind';
import Loader from '../../../components/Loaders/Loader';

const cx = classNames.bind(styles);

export const Button: FC<ButtonProps> = ({
  children,
  styleType = 'primary',
  isLoading = false,
  onClick,
  disabled = false,
  fullWidth = false,
  className = '',
  type = 'button',
  ...rest
}) => {
  return (
    <ButtonBase
      onClick={isLoading || disabled ? () => {} : onClick}
      paddingX={18}
      paddingY={10}
      // loading={isLoading}
      disabled={disabled}
      buttonWidth={fullWidth ? '100%' : undefined}
      className={cx(
        'button',
        {
          primary: styleType === 'primary',
          secondary: styleType === 'secondary',
          tertiary: styleType === 'tertiary',
          error: styleType === 'error',
          onlyText: styleType === 'onlyText',
        },
        className,
      )}
      type={type}
      {...rest}
    >
      {isLoading ? <Loader size='23px' border='3px' /> : children}
    </ButtonBase>
  );
};
