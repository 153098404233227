import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Components
import { Pagination } from '../components/Pagination';
import RegularSpinner from '../components/Spinner/RegularSpinner';
import Title from '../components/Title';
import { TopMenu } from '../components';

// Services
import { GlobalService } from '../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import { notificationsServices } from '../hexagonal-architecture-frontend-base/src/infrastructure/services/notifications.service';
import { notificationsRepositoryInstance } from '../hexagonal-architecture-frontend-base/src/infrastructure/instances/notificationsRepository';
import { useTranslation } from 'react-i18next';

// CSS
import '../styles/Notificaciones/Notificaciones.css';

// Images
import LeftArrow from '../img/Notificaciones/left-arrow.png';
import LeftArrowSmall from '../img/PersonaImg/left-arrow-short.png';
import { INotification } from 'src/hexagonal-architecture-frontend-base/src/domain/models/INotification';

// Hooks
import {
  useViewport,
  useSessionValue,
  useGetNotificationExtraInfo,
  useShowErrorPopup,
} from '../hooks';
import { NotificationCard } from '../components/Buttons/notifications-button/notification-card';

const Notificaciones = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { userInfo } = useSessionValue();
  const { getNotificationExtraInfo } = useGetNotificationExtraInfo();
  const { showErrorPopup } = useShowErrorPopup();

  /**********
   * States *
   **********/
  const [numberItems] = useState<number>(8);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [notifications, setNotifications] = useState<INotification[]>([]);
  const [totalPages, setTotalPages] = useState<number>(0);
  const { viewportWidth } = useViewport();
  const [topMenuUpdate, setTopMenuUpdate] = useState<number>(0);

  const tutorName = userInfo?.name;

  // Get data
  const getNotificationsData = () => {
    setTimeout(() => {
      getNotificationsList(currentPage);
      notificationsServices(notificationsRepositoryInstance)
        .getNotificationsCount()
        .then(count => {
          if (typeof count !== 'string') {
            setTotalPages(Math.ceil(count / numberItems));
          } else {
            setTotalPages(0);
            showErrorPopup(count);
          }
        });
    }, 1000);
  };

  useEffect(() => {
    getNotificationsData();
  }, []);

  /************
   * Handlers *
   ************/
  const handleBackButton = () => {
    const backRoute = localStorage.getItem('backRoute');
    navigate(backRoute as string);
  };

  const handlePagination = (e: number) => {
    setCurrentPage(e);
    setIsLoading(true);
    getNotificationsList(e);
  };

  /**************
   * Functions *
   **************/
  const getNotificationsList = (page: number) => {
    notificationsServices(notificationsRepositoryInstance)
      .getNotifications(numberItems, page)
      .then(data => {
        if (typeof data !== 'string') {
          setNotifications(data);
        } else {
          setNotifications([]);
        }

        setIsLoading(false);
        setTopMenuUpdate(topMenuUpdate => topMenuUpdate + 1);
      });
  };

  const toggleNotification = (id: number) => {
    setNotifications(
      notifications.map((item: any) => {
        if (item.id === id) return { ...item, selected: !item.selected };
        return item;
      }),
    );
  };

  const selectItem = (id: number) => {
    toggleNotification(id);
  };

  const markNotificationAsSeen = (id: number) => {
    notificationsServices(notificationsRepositoryInstance)
      .markNotificationAsSeen(id)
      .then(() => {
        getNotificationsData();
        setTopMenuUpdate(topMenuUpdate => topMenuUpdate + 1);
      });
  };

  /**************
   * JSX Values *
   **************/
  const isScreenBig = viewportWidth > 768;
  const noData = <p>{GlobalService.uppercaseFirstLetter(t('nodata'))}</p>;

  /*******
   * JSX *
   *******/
  const formattedNotifications = notifications.map(notification => {
    const { title, message, path } = getNotificationExtraInfo(notification);

    return (
      <NotificationCard
        key={`${notification.id}${notification.createdAt}`}
        id={notification.id}
        title={title}
        message={message}
        path={path}
        seen={notification.seen}
        createdAt={notification.createdAt}
        onClickNotificationLink={() => markNotificationAsSeen(notification.id)}
      />
    );
  });

  const paginator =
    totalPages > 1 ? (
      <div className='mx-auto my-4'>
        <Pagination totalPages={totalPages} currentPage={currentPage} onChange={handlePagination} />
      </div>
    ) : null;

  const topMenuProperties = {
    title: `${
      isScreenBig
        ? GlobalService.uppercaseFirstLetter(t('hi'))
        : GlobalService.uppercaseFirstLetter(t('pages.topMenu.notifications'))
    }`,
    user: `${isScreenBig ? tutorName : ''}`,
    leftIcon: isScreenBig ? undefined : LeftArrowSmall,
    leftIconLink: isScreenBig ? undefined : localStorage.getItem('backRoute') ?? undefined,
  } as const;

  return (
    <div className='w-full'>
      <TopMenu
        title={topMenuProperties.title}
        userName={topMenuProperties.user}
        leftIcon={topMenuProperties.leftIcon}
        leftIconLink={topMenuProperties.leftIconLink}
        updateCount={topMenuUpdate}
      />
      <div className={`m-8`}>
        {isScreenBig ? (
          <div className='flex text-left mb-4'>
            <img
              onClick={handleBackButton}
              src={LeftArrow}
              alt='Back'
              className='Notificaciones-Arrow'
            />
            <Title
              text={GlobalService.uppercaseFirstLetter(t('pages.topMenu.notifications'))}
              color='black'
              size={'24px'}
              margin={'0px 0px 0px 30px'}
            />
          </div>
        ) : null}
        {isLoading ? (
          <div className='mt-24 md:mt-0'>
            <RegularSpinner />
          </div>
        ) : (
          <>
            <div className='notificaciones-height grid grid-cols-1 lg:grid-cols-2 gap-y-4 gap-x-4 pb-4 mt-24 md:mt-2  items-center'>
              {notifications.length ? formattedNotifications : noData}
            </div>
            {paginator}
          </>
        )}
      </div>
    </div>
  );
};

export default Notificaciones;
