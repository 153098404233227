// import { useState } from 'react';
// import { useEventCallback } from './use-event-callback';
// import { useEventListener } from './use-event-listener';
import { useAtom, useAtomValue } from 'jotai';
import { viewportAtom, viewportEffectAtom } from '../store/global/atoms';

// export const useViewport = () => {
//   const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
//   const [viewportWidth, setViewportWidth] = useState(window.innerWidth);

//   const handleResize = useEventCallback(() => {
//     setViewportHeight(window.innerHeight);
//     setViewportWidth(window.innerWidth);

//     console.log('height', viewportHeight);
//     console.log('width', viewportWidth);
//   });

//   useEventListener('resize', handleResize);

//   return { viewportHeight, viewportWidth };
// };

export const useViewport = () => {
  const { height: viewportHeight, width: viewportWidth } = useAtomValue(viewportAtom);
  useAtom(viewportEffectAtom);

  const isScreenBig = viewportWidth >= 769;
  const isScreenTiny = viewportWidth < 321;

  return { viewportHeight, viewportWidth, isScreenBig, isScreenTiny };
};
