import { useAtomValue } from 'jotai';
import { getSettingsAtom } from '../../store/global/atoms/settings-atoms';
import { PatientQuote } from '../../ts';
import { GlobalService } from '../../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import { AvailableSetting } from '../../ts/types/settings/available-setting';
import { AVAILABLE_SETTINGS } from '../../consts/settings/available-settings';
import { useEffect } from 'react';
import { useShowSettingsErrorPopup } from '../popup';

const PDF_LINK =
  'https://creatorapp.zohopublic.com/export/coordinacion_dentalresidency/presupuesto/pdf/';

export const useSettings = () => {
  const getSettings = useAtomValue(getSettingsAtom);
  const { showSettingsErrorPopup } = useShowSettingsErrorPopup();

  useEffect(() => {
    if (getSettings.error != null) {
      console.log('getSettings.error', getSettings.error);
      showSettingsErrorPopup();
    }
  }, [getSettings.error]);

  const settings = getSettings.data ?? [];

  const getKey = (key: AvailableSetting): string => {
    const result = settings.find(item => item.key.toLowerCase() === key.toLowerCase())?.value;
    return result ?? 'settingNotFound';
  };

  const getReportLink = (report: PatientQuote) => {
    const reportContactName = GlobalService.uppercaseAllFirstLettersPlusReplace(
      report?.Contact_Name?.name,
      ' ',
    );
    const middleLink = `/?quote_id=${report.id}`;
    const nameLink = `&zc_FileName=Informe_Bucodental_${reportContactName}_${report?.Identificador}`;

    let linkToPdf = '';
    switch (report.Tipo_de_informe.toLocaleLowerCase()) {
      case 'it':
        linkToPdf = `${getKey(AVAILABLE_SETTINGS.PDF_IT)}`;
        break;
      case 'boca sana':
        linkToPdf = `${getKey(AVAILABLE_SETTINGS.PDF_BOCA_SANA)}`;
        break;
      case 'ro':
        linkToPdf = `${getKey(AVAILABLE_SETTINGS.PDF_RO)}`;
        break;
      case 'no colabora':
        linkToPdf = `${getKey(AVAILABLE_SETTINGS.PDF_NO_COLABORA)}`;
        break;
    }

    return `${PDF_LINK}${linkToPdf}${middleLink}${nameLink}`;
  };

  return {
    settings,
    getSettingKey: getKey,
    isLoading: getSettings.isFetching || getSettings.isPending,
    getSettings: getSettings.refetch,
    getReportLink,
    isError: getSettings.isError,
    error: getSettings.error,
  };
};
