import { PatientQuote } from 'src/ts';
import styles from './quote-card.module.css';
import classNames from 'classnames/bind';
import { GlobalService } from '../../../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import { useTranslation } from 'react-i18next';
import { FC, useState } from 'react';
import { Button } from '../../../components/Buttons';
import {
  useDownloadQuotePdf,
  useSelectedPatientValue,
  useSetSelectedQuote,
  useSetUrlToReturn,
  useShowDocumentDownloadOkPopup,
  useShowErrorPopup,
} from '../../../hooks';
import { useNavigate } from 'react-router-dom';

const cx = classNames.bind(styles);

const QUOTE_TYPE_TO_TEXT = {
  it: 'pages.reports.it',
  'boca sana': 'pages.reports.bocaSana',
  ro: 'pages.reports.ro',
  'no colabora': 'pages.reports.noColabora',
};

const AUDIO_EXTENSIONS = ['mp3', 'wav', 'ogg'];
const IMG_EXTENSIONS = ['jpg', 'jpeg', 'png', 'gif'];

interface QuoteCardParams {
  quote: PatientQuote;
}

export const QuoteCard: FC<QuoteCardParams> = ({ quote }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isDownloading, setIsDownloading] = useState(false);
  const { setSelectedQuote } = useSetSelectedQuote();
  const { setUrlToReturn } = useSetUrlToReturn();
  const { selectedPatient } = useSelectedPatientValue();
  const { showDocumentDownloadOkPopup } = useShowDocumentDownloadOkPopup();
  const { showErrorPopup } = useShowErrorPopup();
  const { downloadQuotePdf, isLoading: isDownloadingQuotePdf } = useDownloadQuotePdf();

  const handleOnClickDetailedQuote = () => {
    if (quote.Tipo_de_informe.toLocaleLowerCase() !== 'it') return;
    setSelectedQuote(quote.id);
    setUrlToReturn(`/informes/${selectedPatient.id}`);
    navigate(`/informeDetallado/${selectedPatient.id}/${quote.id}`);
  };

  const handleDownloadPdf = async () => {
    setIsDownloading(true);
    downloadQuotePdf(
      { quoteId: quote.id },
      {
        onSuccess: () => showDocumentDownloadOkPopup(),
        onSettled: () => setIsDownloading(false),
        onError: error => showErrorPopup(`errors.${error.message}`),
      },
    );
  };

  const formatReportFields = (report: PatientQuote) => {
    const name =
      report?.Nombre_del_Dentista?.name ||
      GlobalService.uppercaseJustFirstLetter(t('pages.visit.nodata'));
    const reportsFieldData = [
      ['reports.reportType', t(QUOTE_TYPE_TO_TEXT[report.Tipo_de_informe])],
      ['reports.budgetNumber', report.Identificador],
      ['reports.date', report.Fecha_de_la_Visita],
      ['reports.specialist', name],
      ['informeDetallado.reportPhase', report.Quote_Stage],
    ];

    return reportsFieldData.map((fields: string[], index: number) => (
      <div key={index}>
        <p className={'Personas-Visitas'}>
          {GlobalService.uppercaseFirstLetter(t(`pages.${fields[0]}`))}:
        </p>

        <p>
          <strong>{fields[1]}</strong>
        </p>
      </div>
    ));
  };

  const hasMedia = quote.media.length > 0;
  const audioMedia = quote.media.filter(mediaItem =>
    AUDIO_EXTENSIONS.includes(mediaItem.extension),
  );
  const imageMedia = quote.media.filter(mediaItem => IMG_EXTENSIONS.includes(mediaItem.extension));

  return (
    <div className={cx('report-card')}>
      <div className={cx('fields-container')}>{formatReportFields(quote)}</div>

      {hasMedia ? (
        <div className={cx('media-container')}>
          <div className={cx('image-container')}>
            {imageMedia.map((mediaItem, index) => (
              <img
                key={index}
                className={cx('image')}
                src={`https://crm.zoho.com${mediaItem.previewUrl}`}
                alt=''
              />
            ))}
          </div>

          <div className='audio-container'>
            {audioMedia.map((mediaItem, index) => (
              <audio key={index} controls>
                <source
                  src={`https://crm.zoho.com${mediaItem.previewUrl}`}
                  type={`audio/${mediaItem.extension}`}
                />
              </audio>
            ))}
          </div>
        </div>
      ) : null}

      <div className={cx('buttons-container')}>
        <Button
          styleType='primary'
          onClick={isDownloadingQuotePdf ? () => {} : () => handleDownloadPdf()}
          fullWidth={true}
          disabled={isDownloadingQuotePdf && !isDownloading}
          isLoading={isDownloading}
        >
          {GlobalService.uppercaseFirstLetter(t('pages.reports.detailedReport'))}
        </Button>

        {quote.isPayable ? (
          <Button
            styleType='primary'
            onClick={() => handleOnClickDetailedQuote()}
            fullWidth={true}
            disabled={quote.isRejected || quote.Tipo_de_informe.toLocaleLowerCase() !== 'it'}
          >
            {GlobalService.uppercaseFirstLetter(t('continue'))}
          </Button>
        ) : null}
      </div>
    </div>
  );
};
