import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  borderRadius?: string;
  customClass?: string;
  customClassInput?: string;
  enableShowPassword?: boolean;
  id: string;
  name: string;
  onChange?: (e: any) => void;
  placeholder?: string;
  value: string | number;
}

const InputOld: React.FC<Props> = ({
  borderRadius,
  customClass = '',
  customClassInput = '',
  enableShowPassword,
  id,
  name,
  placeholder,
  onChange,
  type,
  value,
  disabled,
  ...rest
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  let classname =
    'appearance-none flex items-center w-full border border-gray-200 text-gray-700 py-2 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 ' +
    customClass;

  classname = disabled ? classname : `${classname} cursor-text`;

  const backgroundColor = disabled ? 'bg-gray-200' : 'bg-white';
  switch (type) {
    case 'text':
      return (
        <label className={classname} style={{ borderRadius: borderRadius, backgroundColor }}>
          <input
            value={value}
            type={type}
            id={id}
            name={name}
            onChange={e => onChange && onChange(e.target.value)}
            placeholder={placeholder}
            style={{ borderRadius: borderRadius }}
            disabled={disabled}
            className={`w-full outline-0 ${customClassInput}`}
            {...rest}
          />
        </label>
      );
    case 'tel':
      return (
        <label className={classname} style={{ borderRadius: borderRadius, backgroundColor }}>
          <input
            value={value}
            type={type}
            id={id}
            name={name}
            onChange={e => onChange && onChange(e.target.value)}
            placeholder={placeholder}
            style={{ borderRadius: borderRadius }}
            disabled={disabled}
            className={`w-full outline-0 ${customClassInput}`}
            {...rest}
          />
        </label>
      );
    case 'number':
      return (
        <label className={classname} style={{ borderRadius: borderRadius, backgroundColor }}>
          <input
            value={value}
            type={type}
            onChange={e => onChange && onChange(e.target.value)}
            placeholder={placeholder}
            disabled={disabled}
            className={`w-full outline-0 numeric-input-no-arrow ${customClassInput}`}
          />
        </label>
      );
    case 'email':
      return (
        <label className={classname} style={{ borderRadius: borderRadius, backgroundColor }}>
          <input
            value={value}
            type={type}
            onChange={e => onChange && onChange(e.target.value)}
            placeholder={placeholder}
            style={{ borderRadius: borderRadius }}
            disabled={disabled}
            className='w-full outline-0'
          />
        </label>
      );
    case 'password':
      return (
        <label className={classname} style={{ backgroundColor }}>
          <input
            value={value}
            type={showPassword ? 'text' : 'password'}
            onChange={e => onChange && onChange(e.target.value)}
            placeholder={placeholder}
            style={{ borderRadius: borderRadius }}
            disabled={disabled}
            className='w-full outline-0'
          />
          {enableShowPassword ? (
            !showPassword ? (
              <FontAwesomeIcon
                icon={faEye}
                size='lg'
                onClick={handleShowPassword}
                className='opacity-50 cursor-auto hover:cursor-pointer'
              />
            ) : (
              <FontAwesomeIcon
                icon={faEyeSlash}
                size='lg'
                onClick={handleShowPassword}
                className='opacity-50 cursor-auto hover:cursor-pointer'
              />
            )
          ) : null}
        </label>
      );
    case 'hidden':
      return <input value={value} type={type} disabled={true} className='w-full outline-0' />;
    default:
      return (
        <label className={classname} style={{ borderRadius: borderRadius, backgroundColor }}>
          <input
            value={value}
            type={type}
            onChange={e => onChange && onChange(e.target.value)}
            placeholder={placeholder}
            style={{ borderRadius: borderRadius }}
            disabled={disabled}
            className={`w-full outline-0 ${customClassInput}`}
          />
        </label>
      );
  }
};

export default InputOld;
