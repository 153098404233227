import { PopupOptionsSetter } from 'src/ts';
import { useSetPopup } from '../use-popup';
import { BstSetAndMobileAlreadyInUsePopup } from '../../../components';

export const useBstSetAndMobileAlreadyInUsePopup = () => {
  const { openPopup } = useSetPopup();

  const showBstSetAndMobileAlreadyInUsePopup = (mobile: string) => {
    const popupOptions: PopupOptionsSetter = {
      children: <BstSetAndMobileAlreadyInUsePopup mobile={mobile} />,
      title: '¡Ya tienes una cuenta!',
      disableClickOutside: true,
    };

    openPopup(popupOptions);
  };

  return { showBstSetAndMobileAlreadyInUsePopup };
};
