import { FC, useEffect } from 'react';
import { useAddPatientFromBstPopup, useBlindSignupToken } from '../../../hooks';

interface ControlBstWhenLoggedProps {
  children: React.ReactNode;
}

export const ControlBstWhenLogged: FC<ControlBstWhenLoggedProps> = ({ children }) => {
  const { isBstValid } = useBlindSignupToken();
  const { showAppPatientFromBstPopup } = useAddPatientFromBstPopup();

  useEffect(() => {
    if (isBstValid !== true) return;

    showAppPatientFromBstPopup();
  }, [isBstValid]);

  return <>{children}</>;
};
