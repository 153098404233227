import axios from 'axios';
import type { ApiResponse, SetTutorToPatientInBstServiceParams } from 'src/ts';

export const setTutorToPatientInBstService = async ({
  tutorId,
  bst,
}: SetTutorToPatientInBstServiceParams): Promise<boolean> => {
  let responseData: ApiResponse<undefined>;

  try {
    const response = await axios.patch<ApiResponse<undefined>>('/patients/update-patient-tutor', {
      tutorId: tutorId,
      bst,
    });

    responseData = response.data;
  } catch (error: any) {
    throw new Error(error.response.data.message);
  }

  if (!responseData.isOk) throw new Error(responseData.message);

  return responseData.isOk;
};
