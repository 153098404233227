import { useAtomValue, useSetAtom } from 'jotai';
import {
  afterUpdatePatientEffect,
  assignAttachedFilesToPatientAtomEffect,
  assignDealsToPatientsAtomEffect,
  assignQuotesToPatientsAtomEffect,
  assignVisitsToPatientsAtomEffect,
  getAllPatientsAtom,
  getPatientsExtraInfoAtomEffect,
  patientsAtom,
  setPatientsOnFetchEndAtomEffect,
} from '../../../store/global/atoms';
import { MutateOptions } from '@tanstack/query-core';
import { PatientInfo } from '../../../ts';

export const useSetPatients = () => {
  const getAllPatientsBase = useAtomValue(getAllPatientsAtom);
  const setPatients = useSetAtom(patientsAtom);
  useAtomValue(getPatientsExtraInfoAtomEffect);
  useAtomValue(assignDealsToPatientsAtomEffect);
  useAtomValue(assignQuotesToPatientsAtomEffect);
  useAtomValue(assignVisitsToPatientsAtomEffect);
  useAtomValue(assignAttachedFilesToPatientAtomEffect);
  useAtomValue(setPatientsOnFetchEndAtomEffect);
  useAtomValue(afterUpdatePatientEffect);

  const resetPatientsList = () => {
    setPatients([]);
  };

  const getAllPatients = (
    options?: MutateOptions<PatientInfo[], Error, void, unknown> | undefined,
  ) => {
    if (getAllPatientsBase.isPending && getAllPatientsBase.data == null) return;
    getAllPatientsBase.mutate(undefined, options);
  };

  const getAllPatientsAsync = async (
    options?: MutateOptions<PatientInfo[], Error, void, unknown> | undefined,
  ) => {
    if (getAllPatientsBase.isPending && getAllPatientsBase.data == null) return;
    return await getAllPatientsBase.mutateAsync(undefined, options);
  };

  return {
    getAllPatients,
    getAllPatientsAsync,
    resetPatientsList,
  };
};
