import axios from "axios";

export const testBicValidator = async (code : string) : Promise<string> => {
	try{
		console.log('code', code);
		const response = await axios.post(`/swift-bic/code`, { code });
		console.log('response', response);
		return response.data;
	}
	catch(error : any){
		throw new Error('Error to find BIC', error.message);
	}
  }