import styles from './signup-form.module.css';
import classNames from 'classnames/bind';
import { useForm } from '@tanstack/react-form';
import { useTranslation } from 'react-i18next';
import { validate } from '../../../../utils';
import { Button } from '../../../../components/Buttons';
import { GlobalService } from '../../../../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import { LoginSignupSwitchLink } from '../../login-signup-switch-link';
import { Dispatch, FC, SetStateAction, useEffect } from 'react';
import {
  useBlindSignupToken,
  useBstSetAndMobileAlreadyInUsePopup,
  useGetIsMobileRegistered,
} from '../../../../hooks';
import { Input } from '../../../Inputs/input';

const cx = classNames.bind(styles);

interface SignupForm0Props {
  initialValues: {
    mobile: string;
    password: string;
    passwordConfirmation: string;
  };
  setInitialValues: {
    setMobile: Dispatch<SetStateAction<string>>;
    setPassword: Dispatch<SetStateAction<string>>;
    setPasswordConfirmation: Dispatch<SetStateAction<string>>;
  };
  handleSubmit: () => void;
}

export const SignupForm0: FC<SignupForm0Props> = ({
  initialValues,
  setInitialValues,
  handleSubmit,
}) => {
  const { t } = useTranslation();
  const { isMobileRegistered, getIsMobileRegisteredAsync, cleanIsMobileRegistered } =
    useGetIsMobileRegistered();
  const { showBstSetAndMobileAlreadyInUsePopup } = useBstSetAndMobileAlreadyInUsePopup();
  const { blindSignupToken } = useBlindSignupToken();

  useEffect(() => {
    if (!isMobileRegistered) return;

    if (blindSignupToken == null) return;

    showBstSetAndMobileAlreadyInUsePopup(form.state.values.mobile);
  }, [isMobileRegistered]);

  const form = useForm({
    defaultValues: {
      mobile: initialValues.mobile,
      password: initialValues.password,
      passwordConfirmation: initialValues.passwordConfirmation,
    },
    onSubmit: async values => {
      cleanIsMobileRegistered();

      const { setMobile, setPassword, setPasswordConfirmation } = setInitialValues;

      setMobile(values.value.mobile);
      setPassword(values.value.password);
      setPasswordConfirmation(values.value.passwordConfirmation);

      try {
        const isMobileRegistered = await getIsMobileRegisteredAsync(values.value.mobile);

        if (isMobileRegistered) return;

        handleSubmit();
      } catch (error: any) {
        console.log('error.message', error.message);
      }
    },
  });

  const isMobileRegisteredError = isMobileRegistered
    ? GlobalService.uppercaseAfterPointFirstsLetters(
        t('modal.verifyMobile.errors.mobileAlreadyRegistered'),
      )
    : '';

  return (
    <>
      <form
        className={cx('signup-form')}
        onSubmit={e => {
          e.preventDefault();
          e.stopPropagation();
          form.handleSubmit();
        }}
      >
        <form.Field
          name={'mobile'}
          validators={{
            onChange: ({ value }) => {
              cleanIsMobileRegistered();
              const isValid = validate(value, 'phone');
              return isValid ? undefined : 'errors.phoneInvalid';
            },
          }}
          // eslint-disable-next-line react/no-children-prop
          children={field => {
            const errors = [...field.state.meta.errors];
            if (isMobileRegistered) errors.push(isMobileRegisteredError);

            return (
              <Input
                id={field.name}
                label={'phoneno'}
                placeholder=''
                type={'tel'}
                value={field.state.value}
                onChange={field.handleChange}
                onBlur={field.handleBlur}
                errorMessage={errors.join(', ')}
              />
            );
          }}
        />

        <form.Field
          name={'password'}
          validators={{
            onChange: ({ value }) => {
              const isValid = validate(value, 'password');
              return isValid ? undefined : 'errors.passwordInvalid';
            },
          }}
          // eslint-disable-next-line react/no-children-prop
          children={field => {
            return (
              <Input
                id={field.name}
                label={'password'}
                placeholder=''
                type={'password'}
                value={field.state.value}
                onChange={field.handleChange}
                onBlur={field.handleBlur}
                errorMessage={field.state.meta.errors.join(', ')}
                enableShowPassword={true}
              />
            );
          }}
        />

        <form.Field
          name={'passwordConfirmation'}
          validators={{
            onChangeListenTo: ['password'],
            onChange: ({ fieldApi, value }) => {
              const password = fieldApi.form.getFieldValue('password');
              return value === password ? undefined : 'errors.passwordsNotMatch';
            },
          }}
          // eslint-disable-next-line react/no-children-prop
          children={field => {
            return (
              <Input
                id={field.name}
                label={'passwordconfirmation'}
                placeholder=''
                type={'password'}
                value={field.state.value}
                onChange={field.handleChange}
                onBlur={field.handleBlur}
                errorMessage={field.state.meta.errors.join(', ')}
                enableShowPassword={true}
              />
            );
          }}
        />

        <form.Subscribe
          // @ts-ignore
          selector={state => [state.canSubmit, state.isSubmitting]}
          // @ts-ignore
          // eslint-disable-next-line react/no-children-prop
          children={([canSubmit, isSubmitting]) => (
            <Button disabled={!canSubmit} fullWidth={true} type='submit' isLoading={isSubmitting}>
              {GlobalService.uppercaseFirstLetter(t(`pages.signup.register`))}
            </Button>
          )}
        />
      </form>

      <LoginSignupSwitchLink type='signup' />
    </>
  );
};
