import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Components
import AddressBlock from '../components/AddressBlock';
import { ButtonBase } from '../components/Buttons/button-base';
import ModalInfo from '../components/Modals/ModalInfo';
import ModalPayment from '../components/Modals/ModalPayment';
import ModalRecurringPurchase from '../components/Modals/ModalRecurringPurchase';
import ModalYesNo from '../components/Modals/ModalYesNo';
import RegularSpinner from '../components/Spinner/RegularSpinner';
import Title from '../components/Title';
import { TopMenu } from '../components';

// Services
import { ActiveUserService } from '../hexagonal-architecture-frontend-base/src/domain/services/activeUser.service';
import { GlobalService } from '../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import { tiendaRepositoryInstance } from '../hexagonal-architecture-frontend-base/src/infrastructure/instances/tiendaRepository';
import { tiendaServices } from '../hexagonal-architecture-frontend-base/src/infrastructure/services/tienda.service';

// Interfaces
import { IAddressData } from 'src/hexagonal-architecture-frontend-base/src/domain/models/IAddressItem';
import {
  ICart,
  ICartDetails,
} from '../hexagonal-architecture-frontend-base/src/domain/models/ICart';
import { ICartItem } from '../hexagonal-architecture-frontend-base/src/domain/models/ICartItem';
import {
  IOrder,
  IOrderItem,
  IUserDataPayment,
} from 'src/hexagonal-architecture-frontend-base/src/domain/models/IPago';
import { IUserData } from '../hexagonal-architecture-frontend-base/src/domain/models/ITutor';

// CSS
import '../styles/Tienda/Tienda.css';

// Images
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GreenCheck from '../img/greenCheck.png';
import LeftArrow from '../img/PersonaImg/left-arrow.png';
import LeftArrowSmall from '../img/PersonaImg/left-arrow-short.png';

// Hooks
import { useViewport } from '../hooks/use-viewport';
import { PaymentIntent, PaymentIntentResult } from '@stripe/stripe-js';

const Carrito = () => {
  const formatPrice = tiendaServices(tiendaRepositoryInstance).formatPrice;
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(window.location.search);
  const { t } = useTranslation();
  const TutorId = ActiveUserService.getActiveUser().Prisma.id;

  /**********
   * States *
   **********/
  const [address, setAddress] = useState<IAddressData>({
    Pa_s: '',
    Codigo_postal: '',
    Direcci_n: '',
    id: '',
    Ciudad: '',
    Name: 'Principal',
  });
  const [isModalOpen, setIsModalopen] = useState<boolean>(false);
  const [isRecurringPurchase, setIsRecurringPurchase] = useState<boolean>(false);
  const [meses, setMeses] = useState<number>(1);
  const [items, setItems] = useState<ICartItem[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [modifiedItem, setModifiedItem] = useState<number>(0);
  const [selectedField, setSelectedField] = useState<string>('');
  const [selectedOperation, setSelectedOperation] = useState<string>('');
  const { viewportWidth } = useViewport();
  const [cartDetails, setCartDetails] = useState<ICartDetails>({
    totalItems: 0,
    totalPriceItems: '0 €',
    vat: 21,
    totalPriceOrder: '0 €',
  });
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState<string>('');
  const [showYesNoModal, setShowYesNoModal] = useState<boolean>(false);
  const [yesNoModalText, setYesNoModalText] = useState<string>('');
  const [idToDelete, setIdToDelete] = useState<number | null>(null);
  const [showPaymentModal, setShowPaymentModal] = useState<boolean>(false);
  const [userData, setUserData] = useState<IUserDataPayment | undefined>(undefined);
  const [orderData, setOrderData] = useState<IOrder | undefined>(undefined);
  const [unformattedTotalAmount, setUnformattedTotalAmount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  /*********
   * Hooks *
   *********/

  //FetchData
  useEffect(() => {
    fetchBasketData();
    getMyData();
  }, []);

  /************
   * Handlers *
   ************/
  const handleChange = (id: number, field: string, operation: string) => {
    setSelectedOperation(operation);
    setModifiedItem(id);
    setSelectedField(field);
    items.forEach((item: ICartItem) => {
      if (item.id === id) {
        if (operation === '+') {
          item.quantity += 1;
        } else {
          if (item.quantity === 1) {
            item.quantity = 1;
          } else {
            item.quantity -= 1;
          }
        }
        if (item.quantity > 0) {
          updateItemsBasket(id, item.quantity);
        }
      }
    });
  };

  const handleCheckToggle = (value: boolean) => {
    if (!isDisabled()) {
      setIsRecurringPurchase(value);
      setIsModalopen(value);
    }
  };

  const handleClickModal = () => {
    setShowModal(false);
  };

  const handleClosePayment = () => {
    setShowPaymentModal(false);
  };

  const handlePaymentResult = async (
    paymentIntent: PaymentIntent | string,
    subscriptionId?: string,
  ) => {
    if (typeof paymentIntent === 'string') {
      if (orderData) {
        const editedOrderData = { ...orderData, status: 'failed payment' };
        tiendaServices(tiendaRepositoryInstance)
          .updateOrder(editedOrderData)
          .then((response: IOrder | string) => {
            if (typeof response === 'string') {
              setShowPaymentModal(false);
              setModalMessage('errors.paymentConfirmationError');
              setShowModal(true);
            }
          });
      }
    } else {
      createOrderMail();
      if (orderData) {
        const editedOrderData = { ...orderData, status: 'paid', subscriptionId: subscriptionId };
        tiendaServices(tiendaRepositoryInstance)
          .updateOrder(editedOrderData)
          .then((response: string) => {
            if (response === 'succeeded') {
              setModalMessage('pages.tienda.successfulPayment');
              setShowModal(true);
              setShowPaymentModal(false);
              handleDeleteAllItems();
            } else {
              setModalMessage(response);
              setShowModal(true);
            }
          });
      }
    }
    setIsLoading(false);
  };

  const handleSelectedModal = (id?: number) => {
    if (id) {
      setYesNoModalText('pages.tienda.deleteCartItem');
      setIdToDelete(id);
    } else {
      setYesNoModalText('pages.tienda.cancelOrderQuestion');
      setIdToDelete(null);
    }
    setShowYesNoModal(true);
  };

  const handleClickYesNo = async () => {
    if (idToDelete) handleDeleteItem(idToDelete);
    else handleDeleteAllItems();
  };

  const handleDeleteAllItems = () => {
    tiendaServices(tiendaRepositoryInstance)
      .removeAllItemsBasket()
      .then((response: string) => {
        if (response === 'errors.genericError') {
          setModalMessage(response);
          setShowModal(true);
        } else {
          setTimeout(() => {
            fetchBasketData(true);
          }, 1000);
        }
      })
      .finally(() => {
        setShowYesNoModal(false);
      });
  };

  const handleDeleteItem = (id: number) => {
    tiendaServices(tiendaRepositoryInstance)
      .removeItemBasket(id)
      .then((response: string) => {
        if (response === 'errors.productNotFound') {
          setModalMessage(response);
          setShowModal(true);
          setIdToDelete(null);
        } else {
          setTimeout(() => {
            fetchBasketData(true);
          }, 1000);
          setShowYesNoModal(false);
          setIdToDelete(null);
        }
      });
  };

  const handleMakeOrder = () => {
    if (
      address.Ciudad === '' ||
      address.Codigo_postal === '' ||
      address.Direcci_n === '' ||
      address.Pa_s === ''
    ) {
      setModalMessage('errors.addressesNotFound');
      setShowModal(true);
    } else {
      placeOrder();
    }
  };

  const handleRecurringPurchase = (meses: number) => {
    setMeses(meses);
    setIsModalopen(false);
  };

  const handleModalGoBackButton = () => {
    setIsRecurringPurchase(false);
    setIsModalopen(false);
  };

  /***********
   * Helpers *
   ***********/
  const createOrderMail = () => {
    const products = items.map(item => {
      return {
        name: item.Products.name,
        price: item.Products.price,
        formattedPrice: item.formattedPrice,
        quantity: item.quantity,
        taxes: 0.21,
      };
    });
    const body = {
      mail: ActiveUserService.getActiveUser().Prisma.email,
      name: ActiveUserService.getActiveUser().Prisma.name,
      products,
    };
    tiendaServices(tiendaRepositoryInstance)
      .sendOrderByMail(body)
      .then(response => {
        // console.log('send response: ', response);
      });
  };

  const fetchBasketData = async (reloadData?: boolean) => {
    await tiendaServices(tiendaRepositoryInstance)
      .getBasketItems(reloadData)
      .then((res: ICart | string) => {
        if (typeof res === 'string') {
          setModalMessage(res);
          setItems([]);
          setShowModal(true);
        } else {
          const editedItems = res.items.map((item: ICartItem) => {
            item.formattedPrice = formatPrice(item.Products.price);
            return item;
          });
          const editedDetails = {
            ...res.details,
            totalPriceItems: formatPrice(res.details.totalPriceItems),
            totalPriceOrder: formatPrice(res.details.totalPriceOrder),
          };
          setItems(editedItems);
          setCartDetails(editedDetails);
          setUnformattedTotalAmount(
            Number(parseFloat(res.details.totalPriceOrder.toString()).toFixed(2)),
          );
        }
        setLoading(false);
      })
      .catch(() => {
        setModalMessage('errors.genericError');
      });
  };

  const getMyData = () => {
    ActiveUserService.initData().then((response: IUserData | string) => {
      if (typeof response === 'object') {
        const userDataPayment = {
          id: response.Prisma.id,
          name: response.Prisma.name,
          email: response.Prisma.email,
          phone: response.Prisma.phone,
          stripeId: response.Prisma.stripeId,
        };
        setUserData(userDataPayment);
      } else {
        setModalMessage(response);
        setShowModal(true);
      }
    });
  };

  const isDisabled = () => {
    if (!items.length) return true;
    return false;
  };

  const placeOrder = async () => {
    const id = userData?.id;
    if (items && id) {
      await tiendaServices(tiendaRepositoryInstance)
        .placeOrder(id, items, isRecurringPurchase, meses, address)
        .then((res: IOrder | string) => {
          if (typeof res === 'string') {
            setModalMessage(res);
            setIsModalopen(true);
          } else {
            setOrderData(res);
            setShowPaymentModal(true);
          }
        });
    } else {
      setModalMessage('errors.genericError');
      setShowModal(true);
    }
  };

  const navigateTo = () => {
    navigate(`${searchParams.get('url')?.replace('=', '')}`);
  };

  /************
   * Functions *
   ************/
  const setIOrderItems = (itemsList: ICartItem[]): IOrder => {
    const itemsListAux: IOrderItem[] = [];
    itemsList.map((item: ICartItem) => {
      const itemsAux: IOrderItem = {
        id: item.id,
        userId: TutorId,
        productId: item.productId,
        quantity: item.quantity,
        Products: {
          id: item.Products.id,
          name: item.Products.name,
          price: item.Products.price,
          formattedPrice: item.Products.formattedPrice,
          image: item.Products.image,
          categoryId: item.Products.categoryId,
          createdAt: item.Products.createdAt,
          updatedAt: item.Products.updatedAt,
        },
        createdAt: new Date().toDateString(),
        updatedAt: new Date().toDateString(),
      };
      itemsListAux.push(itemsAux);
    });

    return {
      id: 0,
      userId: TutorId,
      status: '',
      recurrent: true,
      frequency: 1,
      items: itemsListAux,
      lastSendAt: '',
      lastReceivedAt: '',
      createdAt: '',
      updatedAt: '',
    };
  };

  const updateItemsBasket = async (id: number, quantity?: number) => {
    const index = items.findIndex((item: ICartItem) => {
      return item.id === id;
    });
    const message = await tiendaServices(tiendaRepositoryInstance).addItemToBasket(
      items[index].productId,
      quantity,
    );
    if (message === 'errors.addToBasketFailed') {
      setModalMessage(message);
      setShowModal(true);
    } else {
      fetchBasketData(true);
    }
  };

  /**************
   * JSX Values *
   **************/
  const isScreenBig = viewportWidth > 769;

  const activeButton = {
    color: 'white',
    backgroundColor: '#28337d',
  };

  const inactiveButton = {
    color: '#808080',
    backgroundColor: '#EEEEEE',
  };

  const noData = <p>{GlobalService.uppercaseFirstLetter(t('nodata'))}</p>;

  const formatedItems = items.map((item: ICartItem, index: number) => (
    <div key={index} className={`item-cart-card${isScreenBig ? '' : '-Gray'} bg-white p-4`}>
      <div className='flex justify-between'>
        <div className={`flex ${isScreenBig ? '' : 'text-sm'}`}>
          <img
            src={
              item.Products && item.Products.image && item.Products.image.length > 0
                ? item.Products.image[0]
                : ''
            }
            alt=''
            className='item-cart-image mr-4'
          />
          <div className=''>
            <div className={`${isScreenBig ? 'w-3/5' : ''}`}>
              <p>{item.Products.marca}</p>
              <p className='font-light mt-2'>{item.Products.name}</p>
            </div>

            <p className='mt-2' style={{ color: '#28337d' }}>
              {item.formattedPrice}
            </p>
            <div className='flex justify-between items-center w-24 shadow mt-2 border border-slate-200 rounded-3xl'>
              <button
                className='cart-button 3xs:pt-1.5 md:pt-0'
                style={
                  selectedOperation === '-' && item.id === modifiedItem && selectedField === 'items'
                    ? activeButton
                    : inactiveButton
                }
                onClick={item.quantity > 1 ? () => handleChange(item.id, 'items', '-') : undefined}
              >
                <p className='symbol'>-</p>
              </button>
              <p>{item.quantity}</p>
              <button
                className='cart-button 3xs:pt-2 md:pt-0'
                style={
                  selectedOperation === '+' && item.id === modifiedItem && selectedField === 'items'
                    ? activeButton
                    : inactiveButton
                }
                onClick={() => handleChange(item.id, 'items', '+')}
              >
                <p className='symbol'>+</p>
              </button>
            </div>
          </div>
        </div>
        <div>
          <FontAwesomeIcon
            className='cursor-pointer'
            icon={faXmark}
            onClick={() => handleSelectedModal(item.productId)}
          />
        </div>
      </div>
    </div>
  ));

  let totalBase = 0;
  let totalIva = 0;
  items.forEach(item => {
    totalBase += item.Products.price * item.quantity;
    totalIva += item.Products.price * item.quantity * 0.21;
  });
  const basketResume = (
    <div className={`w-full resume-height${isScreenBig ? '' : ' text-sm mb-24'}`}>
      <div
        className={`total-cart-container${isScreenBig ? '' : '-small'}  flex flex-col ${
          isScreenBig ? 'justify-between' : ''
        } mb-8 p-4`}
      >
        <div>
          <p className='mt-2'>
            <strong>{GlobalService.uppercaseFirstLetter(t('pages.tienda.orderDetail'))}</strong>
          </p>
          <div className='border-t-2 w-full m-auto mt-4 mb-4'></div>

          <div className='flex justify-between mb-2'>
            <p>{GlobalService.uppercaseFirstLetter(t('pages.tienda.productQuantity'))}:</p>
            <p>{cartDetails.totalItems}</p>
          </div>
          <div className='flex justify-between mb-2'>
            <p>{GlobalService.uppercaseFirstLetter(t('pages.tienda.price'))}:</p>
            <p>{formatPrice(totalBase)}</p>
          </div>
          <div className='flex justify-between mb-2'>
            <p>{GlobalService.uppercaseFirstLetter(t('pages.tienda.taxes'))}:</p>
            <p>{cartDetails.vat} %</p>
          </div>
          <div className='flex justify-between mb-2'>
            <p>{GlobalService.uppercaseFirstLetter(t('pages.tienda.totalTaxes'))}:</p>
            <p>{formatPrice(totalIva)}</p>
          </div>
        </div>
        <div>
          <div className='border-t-2 w-full m-auto mb-4'></div>
          <div className='flex justify-between  '>
            <p>
              <strong>Total:</strong>
            </p>
            <p>
              <strong>{formatPrice(totalBase + totalIva)}</strong>
            </p>
          </div>
        </div>
      </div>

      <div className='total-cart-container-small flex flex-row mb-8 p-4'>
        {isRecurringPurchase ? (
          <div
            className={`relative w-5 h-5 border-2 rounded border-black cursor-pointer`}
            onClick={() => {
              handleCheckToggle(false);
            }}
          >
            <div className='absolute w-1.5 h-1.5 bg-white checkbox-white-properties'></div>
            <img src={GreenCheck} alt='Check' className='absolute checkbox-check-properties' />
          </div>
        ) : (
          <div
            className={`w-5 h-5 border-2 rounded ${
              isDisabled() ? 'border-gray' : 'border-black cursor-pointer'
            }`}
            onClick={() => {
              handleCheckToggle(true);
            }}
          ></div>
        )}
        <p className={`ml-3 font-bold ${isDisabled() ? 'text-slate-400' : ''}`}>
          {GlobalService.uppercaseFirstLetter(t('pages.tienda.recurringPurchase'))}
        </p>
      </div>
      <div className='flex flex-col gap-2'>
        <ButtonBase
          text={GlobalService.uppercaseFirstLetter(t('pages.tienda.makeOrder'))}
          background={'linear-gradient(92.78deg, #30357B 18.94%, #D06E80 94.82%)'}
          color={'white'}
          borderRadius={'50px'}
          buttonWidth='100%'
          onClick={() => {
            handleMakeOrder();
          }}
          disabled={isDisabled()}
        />
        <ButtonBase
          text={GlobalService.uppercaseFirstLetter(t('pages.tienda.cancelOrder'))}
          background={'none'}
          color={isDisabled() ? 'white' : '#28337d'}
          border='1px solid #28337d'
          buttonWidth='100%'
          borderRadius='50px'
          onClick={() => handleSelectedModal()}
          disabled={isDisabled()}
        />
      </div>
    </div>
  );

  // Desktop Values
  const desktopPageTitle = (
    <div className='flex text-left'>
      <img
        onClick={() => {
          navigateTo();
        }}
        src={LeftArrow}
        alt='Back'
        className='Notificaciones-Arrow cursor-pointer'
      />
      <Title
        text={GlobalService.uppercaseFirstLetter(t('pages.tienda.basket'))}
        color='black'
        size={'24px'}
        margin={'0px 0px 0px 30px'}
      />
    </div>
  );

  const desktopAddressBlock = (
    <AddressBlock
      isScreenBig={isScreenBig}
      title={GlobalService.uppercaseFirstLetter(t('pages.tienda.addressToSend'))}
    />
  );

  const desktopModal = (
    <ModalRecurringPurchase
      orderItem={setIOrderItems(items)}
      onclickCancel={handleModalGoBackButton}
      onclickContinue={meses => handleRecurringPurchase(meses)}
      cancelButtonText={GlobalService.uppercaseFirstLetter(t('pages.tienda.cancel'))}
      onClose={handleModalGoBackButton}
    />
  );

  return (
    <div className='w-full'>
      {isScreenBig ? (
        <>
          <TopMenu title={GlobalService.uppercaseFirstLetter(t('pages.tienda.title'))} />
          <div className='flex flex-col gap-2 w-full p-5'>
            {desktopPageTitle}
            <div className='carrito-height grid grid-cols-1 lg:grid-cols-2 gap-x-4 flex mt-4'>
              <div>
                {!loading ? desktopAddressBlock : null}
                {loading ? (
                  <RegularSpinner />
                ) : items.length ? (
                  <div className='grid grid-cols-1 gap-y-4 w-full'>{formatedItems}</div>
                ) : (
                  noData
                )}
              </div>
              {address ? basketResume : null}
            </div>
            {isModalOpen ? desktopModal : null}
          </div>
        </>
      ) : (
        <>
          <TopMenu
            title={GlobalService.uppercaseFirstLetter(t('pages.tienda.basket'))}
            leftIcon={LeftArrowSmall}
            leftIconLink={'/tienda'}
          ></TopMenu>
          <div className='carrito-height-mobile mt-24 mx-2 pb-2'>
            {loading ? (
              <RegularSpinner />
            ) : items.length ? (
              <>
                {desktopAddressBlock}
                {formatedItems}
              </>
            ) : (
              noData
            )}
            {address ? basketResume : null}
          </div>
          {isModalOpen ? desktopModal : null}
        </>
      )}
      {showYesNoModal ? (
        <ModalYesNo
          onClickYes={handleClickYesNo}
          onClickNo={() => setShowYesNoModal(false)}
          bodyQuestion={yesNoModalText}
        />
      ) : null}
      {showModal ? (
        <ModalInfo
          bodyText={modalMessage}
          buttonText={GlobalService.uppercaseJustFirstLetter(t('close'))}
          onClickOk={handleClickModal}
        />
      ) : null}
      {showPaymentModal ? (
        <ModalPayment
          onClose={handleClosePayment}
          userData={userData}
          addressData={address}
          totalAmount={unformattedTotalAmount}
          handlePaymentResult={handlePaymentResult}
          isLoading={isLoading}
          billingCycle={meses}
          isSubscription={isRecurringPurchase}
          orderId={orderData && orderData.id ? orderData.id : undefined}
        />
      ) : null}
    </div>
  );
};

export default Carrito;
