import styles from './services.module.css';
import classNames from 'classnames/bind';
import { useState } from 'react';

// Components
import { Pagination } from '../../components/Pagination';
import RegularSpinner from '../../components/Spinner/RegularSpinner';
import Title from '../../components/Title';
import { ItemCard, TopMenu } from '../../components';

// Services
import { GlobalService } from '../../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import { useTranslation } from 'react-i18next';

// CSS
import '../../styles/Personas/Personas.css';

// Hooks
import { useQuotes, useViewport } from '../../hooks';

const cx = classNames.bind(styles);

export const Services = () => {
  const { t } = useTranslation();
  const { paidQuotes: paidItems, unpaidQuotes: unpaidItems, isLoading } = useQuotes();

  // States
  const [selected, setSelected] = useState<'pending' | 'complete'>('pending');
  const [numberItems] = useState<number>(6);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const { isScreenBig } = useViewport();

  const serviciosQuotes = selected === 'pending' ? unpaidItems : paidItems;

  /***********
   * Handlers *
   ***********/
  const handlePagination = (page: number) => {
    setCurrentPage(page);
    const result = selected === 'pending' ? unpaidItems : paidItems;
    setTotalPages(Math.ceil(result.length / numberItems));
  };

  const handleSelectType = (type: 'pending' | 'complete') => {
    setSelected(type);
    setCurrentPage(1);
  };

  /**************
   * JSX Values *
   **************/

  return (
    <div className={cx('services--main-container')}>
      <TopMenu title={GlobalService.uppercaseFirstLetter(t('pages.pagos.services'))} />

      <div className={cx('services--inner-container')}>
        <div className='3xs:hidden md:flex text-left'>
          <div className='w-11/12 text-center md:text-left'>
            <Title
              text={GlobalService.uppercaseFirstLetter(t('pages.pagos.services'))}
              color='black'
              size={'24px'}
            />
          </div>
        </div>

        <div className={`grid 3xs:mt-20 3xs:mb-4 md:mt-6 md:mb-9 Personas-Visitas-Menu`}>
          <div className={`flex flex-row Personas-Title${isScreenBig ? '' : '-small pl-3 pt-3'} `}>
            <div>
              <p
                className={
                  selected === 'pending' ? 'cursor-pointer pb-4 ml-2' : 'pb-4 cursor-pointer ml-2'
                }
                onClick={selected === 'pending' ? () => {} : () => handleSelectType('pending')}
              >
                {GlobalService.uppercaseFirstLetter(t('pages.services.paymentPending'))}
              </p>

              {selected === 'pending' ? (
                <div className='Personas-Visitas-Menu-Selected'></div>
              ) : null}
            </div>

            <div>
              <p
                className={
                  selected === 'complete'
                    ? 'ml-10 cursor-pointer pb-4'
                    : 'ml-10 pb-4 cursor-pointer'
                }
                onClick={selected === 'complete' ? () => {} : () => handleSelectType('complete')}
              >
                {GlobalService.uppercaseFirstLetter(t('pages.services.paymentCompleted'))}
              </p>

              {selected === 'complete' ? (
                <div className='ml-5 Personas-Visitas-Menu-Selected'></div>
              ) : null}
            </div>
          </div>
        </div>

        {isLoading ? (
          <RegularSpinner />
        ) : (
          <div className={cx('items-container')}>
            {serviciosQuotes.length ? (
              serviciosQuotes.map((item: any, index: any) => (
                <ItemCard key={index} patientQuote={item} />
              ))
            ) : (
              <p>{GlobalService.uppercaseFirstLetter(t('nodata'))}</p>
            )}
          </div>
        )}

        {totalPages > 1 ? (
          isLoading ? null : (
            <div className='3xs:my-2 sm:my-4 lg:my-10 m-auto'>
              <Pagination
                totalPages={totalPages}
                currentPage={currentPage}
                onChange={handlePagination}
              />
            </div>
          )
        ) : null}
      </div>
    </div>
  );
};
