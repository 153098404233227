import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Components
import { ButtonBase } from '../components/Buttons/button-base';
import { Pagination } from '../components/Pagination';
import RegularSpinner from '../components/Spinner/RegularSpinner';
import Title from '../components/Title';
import { TopMenu } from '../components';
import ModalRecurringPurchase from '../components/Modals/ModalRecurringPurchase';
import ModalYesNo from '../components/Modals/ModalYesNo';
import ModalInfo from '../components/Modals/ModalInfo';

// Services
import { GlobalService } from '../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import { tiendaServices } from '../hexagonal-architecture-frontend-base/src/infrastructure/services/tienda.service';
import { tiendaRepositoryInstance } from '../hexagonal-architecture-frontend-base/src/infrastructure/instances/tiendaRepository';
import { useTranslation } from 'react-i18next';

// CSS
import '../styles/Notificaciones/Notificaciones.css';

// Interfaces
import {
  IOrder,
  IOrderItem,
} from '../hexagonal-architecture-frontend-base/src/domain/models/IPago';

// Images
import LeftArrow from '../img/Notificaciones/left-arrow.png';
import LeftArrowSmall from '../img/PersonaImg/left-arrow-short.png';

// Hooks
import { useViewport } from '../hooks/use-viewport';

const Subscripciones = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  // States
  const [numberItems] = useState<number>(8);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [suscriptionsItems, setSuscriptionsItems] = useState<any>([]);
  const [selectedSubscription, setSelectedSubscription] = useState<IOrder>();
  const [showRecurringModal, setShowRecurringModal] = useState<boolean>(false);
  const { viewportWidth } = useViewport();
  const [showModalMessage, setShowModalMessage] = useState<boolean>(false);
  const [modalMessage, setModalMessage] = useState<string>('');
  const [modalQuestion, setModalQuestion] = useState<string>('');
  const [months, setMonths] = useState<number>(1);
  const [showYesNoModal, setShowYesNoModal] = useState<boolean>(false);

  // Get Data
  useEffect(() => {
    getSubscriptionsData();
  }, []);

  // Handlers
  const handleClickYes = () => {
    if (
      modalQuestion === GlobalService.uppercaseFirstLetter(t('pages.pagos.updateSubscription')) &&
      selectedSubscription
    )
      handleUpdateSubscription(months, selectedSubscription);
    else handleDeleteSubscription();
    setShowYesNoModal(false);
  };

  const handlePagination = (e: number) => {
    setCurrentPage(e);
    setLoading(true);
    tiendaServices(tiendaRepositoryInstance)
      .getSuscriptionsItems(numberItems, currentPage)
      .then(data => {
        if (typeof data !== 'string') {
          setSuscriptionsItems(data);
        } else {
          setSuscriptionsItems([]);
        }

        setLoading(false);
      });
  };

  // Functions
  const getSubscriptionsData = async () => {
    await tiendaServices(tiendaRepositoryInstance)
      .getSuscriptionsItems(numberItems, currentPage)
      .then(data => {
        if (typeof data !== 'string') {
          setSuscriptionsItems(data);
        } else {
          setSuscriptionsItems([]);
        }

        setLoading(false);
      });
    await tiendaServices(tiendaRepositoryInstance)
      .getSuscriptionsItemsCount()
      .then(count => {
        if (typeof count !== 'string') {
          setTotalPages(Math.ceil(count / numberItems));
        } else {
          setTotalPages(0);
        }
      });
  };

  const getPriceFromItems = (item: IOrder): string => {
    let value = 0;

    if (item && item.items && Array.isArray(item.items) && item.items.length > 0) {
      value = item?.items.reduce(
        (accumulator, currentValue) =>
          accumulator +
          Number(currentValue.Products.price.toString().replace(',', '.')) * currentValue.quantity,
        0,
      );
    }
    return value + ' €';
  };

  const getImageFromItems = (item: IOrder): string => {
    let imageUrl = '';

    if (item && item.items && Array.isArray(item.items) && item.items.length > 0) {
      item.items.map((item: IOrderItem) => {
        item.Products.image.map((image: string) => {
          if (image !== '' && imageUrl === '') imageUrl = image;
        });
      });
    }
    return imageUrl;
  };

  const parseFutureDate = (date: string, frequency: number): string => {
    const dateAux = GlobalService.addTimeToDate(date, 0, frequency, 0);
    return dateAux.day + '/' + dateAux.month + '/' + dateAux.year;
  };

  /************
   * Handlers *
   ************/
  const handleShowRecurringModal = (item: IOrder) => {
    setSelectedSubscription(item);
    setShowRecurringModal(true);
  };

  const handleUpdateSubscription = async (months: number, order?: IOrder) => {
    if (months && order && order.subscriptionId && order.id) {
      const editedOrder = { ...order, frequency: months };
      await tiendaServices(tiendaRepositoryInstance)
        .updateStripeSubscription(months, order.subscriptionId)
        .then(async (res: string) => {
          if (res === 'succeeded') {
            await tiendaServices(tiendaRepositoryInstance)
              .updateOrder(editedOrder)
              .then((res: string) => {
                if (res === 'succeeded') {
                  setModalMessage('pages.pagos.succeedeedUpdateSubscription');
                  setShowModalMessage(true);
                  setShowRecurringModal(false);
                  getSubscriptionsData();
                } else {
                  setModalMessage(res);
                  setShowModalMessage(true);
                }
              });
          }
        });
    } else {
      setModalMessage('errors.genericError');
      setShowModalMessage(true);
    }
  };

  const handleShowYesNoModal = (order?: IOrder, isCancel?: boolean, months?: number) => {
    if (order) {
      setSelectedSubscription(order);
      if (isCancel)
        setModalQuestion(GlobalService.uppercaseFirstLetter(t('pages.pagos.cancelSubscription')));
      if (months) {
        setMonths(months);
        setModalQuestion(GlobalService.uppercaseFirstLetter(t('pages.pagos.updateSubscription')));
      }
      setShowYesNoModal(true);
    }
  };

  const handleDeleteSubscription = async () => {
    if (selectedSubscription && selectedSubscription.id && selectedSubscription.subscriptionId) {
      await tiendaServices(tiendaRepositoryInstance)
        .cancelStripeSubscription(selectedSubscription.subscriptionId)
        .then(async (res: string) => {
          if (res === 'succeeded') {
            const editedSelectedSubscription = { ...selectedSubscription, status: 'canceled' };
            await tiendaServices(tiendaRepositoryInstance)
              .updateOrder(editedSelectedSubscription)
              .then((res: string) => {
                if (res === 'succeeded') {
                  setModalMessage('pages.pagos.succeedeedCancelSubscription');
                  setShowModalMessage(true);
                  setShowRecurringModal(false);
                  setShowYesNoModal(false);
                  getSubscriptionsData();
                } else {
                  setModalMessage(res);
                  setShowModalMessage(true);
                }
              });
          }
        });
    } else {
      setModalMessage('errors.genericError');
      setShowModalMessage(true);
    }
  };

  const handleCloseModal = () => {
    setShowRecurringModal(false);
  };

  /**************
   * JSX Values *
   **************/
  const isScreenBig = viewportWidth > 768;
  const noData = <p>{GlobalService.uppercaseFirstLetter(t('nodata'))}</p>;
  const topMenuProperties: any = {
    title: GlobalService.uppercaseFirstLetter(
      t(`pages.pagos.${isScreenBig ? 'title' : 'subscriptions'}`),
    ),
    fixed: !isScreenBig,
  };
  if (!isScreenBig) {
    topMenuProperties.leftIcon = LeftArrowSmall;
    topMenuProperties.leftIconLink = '/pagos';
  }

  const formatedSubscriptionsItems = suscriptionsItems.length
    ? suscriptionsItems.map((item: any, index: any) => (
        <div
          key={index}
          className='relative p-2 md:p-4 flex justify-between servicios-container'
          style={{ background: '#D6D6E533' }}
        >
          <div className='flex'>
            <img
              src={getImageFromItems(item)}
              alt=''
              className='w-[70px] h-[70px] md:w-[125px] md:h-[125px] border-2'
            />
            <div className='flex flex-col justify-between ml-3'>
              <div>
                <p>
                  <strong className={isScreenBig ? '' : 'valor-small'}>
                    {item?.items[0]?.Products?.name}
                  </strong>
                </p>
                <p className='mb-2' style={{ color: '#28337d' }}>
                  <strong>{getPriceFromItems(item)}</strong>
                </p>
              </div>
              <div className='text-xs'>
                <p>
                  <strong>
                    {GlobalService.uppercaseFirstLetter(t('pages.pagos.nextDelivery'))}:{' '}
                    {parseFutureDate(item.lastReceivedAt, item.frequency)}
                  </strong>
                </p>
              </div>
            </div>
          </div>
          <div className='absolute top-3 right-0 w-24 md:w-32'>
            <ButtonBase
              text={GlobalService.uppercaseFirstLetter(t('pages.pagos.modify'))}
              background='white'
              color={item.status !== 'paid' || !item.recurrent ? 'grey' : '#28337d'}
              border={
                item.status !== 'paid' || !item.recurrent ? '1px solid grey' : '1px solid #28337d'
              }
              borderRadius='50px'
              paddingX={6}
              paddingY={isScreenBig ? 4 : 2}
              extraClass='informe-button'
              fontSize={isScreenBig ? '16px' : '12px'}
              buttonWidth={isScreenBig ? '111px' : '85px'}
              minWidth={isScreenBig ? '80px' : '73px'}
              minHeight={isScreenBig ? '38px' : '27px'}
              onClick={() => handleShowRecurringModal(item)}
              disabled={item.status !== 'paid' || !item.recurrent}
            />
          </div>
        </div>
      ))
    : noData;

  const recurringModal = (
    <ModalRecurringPurchase
      orderItem={selectedSubscription}
      onclickCancel={order => handleShowYesNoModal(order, true)}
      onclickContinue={(months, order) => handleShowYesNoModal(order, false, months)}
      cancelButtonText={GlobalService.uppercaseFirstLetter(t('pages.tienda.cancelOrder'))}
      onClose={handleCloseModal}
    />
  );

  const YesNoModal = (
    <ModalYesNo
      onClickYes={handleClickYes}
      onClickNo={() => setShowYesNoModal(false)}
      bodyQuestion={modalQuestion}
    />
  );

  const messageModal = (
    <ModalInfo bodyText={modalMessage} onClickOk={() => setShowModalMessage(false)} />
  );

  // Desktop values
  const desktopSubTitle = (
    <>
      <div className='w-[50px]'>
        {isScreenBig ? (
          <img
            onClick={() => {
              navigate('/pagos');
            }}
            src={LeftArrow}
            alt='Back'
            className='Notificaciones-Arrow cursor-pointer'
          />
        ) : (
          <img
            onClick={() => {
              navigate('/pagos');
            }}
            src={LeftArrowSmall}
            alt='Back'
            className='my-auto ml-2 cursor-pointer w-[26px] h-[28px]'
          />
        )}
      </div>

      <div className='w-full text-center md:text-left'>
        <Title
          text={GlobalService.uppercaseFirstLetter(t('pages.pagos.subscriptions'))}
          color='black'
          size={'24px'}
        />
      </div>
    </>
  );
  return (
    <div className='w-full'>
      {isScreenBig ? <TopMenu {...topMenuProperties} /> : null}
      <div
        className={`w-full ${
          isScreenBig ? 'flex flex-col gap-2 pb-6 px-10' : 'mt-8 mb-24 pb-4 px-6'
        }`}
      >
        <div className='flex text-left my-4'>{desktopSubTitle}</div>
        <div className='suscriptions-height grid grid-cols-1 xl:grid-cols-2 auto-rows-min gap-y-4 gap-x-8 w-full'>
          {loading ? <RegularSpinner /> : formatedSubscriptionsItems}
        </div>
        {totalPages > 1 ? (
          loading ? null : (
            <div className=' m-auto'>
              <Pagination
                totalPages={totalPages}
                currentPage={currentPage}
                onChange={handlePagination}
              />
            </div>
          )
        ) : null}
      </div>
      {showRecurringModal ? recurringModal : null}
      {showYesNoModal ? YesNoModal : null}
      {showModalMessage ? messageModal : null}
    </div>
  );
};

export default Subscripciones;
