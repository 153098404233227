import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HelpButton, VideoCallIcon, WhatsappIcon } from '../../../components';
import { HelpButtonProps } from 'src/ts';
import { faEnvelope, faPhone, faStopwatch } from '@fortawesome/free-solid-svg-icons';
import { AVAILABLE_SETTINGS, PHONE_PREFIX } from '../../../consts';
import { GlobalService } from '../../../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import { useTranslation } from 'react-i18next';
import { useIframePopup, useSettings } from '../../../hooks';

export const useHelpButtons = (textBase: string) => {
  const { t } = useTranslation();
  const { getSettingKey } = useSettings();
  const { showIframePopup } = useIframePopup();

  const phoneText = GlobalService.uppercaseFirstLetter(t(`${textBase}.${AVAILABLE_SETTINGS.CALL}`));
  const whatsappText = GlobalService.uppercaseFirstLetter(
    t(`${textBase}.${AVAILABLE_SETTINGS.WHATSAPP}`),
  );
  const scheduleCallText = GlobalService.uppercaseFirstLetter(
    t(`${textBase}.${AVAILABLE_SETTINGS.SCHEDULE_CALL}`),
  );
  const videoCallText = GlobalService.uppercaseFirstLetter(
    t(`${textBase}.${AVAILABLE_SETTINGS.VIDEO_CALL}`),
  );
  const sendEmailText = GlobalService.uppercaseFirstLetter(
    t(`${textBase}.${AVAILABLE_SETTINGS.SEND_EMAIL}`),
  );

  const helpButtonsObjects: HelpButtonProps[] = [
    {
      icon: <FontAwesomeIcon color='#E96E81' icon={faPhone} />,
      link: `tel:${PHONE_PREFIX}${getSettingKey(AVAILABLE_SETTINGS.CALL).replaceAll(' ', '')}`,
      text: phoneText,
    },
    {
      icon: <WhatsappIcon />,
      link: getSettingKey(AVAILABLE_SETTINGS.WHATSAPP),
      text: whatsappText,
    },
    {
      icon: <FontAwesomeIcon color='#E96E81' icon={faStopwatch} />,
      text: scheduleCallText,
      onClick: () =>
        showIframePopup(getSettingKey(AVAILABLE_SETTINGS.SCHEDULE_CALL), scheduleCallText),
    },
    {
      icon: <VideoCallIcon />,
      text: videoCallText,
      onClick: () => showIframePopup(getSettingKey(AVAILABLE_SETTINGS.VIDEO_CALL), videoCallText),
    },
    {
      icon: <FontAwesomeIcon color='#E96E81' icon={faEnvelope} />,
      link: `mailto:${getSettingKey(AVAILABLE_SETTINGS.SEND_EMAIL)}`,
      text: sendEmailText,
    },
  ];

  const helpButtons = helpButtonsObjects.map((button, index) => (
    <HelpButton
      key={index}
      icon={button.icon}
      link={button.link!}
      text={button.text}
      onClick={button.onClick}
    />
  ));

  return { helpButtons };
};
