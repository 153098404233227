import { FC, useLayoutEffect } from 'react';
import { useSetTopMenu } from '../../../hooks';
import { TopMenuProps } from '../../../ts';

import LeftArrowSmall from '../../../img/PersonaImg/left-arrow-short.png';

interface TopMenuSetterProps extends Omit<TopMenuProps, 'leftIcon'> {
  leftIcon?: string | boolean;
}

export const TopMenu: FC<TopMenuSetterProps> = props => {
  const { setTopMenuProps } = useSetTopMenu();

  const finalProps: TopMenuProps = {
    ...props,
    leftIcon: !props.leftIcon
      ? undefined
      : props.leftIcon === true
      ? LeftArrowSmall
      : props.leftIcon,
  };

  useLayoutEffect(() => {
    setTopMenuProps(finalProps);
  }, [props]);

  return null;
};
