// CSS
import '../styles/Facturas/Facturas.css';

import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// Components
import RegularSpinner from '../components/Spinner/RegularSpinner';
import Title from '../components/Title';
import { TopMenu } from '../components';

// Services
import { GlobalService } from '../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import { useTranslation } from 'react-i18next';

// Images
import LeftArrow from '../img/PersonaImg/left-arrow.png';
import LeftArrowSmall from '../img/PersonaImg/left-arrow-short.png';
import Pdf from '../img/pdf.png';

// Hooks
import {
  useViewport,
  usePatientsValue,
  useSelectedPatient,
  useGetInvoices,
  useShowDownloadInvoicePopup,
} from '../hooks';

const Facturas = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { patientId } = useParams();
  const { isLoading } = usePatientsValue();
  const { selectedPatient, setSelectedPatient } = useSelectedPatient();
  const { invoices, getInvoices } = useGetInvoices();
  const { showDownloadInvoicePopup } = useShowDownloadInvoicePopup();

  /**********
   * States *
   **********/
  const { viewportWidth } = useViewport();

  useEffect(() => {
    setSelectedPatient(patientId as string);
  }, [patientId]);

  useEffect(() => {
    getInvoices({ patientId });
  }, [selectedPatient]);

  /************
   * Handlers *
   ************/

  /***********
   * Helpers *
   ***********/

  const parseDate = (dateToParse: string): string => {
    const month = new Date(dateToParse).toLocaleString('es-ES', { month: 'short' });
    const day = new Date(dateToParse).toLocaleString('es-ES', { day: '2-digit' });
    const year = new Date(dateToParse).toLocaleString('es-ES', { year: 'numeric' });
    return `${month[0].toUpperCase()}${month.substring(1).toLowerCase()} ${day}, ${year}`;
  };

  /**************
   * JSX Values *
   **************/
  const isScreenBig = viewportWidth > 1000;

  const FormattedFacturas = () =>
    invoices?.map((item: any, index: number) => (
      <div key={index} className='flex h-[70px] factura-container p-3'>
        <div className='w-full flex'>
          <div className='min-w-[52px] h-[52px] border border-stone-300 rounded'>
            <img src={Pdf} className='min-w-[27px] h-[30px] mx-auto mt-[9px] mb-[9px]' alt='pdf' />
          </div>
          <div className='my-auto'>
            <p className={`ml-2 ${isScreenBig ? '' : 'text-xs'}`}>
              <strong>{item.Subject}</strong>
            </p>
            {isScreenBig ? null : (
              <p className='factura-text-small ml-2'>
                {GlobalService.uppercaseFirstLetter(t('dateOfInvoice'))}:{' '}
                <strong>{parseDate(item.Fecha_Factura)}</strong>
              </p>
            )}
          </div>
        </div>
        {isScreenBig ? (
          <div className='w-full my-auto'>
            <p>
              {GlobalService.uppercaseFirstLetter(t('dateOfInvoice'))}:{' '}
              <strong>{parseDate(item.Fecha_Factura)}</strong>
            </p>
          </div>
        ) : null}

        <div className={`text-right my-auto ${isScreenBig ? '' : 'factura-text-small'}`}>
          <p onClick={showDownloadInvoicePopup} className='download-link cursor-pointer mr-4'>
            {GlobalService.uppercaseFirstLetter(t('download'))}
          </p>
        </div>
      </div>
    ));

  const topMenuProperties: any = {
    title: `${GlobalService.uppercaseFirstLetter(
      t(isScreenBig ? 'pages.facturas.mainTitle' : 'pages.facturas.title'),
    )}${isScreenBig ? ':' : ''}`,
    fixed: !isScreenBig,
    user: selectedPatient.title,
  };
  if (!isScreenBig) {
    topMenuProperties.leftIcon = LeftArrowSmall;
    topMenuProperties.leftIconLink = `/datosDocumentos/${patientId}/1`;
    delete topMenuProperties.user;
  }

  return (
    <div className='w-full'>
      <TopMenu {...topMenuProperties} />
      <div className={`w-full 3xs:p-2 md:p-5`}>
        {isScreenBig ? (
          <div className='3xs:hidden md:flex text-left mb-6'>
            <img
              onClick={() => {
                navigate(`/datosDocumentos/${patientId}/1`);
              }}
              src={LeftArrow}
              alt='Back'
              className='Notificaciones-Arrow cursor-pointer'
            />
            <Title
              text={`${GlobalService.uppercaseFirstLetter(t('pages.facturas.title'))}:`}
              color='black'
              size={'24px'}
              margin={'0px 0px 0px 30px'}
            />
          </div>
        ) : null}
        {isLoading ? (
          <RegularSpinner />
        ) : invoices?.length ? (
          <FormattedFacturas />
        ) : (
          <p>{GlobalService.uppercaseFirstLetter(t('nodata'))}</p>
        )}
      </div>
    </div>
  );
};

export default Facturas;
