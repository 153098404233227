import axios from 'axios';
import { AddressData } from '../../../ts';
import { Getter } from 'jotai';
import { selectedPatientAtom } from '../../../store/global/atoms';
import { DEFAULT_ADDRESS } from '../../../consts';

export const getUserAddressService = (get: Getter) => async () => {
  const type = 'addressenviamentcomanda';
  try {
    const response = await axios.get<AddressData>(`/addresses${type ? `?types=${type}` : ''}`);

    if (response.data.id === '') {
      const selectedPatientAddress = get(selectedPatientAtom).carehomeAddress;

      if (selectedPatientAddress != null) {
        const address: AddressData = {
          ...DEFAULT_ADDRESS,
          Pa_s: selectedPatientAddress.country ?? '',
          Ciudad: selectedPatientAddress.city ?? '',
          Codigo_postal: selectedPatientAddress.postalCode ?? '',
          Direcci_n: selectedPatientAddress.street ?? '',
          isPatientFallback: true,
        };

        return address;
      }
    }

    return response.data;
  } catch (error) {
    throw new Error('errors.addressesNotFound');
  }
};
