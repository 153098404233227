import styles from './user-button.module.css';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames/bind';
import { useSession } from '../../../hooks';
// Images
import User from '../../../img/PersonaImg/avatarLura.png';
const cx = classNames.bind(styles);

export const UserButton = () => {
  const navigate = useNavigate();
  const { userInfo, isUserLogged } = useSession();
  const [photo, setPhoto] = useState<string>('');

  if (userInfo == null) return null;

  const tutorName = isUserLogged
    ? userInfo.name == null
      ? null
      : userInfo.name.length > 15
      ? userInfo.name.substring(0, 14) + '...'
      : userInfo.name
    : null;

  useEffect(() => {
    if (isUserLogged) {
      setPhoto(userInfo.photo ? `data:image/*;base64,${userInfo.photo}` : User);
    }
  }, [userInfo.photo]);

  return (
    <button
      className={cx(
        'avatar-container',
        'cursor-pointer',
        'flex',
        'justify-between',
        'items-center',
        'gap-4',
      )}
      onClick={() => navigate('/profile')}
    >
      <img src={photo} className='max-h-10 rounded-full' />

      {tutorName != null ? (
        <p title={tutorName} className={cx('tutor-name')}>
          {/* TODO - apply text limit via css */}
          {/* {tutorName ? GlobalService.limitText(tutorName, getSizeOfText()) : ''} */}
          {tutorName}
        </p>
      ) : null}
    </button>
  );
};
