import { PopupOptionsSetter } from 'src/ts';
import { useSetPopup } from '../use-popup';
import { GlobalService } from '../../../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import { useTranslation } from 'react-i18next';
import { ConfirmPaymentMethodPopup } from '../../../components';

export const useConfirmPaymentMethodPopup = () => {
  const { t } = useTranslation();
  const { openPopup } = useSetPopup();

  const showConfirmPaymentMethodPopup = (onSuccess: () => void) => {
    const confirmPaymentMethodPopupOptions: PopupOptionsSetter = {
      title: GlobalService.uppercaseJustFirstLetter(
        t('modal.transferencia.confirmPaymentMethodModalTitle'),
      ),
      children: <ConfirmPaymentMethodPopup onSuccessCallback={onSuccess} />,
    };

    openPopup(confirmPaymentMethodPopupOptions);
  };

  return {
    showConfirmPaymentMethodPopup,
  };
};
