import styles from './confirm-payment-method-popup.module.css';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../components/Buttons';
import { GlobalService } from '../../../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import {
  useSelectedPatient,
  useSelectedQuote,
  useSetPopup,
  useSetUrlToReturn,
  useUpdateQuotePaymentType,
} from '../../../hooks';
import { FC } from 'react';

const cx = classNames.bind(styles);

interface ConfirmPaymentMethodPopupProps {
  onSuccessCallback?: () => void;
}

export const ConfirmPaymentMethodPopup: FC<ConfirmPaymentMethodPopupProps> = ({
  onSuccessCallback = () => {},
}) => {
  const { t } = useTranslation();
  const { selectedQuote, removeSelectedQuote } = useSelectedQuote();
  const { selectedPatient, removeSelectedPatient } = useSelectedPatient();
  const { updateQuotePaymentType, isLoading: isUpdatingPaymentType } = useUpdateQuotePaymentType();
  const { setUrlToReturn } = useSetUrlToReturn();
  const navigate = useNavigate();
  const { closePopup, setForceKeepOpen } = useSetPopup();

  const handleSelectPayMethod = async () => {
    if (selectedQuote?.id) {
      setForceKeepOpen.enable();
      updateQuotePaymentType.transfer(selectedQuote.id, {
        onSuccess: () => {
          onSuccessCallback();
          setUrlToReturn(`/informes/${selectedPatient.id}`);
          setTimeout(() => {
            removeSelectedQuote();
            removeSelectedPatient();
            navigate('/allPatients');
          }, 6000);
        },
        onSettled: () => {
          setForceKeepOpen.disable();
          closePopup();
        },
      });
    }
  };

  return (
    <div className={cx('container')}>
      <p>
        {GlobalService.uppercaseJustFirstLetter(
          t('modal.transferencia.confirmPaymentMethodModalBody'),
        )}
      </p>

      <div className={cx('buttons-container')}>
        <Button styleType='secondary' onClick={() => closePopup()} fullWidth={true}>
          {t('cancel')}
        </Button>

        <Button onClick={handleSelectPayMethod} isLoading={isUpdatingPaymentType} fullWidth={true}>
          {t('confirm')}
        </Button>
      </div>
    </div>
  );
};
