import { useAtomValue } from 'jotai';
import {
  getPatientsQuotesAtom,
  isGettingAllPatientsAtom,
  paidAndUnpaidItemsAtom,
} from '../../../store/global/atoms';

export const useQuotes = () => {
  const paidAndUnpaidQuotes = useAtomValue(paidAndUnpaidItemsAtom);
  const allQuotes = useAtomValue(getPatientsQuotesAtom);
  const isGettingAllPatients = useAtomValue(isGettingAllPatientsAtom);

  return {
    allQuotes: [...paidAndUnpaidQuotes.paidItems, ...paidAndUnpaidQuotes.unpaidItems],
    paidQuotes: paidAndUnpaidQuotes.paidItems,
    unpaidQuotes: paidAndUnpaidQuotes.unpaidItems,
    isLoading: allQuotes.isPending || isGettingAllPatients,
    error: allQuotes.error,
  };
};
