import axios from 'axios';
import type { ApiResponse, TutorInfo, UpdateUserObject } from '../../ts';

export const updateUserService = async (newUser: UpdateUserObject): Promise<TutorInfo> => {
  const newUserCopy: TutorInfo = { ...newUser };
  delete newUserCopy.id;

  let response: ApiResponse<TutorInfo>;
  try {
    const axiosResponse = await axios.put<ApiResponse<TutorInfo>>('/users/me', newUserCopy);

    response = axiosResponse.data;
  } catch (error: any) {
    // console.error('error.message', error.message);
    throw new Error(error.message);
  }

  if (response.isOk !== true) {
    // console.log('[updateUserService] response', response);

    if (response.message.toLowerCase() === 'invalid sms code') {
      throw new Error('modal.verifyMobile.errors.invalidSmsCode');
    }

    throw new Error('errors.updateInvalid');
  }

  return response.data;
};
