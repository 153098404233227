import styles from './top-menu.module.css';
import classNames from 'classnames/bind';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Components
import Title from '../../Title';
import { NotificationsButton } from '../../Buttons/notifications-button';
import { UserButton } from '../../Buttons/user-button';
import InputOld from '../../../components/Inputs/InputOld';

// Services
import { GlobalService } from '../../../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import { notificationsServices } from '../../../hexagonal-architecture-frontend-base/src/infrastructure/services/notifications.service';
import { notificationsRepositoryInstance } from '../../../hexagonal-architecture-frontend-base/src/infrastructure/instances/notificationsRepository';
import { useTranslation } from 'react-i18next';

// CSS
import '../../../styles/Notificaciones/Notificaciones.css';
import '../../../styles/TopMenu/TopMenu.css';

// Icons
import { faMagnifyingGlass, faCartShopping } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { INotification } from 'src/hexagonal-architecture-frontend-base/src/domain/models/INotification';

// Hooks
import { useViewport } from '../../../hooks/use-viewport';
import { useSession, useTopMenuValue } from '../../../hooks';
import { Button } from '../..';

const cx = classNames.bind(styles);

export const TopMenuBase = () => {
  const {
    handleSearch,
    leftIcon,
    leftIconLink = '',
    searchInput,
    searchValue = '',
    title,
    userName,
    cartiItems = 0,
    textCentered = false,
    updateCount = 0,
  } = useTopMenuValue();

  const { t } = useTranslation();
  const pathName = new URLSearchParams(window.location.pathname);
  const searchParams = new URLSearchParams(window.location.search);
  const navigate = useNavigate();
  const { isUserLogged } = useSession();

  /**********
   * States *
   **********/
  const { viewportWidth } = useViewport();
  const [notifications, setNotifications] = useState<INotification[]>([]);
  const [totalNotifications, setTotalNotifications] = useState<number>(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      getInformation();
      getInformationCount();
    }, 1000);

    return () => clearTimeout(timer);
  }, [updateCount]);

  useEffect(() => {
    // console.log('updateCount', updateCount);
    getInformationCount();
  }, [updateCount]);

  useEffect(() => {
    if (GlobalService.reloadNotificationsEvery5Minutes) {
      getInformationEvery5Minutes();
      GlobalService.reloadNotificationsEvery5Minutes = false;
    }
  });

  /************
   * Handlers *
   ************/
  const goToLeftIconLink = () => {
    const path = searchParams.get('url');
    if (path) navigate(`${path.replace('=', '')}`);
    else navigate(leftIconLink as string);
  };

  /**************
   * Functions *
   **************/
  const getInformationEvery5Minutes = () => {
    setTimeout(() => {
      if (isUserLogged) getInformationCount();
      getInformationEvery5Minutes();
    }, 60000);
  };

  const getInformation = () => {
    if (!isUserLogged) return;

    notificationsServices(notificationsRepositoryInstance)
      .getNotifications(5, 1, 'false')
      .then(data => {
        if (typeof data !== 'string') {
          setNotifications(data);
        } else {
          setNotifications([]);
        }
      });
  };

  const getInformationCount = () => {
    if (!isUserLogged) return;

    notificationsServices(notificationsRepositoryInstance)
      .getNotificationsNonReadCount()
      .then(data => {
        if (typeof data !== 'string') {
          setTotalNotifications(data);
        } else {
          setTotalNotifications(0);
        }
      });
  };

  const markNotificationAsSeen = (id: number) => {
    if (!isUserLogged) return;

    notificationsServices(notificationsRepositoryInstance)
      .markNotificationAsSeen(id)
      .then(() => {
        getInformationCount();
        getInformation();
      });
  };

  const goToCarrito = () => {
    navigate(`/carrito?url=${pathName}`);
  };

  const getSizeOfText = () => {
    if (
      location.pathname.includes('blog') ||
      location.pathname.includes('producto') ||
      location.pathname.includes('pagos') ||
      location.pathname.includes('help') ||
      location.pathname.includes('carrito')
    )
      return 50;
    if (location.pathname.includes('tienda') && viewportWidth > 1300) return 50;
    if (viewportWidth > 1300) return 30;
    // if (viewportWidth > 1200) return 25;
    // if (viewportWidth > 1100) return 20;
    // if (viewportWidth > 1000) return 25;
    if (viewportWidth > 900) return 25;
    if (viewportWidth > 890) return 23;
    if (viewportWidth > 880) return 22;
    if (viewportWidth > 870) return 21;
    if (viewportWidth > 860) return 20;
    if (viewportWidth > 850) return 19;
    if (viewportWidth > 840) return 18;
    if (viewportWidth > 830) return 17;
    if (viewportWidth > 820) return 16;
    if (viewportWidth > 810) return 15;
    if (viewportWidth > 800) return 14;
    if (viewportWidth > 750) return 30;
    if (viewportWidth > 500) return 30;
    if (viewportWidth > 400) return 20;
    if (viewportWidth > 300) return 15;
    if (viewportWidth > 100) return 10;
    return 30;
  };

  /*******
   * JSX *
   *******/
  return (
    <div className={cx('top-menu--container')}>
      <div
        className={`${textCentered ? 'w-full ' : ''} flex gap-2 text-left flex-row items-center`}
      >
        {leftIcon ? (
          <img
            src={leftIcon}
            alt='Back'
            className='title-icon cursor-pointer'
            onClick={goToLeftIconLink}
          />
        ) : null}
        <div className={`${textCentered ? 'w-full md:w-fit text-center md:text-left' : ''}`}>
          <Title text={title} color='#28337D' fontWeight={300} className='top-menu-title' />
        </div>
        <h2 className='top-menu-title' style={{ color: '#28337D' }}>
          {userName != null ? GlobalService.limitText(userName, getSizeOfText()) : ''}
        </h2>
      </div>

      {!isUserLogged && viewportWidth > 768 ? (
        <div className='gap-x-2 hidden xs:flex'>
          <Button onClick={() => navigate('/login')}>
            {GlobalService.uppercaseFirstLetter(t('pages.login.title'))}
          </Button>

          <Button styleType='secondary' onClick={() => navigate('signup')}>
            {GlobalService.uppercaseFirstLetter(t('pages.signup.register'))}
          </Button>
        </div>
      ) : (
        <div className='flex items-center'>
          <div className='flex gap-x-4 items-center'>
            {searchInput && handleSearch ? (
              <div className='search-input-container flex items-center cursor-pointer bg-white min-w-min'>
                <FontAwesomeIcon icon={faMagnifyingGlass} />
                <InputOld
                  id='searchInput'
                  type='text'
                  placeholder={GlobalService.uppercaseFirstLetter(t('search'))}
                  value={searchValue}
                  customClass='search-input'
                  name='searchInput'
                  onChange={e => handleSearch(e)}
                />
              </div>
            ) : null}

            {searchInput ? (
              <div
                className='bell-container flex justify-center items-center'
                onClick={() => goToCarrito()}
              >
                <FontAwesomeIcon icon={faCartShopping} onClick={() => goToCarrito()} />
                {cartiItems > 0 ? (
                  <div className='notification-advice flex items-center justify-center'>
                    <p>{cartiItems}</p>
                  </div>
                ) : null}
              </div>
            ) : null}

            {isUserLogged && viewportWidth >= 769 ? (
              <NotificationsButton
                numberNotifications={totalNotifications}
                notifications={notifications}
                onClickNotificationLink={markNotificationAsSeen}
              />
            ) : null}

            <UserButton />
          </div>
        </div>
      )}
    </div>
  );
};
