import { useAtom, useAtomValue } from 'jotai';
import {
  blindSignupTokenAtom,
  getBstInfoAtom,
  setTutorToPatientInBstAtom,
} from '../../store/global/atoms';
import { useLocation } from 'react-router-dom';
import { useEffect, useLayoutEffect } from 'react';
import { MutationOptions } from 'jotai-tanstack-query';
import { SetTutorToPatientInBstServiceParams } from 'src/ts';
import { useShowErrorPopup } from '../popup';
import { useTranslation } from 'react-i18next';

const SUPPORTED_PAGES = ['/signup', '/login'];

export const useBlindSignupToken = () => {
  const { t } = useTranslation();
  const [blindSignupToken, setBlindSignupToken] = useAtom(blindSignupTokenAtom);
  const location = useLocation();
  const bstInfo = useAtomValue(getBstInfoAtom);
  const setPatientTutor = useAtomValue(setTutorToPatientInBstAtom);
  const { showErrorPopup } = useShowErrorPopup();

  const isBstValid = bstInfo.isIdle || bstInfo.isPending ? null : bstInfo.data != null;

  const resetBlindSignupToken = () => setBlindSignupToken(undefined);

  useLayoutEffect(() => {
    if (!SUPPORTED_PAGES.includes(location.pathname)) return;
    const queryParams = new URLSearchParams(location.search);

    const blindSignupToken = queryParams.get('bst');

    if (blindSignupToken == null) return;

    setBlindSignupToken(blindSignupToken);
  }, [location]);

  useEffect(() => {
    if (blindSignupToken == null || bstInfo.data != null) return;

    bstInfo.mutate(blindSignupToken);
  }, [blindSignupToken]);

  useEffect(() => {
    if (isBstValid !== false || blindSignupToken == null) return;

    showErrorPopup(t('modal.addPatientFromBst.tokenNotValidError'));
  }, [bstInfo.data, bstInfo.isIdle, bstInfo.isPending]);

  const handleSetPatientTutor = (
    tutorId: number,
    options?: MutationOptions<Boolean, Error, SetTutorToPatientInBstServiceParams>,
  ) => {
    if (blindSignupToken == null) return;
    setPatientTutor.mutate({ tutorId, bst: blindSignupToken }, options);
  };

  return {
    blindSignupToken,
    bstInfo: bstInfo.data,
    isBstInfoLoading: bstInfo.isPending,
    bstInfoError: bstInfo.error,
    isBstValid,
    setBlindSignupToken,
    resetBlindSignupToken,
    setPatientTutorResult: setPatientTutor.data,
    setTutorToPatient: handleSetPatientTutor,
    setTutorToPatientAsync: setPatientTutor.mutateAsync,
    isSetTutorToPatientLoading: setPatientTutor.isPending,
    setTutorToPatientError: setPatientTutor.error,
  };
};
