import axios from 'axios';
import { ApiResponse, SessionWithBstInfo } from '../../ts';

interface RegisterParams {
  mobile: string;
  password: string;
  smsCode: string;
  blindSignupToken?: string;
}

export const signupService = async ({
  mobile,
  password,
  smsCode,
  blindSignupToken,
}: RegisterParams) => {
  let response: ApiResponse<SessionWithBstInfo>;

  try {
    const axiosResponse = await axios.post<ApiResponse<SessionWithBstInfo>>('/users/register', {
      mobile,
      password,
      code: smsCode,
      blindToken: blindSignupToken,
    });

    response = axiosResponse.data;
  } catch (error: any) {
    throw new Error('errors.unexpectedError');
  }

  if (response.isOk === false) {
    console.log('response.message', response.message);
    if (response.message.toLowerCase() === 'mobile already registered') {
      throw new Error('modal.verifyMobile.errors.mobileAlreadyRegistered');
    }

    if (response.message.toLowerCase() === 'invalid sms code') {
      throw new Error('modal.verifyMobile.errors.invalidSmsCode');
    }

    if (response.message.toLowerCase() === 'invalid bst') {
      throw new Error('pages.login-signup.bstNotValid');
    }

    throw new Error('errors.unexpectedError');
  }

  return response.data;
};
