import styles from './send-sms-code.module.css';
import classNames from 'classnames/bind';
import { FC, useEffect } from 'react';
import { Button } from '../../../../components/Buttons';
import { useVerifyMobile, useMobileVerificationTimestamps } from '../../../../hooks';
import { GlobalService } from '../../../../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import { useTranslation } from 'react-i18next';

interface SendSMSCodeProps {
  mobile: string;
  isVerifyLoading: boolean;
}

const cx = classNames.bind(styles);

export const SendSMSCode: FC<SendSMSCodeProps> = ({ mobile, isVerifyLoading }) => {
  const { t } = useTranslation();
  const { sendSMS, isSendSMSLoading } = useVerifyMobile();
  const { timeLeftForNextSMS, timeLeftForNextBlock, limitPerBlockReached, canResendSMS } =
    useMobileVerificationTimestamps();

  const count = limitPerBlockReached ? timeLeftForNextBlock : timeLeftForNextSMS;

  useEffect(() => {
    sendSMS(mobile, {
      onSuccess: value => {
        console.log('send SMS value', value);
      },
    });
  }, []);

  return (
    <div className={cx('container')}>
      {!canResendSMS ? (
        <p>
          {GlobalService.uppercaseJustFirstLetter(
            t('modal.verifyMobile.resendCodeAvailable', { count }),
          )}
        </p>
      ) : null}
      <Button
        styleType='onlyText'
        onClick={() =>
          sendSMS(mobile, {
            onSuccess: value => {
              console.log('value', value);
            },
          })
        }
        className={cx('button')}
        disabled={isSendSMSLoading || !canResendSMS || isVerifyLoading}
      >
        {GlobalService.uppercaseFirstLetter(t('modal.verifyMobile.resendSmsCode'))}
      </Button>
    </div>
  );
};
