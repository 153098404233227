import axios from 'axios';
import { ApiResponse } from 'src/ts';

type IsMobileRegisteredResponse = ApiResponse<{ isRegistered: boolean }>;

export const getIsMobileRegisteredService = async (mobile: string): Promise<boolean> => {
  let response: IsMobileRegisteredResponse;
  const url = '/users/is-mobile-registered';

  try {
    const axiosResponse = await axios.post<IsMobileRegisteredResponse>(url, { mobile });

    response = axiosResponse.data;
  } catch (error: any) {
    throw new Error('errors.mobileAlreadyRegistered_FetchError');
  }

  if (response.isOk === false) {
    throw new Error(response.message);
  }

  return response.data.isRegistered;
};
