import { useEffect, useState } from 'react';

// Components
import Card from '../components/Cards/Card';
import { Pagination } from '../components/Pagination';
import RegularSpinner from '../components/Spinner/RegularSpinner';
import { TopMenu } from '../components';

// Services
import { blogServices } from '../hexagonal-architecture-frontend-base/src/infrastructure/services/blog.service';
import { blogRepositoryInstance } from '../hexagonal-architecture-frontend-base/src/infrastructure/instances/blogRepository';
import { GlobalService } from '../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import { useTranslation } from 'react-i18next';

// CSS
import '../styles/Blog/Blog.css';

// Hooks
import { useViewport } from '../hooks/use-viewport';

interface IArticle {
  image: string | undefined;
  link: string;
  title: string;
}

const Blog = () => {
  const { t } = useTranslation();

  /**********
   * States *
   **********/
  const [areArticlesDone, setAreArticlesDone] = useState<boolean>(false);
  const [blogItems, setBlogItems] = useState<any>([]);
  const [blogItemsBackUp, setBlogItemsBackUp] = useState<IArticle[]>([]);
  const [numberItems] = useState(8);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showFeeds, setShowFeeds] = useState<boolean>(true);
  const { viewportWidth } = useViewport();

  const getBlogData = () => {
    if (blogItemsBackUp?.length) {
      setBlogItems(blogItemsBackUp);
      setIsLoading(false);
    } else {
      blogServices(blogRepositoryInstance)
        .getBlogs()
        .then(blogs => {
          if (blogs !== 'invalidFeed') {
            const blogItems = getBlogItems(blogs.data);
            setAreArticlesDone(true);
            setBlogItemsBackUp(blogItems);
            setBlogItems(
              blogItems.slice((currentPage - 1) * numberItems, currentPage * numberItems),
            );
            setTotalPages(Math.ceil(blogItems.length / numberItems));
            setIsLoading(false);
          } else {
            setShowFeeds(false);
          }
        });
    }
    setIsLoading(false);
  };

  // Get data
  useEffect(() => {
    getBlogData();
  }, []);

  /***********
   * Helpers *
   ***********/
  const getBlogItems = (xmlTxt: any) => {
    const blogItems: IArticle[] = [];
    // Convert xml
    const xml = new DOMParser().parseFromString(xmlTxt, 'text/xml');
    // Get the items
    const items = xml.getElementsByTagName('item');

    for (let i = 0; i < items.length; i++) {
      const article: IArticle = { image: '', link: '', title: '' };
      const childs: any = items[i].children;
      for (const node of childs) {
        if (node.nodeName === 'title' || node.nodeName === 'link') {
          article[node.nodeName as keyof IArticle] = node.textContent;
        } else if (node.nodeName === 'content:encoded') {
          const contentXml = new DOMParser().parseFromString(node.textContent, 'text/xml');
          const imgs = contentXml.getElementsByTagName('img');
          article.image = imgs[0]?.attributes?.getNamedItem('src')?.value;
        }
      }
      blogItems.push(article);
    }
    return blogItems;
  };

  /************
   * Handlers *
   ************/
  const handlePagination = (e: number) => {
    setCurrentPage(e);
    setBlogItems(blogItemsBackUp.slice((e - 1) * numberItems, e * numberItems));
  };

  // JSX Values
  document.title = `LuraCare - ${GlobalService.uppercaseFirstLetter(t('pages.blog.title'))}`;
  const isScreenBig = viewportWidth > 768;
  const noData = <p>{GlobalService.uppercaseFirstLetter(t('nodata'))}</p>;
  // Formatted blog items
  const formattedBlogItems = blogItems.map((item: IArticle, index: any) => (
    <Card
      key={index}
      name='1'
      topImage={item.image}
      width={390}
      padding='1rem'
      backgroundColor='white'
      borderShadow='0px 4px 8px rgba(0, 0, 0, 0.1)'
      action={() => {
        null;
      }}
      numberItems={4}
    >
      <a target='_blank' href={item.link} rel='noreferrer' className='Blog-Text'>
        {item.title}
      </a>
    </Card>
  ));

  const paginator =
    totalPages > 1 ? (
      <div className='m-auto flex'>
        <Pagination totalPages={totalPages} currentPage={currentPage} onChange={handlePagination} />
      </div>
    ) : null;

  const topMenuProperties: any = {
    title: GlobalService.uppercaseFirstLetter(t('pages.blog.title')),
    fixed: !isScreenBig,
  };

  return (
    <div className='w-full'>
      <TopMenu {...topMenuProperties}></TopMenu>
      <div className='blog-height flex flex-col gap-2 w-full mt-[100px] md:mt-[40px]'>
        {isLoading ? (
          <RegularSpinner />
        ) : showFeeds ? (
          <>
            <div
              className={
                viewportWidth >= 1024
                  ? 'grid gap-6 grid-cols-4'
                  : viewportWidth >= 480
                  ? 'grid gap-6 grid-cols-2'
                  : 'grid gap-6 grid-cols-1 mb-12'
              }
            >
              {blogItems.length ? formattedBlogItems : areArticlesDone ? noData : null}
            </div>
            {paginator}
          </>
        ) : areArticlesDone ? (
          noData
        ) : null}
      </div>
    </div>
  );
};

export default Blog;
