// Services
import { personasServices } from '../../infrastructure/services/personas.service';
import { personasRepositoryInstance } from '../../infrastructure/instances/personasRepository';

// Interfaces
import { IArticle } from '../models/IArticle';
import { ICount, IPatient, IPatientDeal, IPatientReport, IPatientVisit, IPersonasReturn, IReportShortArticle } from '../models/IPersona';
import { IProduct } from '../models/IProduct';
import { IQuotedItem } from '../models/IArticle';
import { IUploadResponse } from '../models/IUploadResponse';

/**********
 * Values *
 **********/
let patientsCount = 0;
let searchedPatients: IPatient[] = [];
let searchValue = '';
let selectedPatient: IPatient = {
  dealDetails: {
    Archivo_Consentimientos: [],
    Autorizaciones_Firmadas: false,
    Deal_Name: '',
    id: '',
    Patomedi: false,
    Signed_SEPA: false,
    Created_Time: 0,
    Modified_Time: 0
  },
  id: '0',
  image: '',
  title: '',
  residence: '',
  email: '',
  eternalReviewAuth: false,
  birthDate: null
};
let servicePatients: IPatient[] = [];
// reportsCache is only needed for getItemsPayed()
let reportsCache: IPatientReport[] = [];

let activePatientReport: IPatientReport;

// ID of the patient to get the reports
let patientReportsId: unknown = undefined;
let patientReports: IPatientReport[] | undefined = [];
let patientVisits: IPatientVisit[] | undefined = [];
// Id of the patient to get the visits
let patientVisitsId: unknown = undefined;

let urlToReturn = '';

/***********
 * Helpers *
 ***********/
const assignDealsDetails = async (dealData: IPatientDeal[]) => {
  servicePatients.forEach(patient => {
    const deals = dealData.filter(item => item.Contact_Name.id === patient.id);
    if (deals.length) {
      let sortedData = deals;
      if (deals.length > 1) {
        sortedData = deals.sort((a: any, b: any) =>
          new Date(a.Created_Time).toISOString() > new Date(b.Created_Time).toISOString() ? -1 : 1
        );
      }
      const values = sortedData[0];
      patient.dealDetails.Archivo_Consentimientos = values.Archivo_Consentimientos;
      patient.dealDetails.Archivo_Patomedi = values.Archivo_Patomedi;
      patient.dealDetails.Autorizaciones_Firmadas = values.Autorizaciones_Firmadas;
      patient.dealDetails.Deal_Name = values.Deal_Name;
      patient.dealDetails.Pago_Inicial = values.Pago_Inicial;
      patient.dealDetails.Pago_Final = values.Pago_Final;
      patient.dealDetails.Patomedi = values.Patomedi;
      patient.dealDetails.SEPA_File = values?.SEPA_File;
      patient.dealDetails.Signed_SEPA = values.Signed_SEPA;

      patient.dealDetails.id = values.id;
      patient.pendingDocs =
        !patient.dealDetails.Archivo_Consentimientos || !patient.dealDetails.Autorizaciones_Firmadas || !patient.dealDetails.Patomedi;
    } else {
      patient.pendingDocs = false;
    }
  });
};

const assignReportsDetails = async (reportsData: IPatientReport[]) => {
  servicePatients.forEach((patient: IPatient) => {
    const reports = reportsData.filter(item => item.Contact_Name.id === patient.id);
    const sortedReports = reports.sort((a: IPatientReport, b: IPatientReport) => {
      return new Date(a.Fecha_de_la_Visita) > new Date(b.Fecha_de_la_Visita) ? 1 : -1;
    });
    const patientReports: IPatientReport[] = [];
    sortedReports.forEach((report: IPatientReport) => {
      const datas = parseFechaVisita(report.Fecha_de_la_Visita);
      const result: IPatientReport = parseReportData(report, datas);
      patientReports.push(result);
      patient.reportsDetails = patientReports;
      const pendingReports = patientReports.filter(
        (report: IPatientReport) =>
          report.Quote_Stage.includes('Presupuesto revisado') || report.Quote_Stage.includes('Presupuesto en espera')
      );
      if (!patient.pendingReports) patient.pendingReports = !!pendingReports.length;
    });
  });
};

const assignVisitsDetails = async (visitsData: IPatientVisit[]) => {
  servicePatients.forEach(patient => {
    const visits = visitsData.filter(item => item.Paciente.id === patient.id);
    // Ordeno les visites per data
    const sortedVisitas = visits.sort((a: IPatientVisit, b: IPatientVisit) => {
      return new Date(a.Fecha_de_la_visita) > new Date(b.Fecha_de_la_visita) ? 1 : -1;
    });
    const patientVisits: IPatientVisit[] = [];
    sortedVisitas.forEach((visita: any) => {
      const fechaVisita = new Date(visita.Fecha_de_la_visita);
      const fecha = `${fechaVisita.getDate()}/${(fechaVisita.getMonth() + 1).toString().padStart(2, '0')}/${fechaVisita.getFullYear()}`;
      const tratamiento = visita.Tratamiento.length ? visita.Tratamiento.join(', ') : '';
      const estadoVisita = visita.Estado_de_la_visita.length ? visita.Estado_de_la_visita.join(', ') : '';
      const result = {
        Doctor: visita.Doctor?.name ?? 'null',
        Estado_de_la_visita: estadoVisita,
        Fecha_de_la_visita: fecha,
        Fecha_de_la_visita_raw: visita.Fecha_de_la_visita,
        Notas_del_dentista: visita.Notas_del_dentista,
        PatientId: patient.id,
        Paciente: visita.Paciente,
        Tipo_de_visita: visita.Tipo_de_visita,
        Tratamiento: tratamiento,
        VisitId: visita.id,
        patient: visita.patient
      };
      patientVisits.push(result);
    });
    patient.visitsDetails = patientVisits;
    const pendingVisits = patientVisits.filter(
      (visit: IPatientVisit) =>
        visit.Estado_de_la_visita.includes('Visita pendiente') && new Date(visit.Fecha_de_la_visita_raw) >= new Date()
    );
    patient.pendingVisits = !!pendingVisits.length;
  });
};

const buildReportsCache = () => {
  let result: IPatientReport[] = [];
  servicePatients.forEach(item => {
    if (item.reportsDetails) {
      result = [...result, ...item.reportsDetails];
    }
  });
  reportsCache = result;
};

const buildResponse = (data: any[], page: number, per_page: number) => {
  const newServiceInfo = {
    count: data.length,
    more_records: page * per_page < data.length,
    next_page_token: null,
    page: page,
    page_token_expiry: null,
    per_page,
    previous_page_token: null
  };
  return { data: data.slice((page - 1) * per_page, page * per_page), info: newServiceInfo };
};

const buildResponseAllData = (data: any[]) => {
  const newServiceInfo = {
    count: data.length,
    more_records: false,
    next_page_token: null,
    page: 0,
    page_token_expiry: null,
    per_page: 'all',
    previous_page_token: null
  };
  return { data, info: newServiceInfo };
};

const chunk = (arr: any[], size: number) =>
  Array.from({ length: Math.ceil(arr.length / size) }, (v, i) => arr.slice(i * size, i * size + size));

const clearNames = (): string[] => {
  const names: string[] = [];
  servicePatients.forEach(item => {
    if (item.title.includes('(')) {
      const tmp1 = item.title.substring(0, item.title.indexOf('('));
      names.push(tmp1);
    } else if (item.title.includes(',')) {
      const tmp1 = item.title.substring(0, item.title.indexOf(','));
      names.push(tmp1);
    } else {
      names.push(item.title);
    }
  });
  return names;
};

const deleteActivePatientReport = () => {
  activePatientReport = {
    Articles: undefined,
    Contact_Name: { name: '', id: '' },
    Currency: '',
    Estado_del_pago: '',
    Facturado: '',
    Fecha_de_la_Visita: '',
    Fecha_de_la_Visita_raw: '',
    Grand_Total: 0,
    id: '',
    Identificador: '',
    Importe_Aceptado: 0,
    Nombre_del_Dentista: { name: '', id: '' },
    Quote_Stage: '',
    Subject: '',
    Tipo_de_informe: 'it',
    Tipo_de_Pago: '',
    patient: { name: '', id: '' },
    fechaAceptacion: '',
    isRejected: false,
    isPayable: false,
    media: []
  };
  patientReportsId = undefined;
  patientReports = [];
  patientVisits = [];
  patientVisitsId = undefined;
};

const deleteSelectedPatient = () => {
  selectedPatient = {
    dealDetails: {
      Archivo_Consentimientos: [],
      Autorizaciones_Firmadas: false,
      Deal_Name: '',
      id: '',
      Patomedi: false,
      Signed_SEPA: false,
      Created_Time: 0,
      Modified_Time: 0
    },
    id: '0',
    image: '',
    title: '',
    residence: '',
    email: '',
    eternalReviewAuth: false,
    birthDate: null
  };
};

const getAndSetDealsData = async (block: string[], page: number): Promise<IPatientDeal[] | string> => {
  let dealsData: IPatientDeal[] = [];
  const response = await personasServices(personasRepositoryInstance).getAllPatientDeals(block, page);
  if (typeof response !== 'string') {
    dealsData = [...dealsData, ...response.data];
    if (response.info.more_records) {
      const res = await getAndSetDealsData(block, ++page);
      if (typeof res !== 'string') {
        dealsData = [...dealsData, ...res];
      }
    }
  } else {
    return response;
  }
  return dealsData;
};

const getAndSetReportsData = async (block: string[], page: number): Promise<IPatientReport[] | string> => {
  let reportsData: IPatientReport[] = [];
  const response = await personasServices(personasRepositoryInstance).getAllPatientReports(block, page);
  if (typeof response !== 'string') {
    if (response.info.more_records) {
      reportsData = [...reportsData, ...response.data];
      const res = await getAndSetReportsData(block, ++page);
      if (typeof res !== 'string') {
        reportsData = [...reportsData, ...res];
        return reportsData;
      }
    } else reportsData = [...reportsData, ...response.data];
    return reportsData;
  } else {
    return response;
  }
};

const getAndSetVisitsData = async (block: string[], page: number): Promise<IPatientVisit[] | string> => {
  let visitsData: IPatientVisit[] = [];
  const response = await personasServices(personasRepositoryInstance).getAllPatientVisits(block, page);
  if (typeof response !== 'string') {
    visitsData = [...visitsData, ...response.data];
    if (response.info.more_records && page < 10) {
      const res = await getAndSetVisitsData(block, ++page);
      if (typeof res !== 'string') {
        visitsData = [...visitsData, ...res];
        return visitsData;
      }
    }
    return visitsData;
  } else {
    return response;
  }
};

const parseFechaVisita = (fechaVisita: string) => {
  let fechaReport: string[] = [];
  if (fechaVisita) {
    const f = fechaVisita.split('-');
    fechaReport = [f[2], f[1], f[0]];
  } else {
    const ara = new Date();
    const mes = ara.getMonth() + 1;
    const mesData: string = mes.toString().padStart(2, '0');
    fechaReport = [ara.getDate().toString().padStart(2, '0'), mesData, ara.getFullYear().toString()];
  }
  const fecha = `${fechaReport[0]}/${fechaReport[1].padStart(2, '0')}/${fechaReport[2]}`;
  const fechaRaw = new Date(parseInt(fechaReport[2]), parseInt(fechaReport[1]) - 1, parseInt(fechaReport[0]) + 1).toISOString();
  return { fecha, fechaRaw };
};

const parseReportData = (report: IPatientReport, datas: { fecha: string; fechaRaw: string }): IPatientReport => {
  return {
    Contact_Name: report.Contact_Name,
    Currency: report.Currency,
    Estado_del_pago: report.Estado_del_pago,
    Facturado: report.Facturado,
    Fecha_de_la_Visita: datas.fecha,
    Fecha_de_la_Visita_raw: datas.fechaRaw,
    Grand_Total: report.Grand_Total,
    id: report.id,
    Identificador: report.Identificador,
    Importe_Aceptado: report.Importe_Aceptado,
    Nombre_del_Dentista: report.Nombre_del_Dentista,
    Quote_Stage: report.Quote_Stage,
    Subject: report.Subject,
    Tipo_de_informe: report.Tipo_de_informe,
    Tipo_de_Pago: report.Tipo_de_Pago,
    patient: report.patient,
    fechaAceptacion: report.fechaAceptacion,
    isRejected: report.isRejected,
    isPayable: report.isPayable,
    media: []
  };
};
/**
 * Returns the reports data paginated to the component
 * @param page
 * @returns
 */
const reportsToReturn = (page: number) => {
  if (!patientReports?.length) {
    return 'noReportsData';
  }
  const totalPages = Math.ceil(patientReports.length / 2);
  const data = patientReports.slice((page - 1) * 2, 2 * page);
  return { data, totalPages };
};

/**
 * Returns an IPatient array sorted by name and warnings
 */
const sortPatientsArray = (patientsArray: IPatient[]): IPatient[] => {
  const tmp1 = patientsArray
    .filter(item => item.pendingDocs || item.pendingReports || item.pendingVisits)
    .sort((a: IPatient, b: IPatient) => (a.title > b.title ? 1 : -1));
  const tmp2 = patientsArray
    .filter(item => !item.pendingDocs && !item.pendingReports && !item.pendingVisits)
    .sort((a: IPatient, b: IPatient) => (a.title > b.title ? 1 : -1));
  return [...tmp1, ...tmp2];
};

/**
 * Returns the visits data paginated to the component
 * @param page
 * @param pendingVisits
 * @returns
 */
const visitsToReturn = (page: number, pendingVisits: boolean) => {
  if (!patientVisits?.length) {
    return 'noVisitsData';
  }

  const filteredPatients = patientVisits.filter((visit: IPatientVisit) =>
    pendingVisits
      ? visit.Estado_de_la_visita.includes('Visita pendiente') && new Date(visit.Fecha_de_la_visita_raw) >= new Date()
      : !visit.Estado_de_la_visita.includes('Visita pendiente') || new Date(visit.Fecha_de_la_visita_raw) < new Date()
  );
  const totalPages = Math.ceil(filteredPatients.length / 2);
  const data = filteredPatients.slice((page - 1) * 2, 2 * page);
  return { data, totalPages };
};

export const PatientsService = {
  deleteActivePatientReport: () => deleteActivePatientReport(),
  deleteConsent: async (data: { Deal_Name: string; fileId: string; recordId: string }): Promise<object | string> => {
    return await personasServices(personasRepositoryInstance).deleteConsent(data);
  },
  deleteContactConsent: async (data: { fileId: string; patientId: string }): Promise<object | string> => {
    return await personasServices(personasRepositoryInstance).deleteContactConsent(data);
  },
  deletePatoMedi: async (Deal_Name: string, contactName: string, fileId: { deal: string; contact: string }) => {
    const response = await personasServices(personasRepositoryInstance).deletePatoMedi(Deal_Name, contactName, fileId);
    return response;
  },
  getArticleProduct: async (articleId: string): Promise<IProduct[] | string> => {
    const articleData = await personasServices(personasRepositoryInstance).getProduct(articleId);
    return articleData;
  },
  getArticles: async (reportId: string): Promise<IPatientReport | string> => {
    if ((activePatientReport && reportId !== activePatientReport?.id) || !activePatientReport?.Articles?.length) {
      const quote: IArticle[] | string = await personasServices(personasRepositoryInstance).getReportArticles(reportId);

      if (typeof quote !== 'string') {
        const products: IReportShortArticle[] = [];

        const patientProductPromises = quote[0].Quoted_Items.map((item: IQuotedItem) => {
          return PatientsService.getArticleProduct(item.Product_Name.id);
        });

        const patientProductResponses = await Promise.all(patientProductPromises);

        quote[0].Quoted_Items.forEach((item: IQuotedItem, index: number) => {
          const product: IProduct[] | string = patientProductResponses[index];
          if (typeof product !== 'string') {
            const newArticle: IReportShortArticle = {
              description: '',
              id: product[0].id,
              isSelected: true,
              isShowingDescription: false,
              name: '',
              price: -1
            };
            newArticle.name = item.Product_Name.name;
            newArticle.price = item.Total;
            newArticle.description = product[0].Texto_del_Informe || '';
            products.push(newArticle);
          }
        });
        activePatientReport.Articles = products;
        return activePatientReport as IPatientReport;
      } else {
        return quote;
      }
    } else {
      return activePatientReport;
    }
  },
  getDealFile: async (module: string, dealId: string, fileId: string, File_Name__s: string) => {
    return await personasServices(personasRepositoryInstance).getFile(module, dealId, fileId, File_Name__s);
  },
  getItemsPayed: (payed: boolean, page: number, per_page: number) => {
    const payedItems = reportsCache.filter(item => {
      if (payed) return item.Quote_Stage.toLowerCase() === 'presupuesto aceptado';
      else return item.Quote_Stage.toLowerCase() !== 'presupuesto aceptado';
    });
    const sortedItems = payedItems.sort((a, b) => (a.Subject > b.Subject ? 1 : -1));
    return buildResponse(sortedItems, page, per_page);
  },
  getPatientById: (patientId: string): IPatient | undefined => {
    return servicePatients.find(el => el.id === patientId);
  },
  getPatientReports: (id: string, page: number) => {
    if (patientReportsId !== id) {
      patientReportsId = id;
      const patient = servicePatients.find((patient: IPatient) => patient.id === id);
      patientReports = patient?.reportsDetails;
      // Ordeno els informes posant els que tenen prioritat davant
      if (patientReports?.length) {
        const tmp1 = patientReports.filter(item => item.Quote_Stage.toLowerCase() === 'presupuesto revisado');
        const tmp2 = patientReports.filter(item => item.Quote_Stage.toLowerCase() === 'presupuesto en espera');
        const tmp3 = patientReports.filter(
          item => item.Quote_Stage.toLowerCase() !== 'presupuesto revisado' && item.Quote_Stage.toLowerCase() !== 'presupuesto en espera'
        );
        const sortedReports = [...tmp1, ...tmp2, ...tmp3];
        patientReports = sortedReports;
      }
    }
    return reportsToReturn(page);
  },
  getPatientVisits: (id: string, page: number, pendingVisits: boolean) => {
    if (patientVisitsId !== id) {
      patientVisitsId = id;
      const patient = servicePatients.find((patient: IPatient) => patient.id === id);
      patientVisits = patient?.visitsDetails;
    }
    return visitsToReturn(page, pendingVisits);
  },
  getReportsCache: (page: number, per_page: number) => {
    return buildResponse(reportsCache, page, per_page);
  },
  getSearchValue: () => searchValue,
  getUrlToReturn: () => urlToReturn,
  isServicePatientsEmpty: () => !servicePatients.length,

  loadPatients: async (userId: number, reload?: boolean): Promise<IPersonasReturn | string> => {
    reload = reload || false;
    if (reload) {
      servicePatients = [];
      selectedPatient.id = '';
    }

    if (!servicePatients.length) {
      const responsePacients = await personasServices(personasRepositoryInstance).getPatients(userId);

      if (typeof responsePacients === 'string') {
        return responsePacients;
      }
      servicePatients = responsePacients.data;
      // Netejo els noms de '(' i ','
      const names = clearNames();
      // Divideixo names en blocks de 100
      const chunkedNames = chunk(names, 100);
      const dealsPromises: any = [];
      const reportsPromises: any = [];
      const visitsPromises: any = [];
      chunkedNames.forEach(block => {
        dealsPromises.push(getAndSetDealsData(block, 1));
        reportsPromises.push(getAndSetReportsData(block, 1));
        visitsPromises.push(getAndSetVisitsData(block, 1));
      });
      const dealsResponses = await Promise.all(dealsPromises);
      const reportsResponses = await Promise.all(reportsPromises);
      const visitsResponses = await Promise.all(visitsPromises);
      let dealsData: IPatientDeal[] = [];
      let reportsData: IPatientReport[] = [];
      let visitsData: IPatientVisit[] = [];
      if (dealsResponses[0] !== 'invalidDealData') {
        dealsResponses.forEach(response => (dealsData = [...dealsData, ...response]));
      }
      if (reportsResponses[0] !== 'invalidReportsData') {
        reportsResponses.forEach(response => (reportsData = [...reportsData, ...response]));
      }
      if (visitsResponses[0] !== 'invalidVisitsData') {
        visitsResponses.forEach(response => (visitsData = [...visitsData, ...response]));
      }
      await assignDealsDetails(dealsData);
      await assignReportsDetails(reportsData);
      await assignVisitsDetails(visitsData);
      buildReportsCache();
    }
    if (searchValue) {
      searchedPatients = servicePatients.filter((patient: IPatient) => patient.title.toLowerCase().includes(searchValue.toLowerCase()));
      searchedPatients = sortPatientsArray(searchedPatients);
    }
    servicePatients = sortPatientsArray(servicePatients);
    return buildResponseAllData(searchValue ? searchedPatients : servicePatients);
  },
  reloadCache: () => {
    deleteSelectedPatient();
    deleteActivePatientReport();
    servicePatients = [];
    patientsCount = 0;
    reportsCache = [];
  },
  setActivePatient: (patientId: string): IPatient | string => {
    if (!selectedPatient || selectedPatient.id !== patientId) {
      const patient = servicePatients.find(el => el.id === patientId);
      if (patient) {
        selectedPatient = patient;
        return selectedPatient;
      } else {
        return 'patientNotFound';
      }
    } else {
      return selectedPatient;
    }
  },
  setActiveReport: (report: IPatientReport) => {
    activePatientReport = report;
  },
  setActiveReportById: (reportId: string) => {
    if (reportId !== activePatientReport?.id) {
      servicePatients.forEach((patient: IPatient) => {
        patient.reportsDetails?.forEach((report: IPatientReport) => {
          if (report.id === reportId) {
            activePatientReport = report;
          }
        });
      });
    }
  },
  setReportById: (reports: IPatientReport[]) => {
    if (servicePatients.length) {
      servicePatients.forEach(patient => {
        if (patient.id === reports[0].Contact_Name.id && !patient.reportsDetails?.length) {
          const result: IPatientReport[] = [];
          reports.forEach((report: IPatientReport) => {
            const datas = parseFechaVisita(report.Fecha_de_la_Visita);
            const element: IPatientReport = parseReportData(report, datas);
            result.push(element);
          });
          patient.reportsDetails = result;
          const pendingReports = patient.reportsDetails.filter(
            (report: IPatientReport) =>
              report.Quote_Stage.includes('Presupuesto revisado') || report.Quote_Stage.includes('Presupuesto en espera')
          );
          if (!patient.pendingReports) patient.pendingReports = !!pendingReports.length;
        }
      });
    }
  },
  setSearchValue: (value: string) => {
    searchValue = value;
  },
  setServicePatients: (newData: IPatient[]) => {
    if (!servicePatients.length) {
      servicePatients = newData;
    }
  },
  setUrlToReturn: (url: string) => {
    urlToReturn = url;
  },
  totalPatients: async (id: number): Promise<ICount | string> => {
    if (!patientsCount) {
      const totalPatients = await personasServices(personasRepositoryInstance).getTotalPatients(id);
      if (typeof totalPatients !== 'string') {
        patientsCount = totalPatients.count;
        return { count: patientsCount };
      } else {
        return totalPatients;
      }
    } else {
      if (searchValue) {
        return { count: searchedPatients.length };
      }
      return { count: patientsCount };
    }
  },
  updateContactConsent: async (data: { fileId: string; patientId: string }): Promise<object | string> => {
    return await personasServices(personasRepositoryInstance).updateContactConsent(data);
  },
  updateConsent: async (data: { Deal_Name: string; fileId: string; recordId: string }): Promise<object | string> => {
    return await personasServices(personasRepositoryInstance).updateConsent(data);
  },
  updateDealAfterPayment: async (Deal_Name: string, DealId: string): Promise<object | string> => {
    return await personasServices(personasRepositoryInstance).updateDeal(Deal_Name, DealId, { Pago_Inicial: true, Pago_Final: true });
  },
  uploadPatoMedi: async (data: FormData, contactId: string, dealName: string): Promise<IUploadResponse | string> => {
    const response = await personasServices(personasRepositoryInstance).uploadPatoMedi(data, contactId, dealName);
    return response;
  },
  updateServiceAfterPayment: async (serviceId: string, subject: string, paidAmount: number): Promise<IUploadResponse | string> => {
    return await personasServices(personasRepositoryInstance).updateServiceAfterPayment(serviceId, subject, paidAmount);
  }
};
