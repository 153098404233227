import { FC } from 'react';

interface HelpButtonLinkProps {
  link?: string;
  children: React.ReactNode;
}

export const HelpButtonLink: FC<HelpButtonLinkProps> = ({ link, children }) => {
  if (!link) return <>{children}</>;

  return (
    <a href={link} target={link.includes('http') ? '_blank' : '_self'} rel='noreferrer noopener'>
      {children}
    </a>
  );
};
