import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// Components
import RegularSpinner from '../components/Spinner/RegularSpinner';
import Title from '../components/Title';
import { TopMenu } from '../components';

// Services
import { GlobalService } from '../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import { useTranslation } from 'react-i18next';

// Interfaces
import { IPatient } from '../hexagonal-architecture-frontend-base/src/domain/models/IPersona';

// CSS
import '../styles/Personas/Personas.css';

// Images
import DocumentationsGrey from '../img/PersonaImg/documentation-black.png';
import DocumentationsRed from '../img/PersonaImg/documentation-black.png';
import InvoicesGrey from '../img/PersonaImg/invoices-black.png';
import InvoicesRed from '../img/PersonaImg/invoices-black.png';
import LeftArrow from '../img/PersonaImg/left-arrow.png';
import LeftArrowSmall from '../img/PersonaImg/left-arrow-short.png';
import ReportsGrey from '../img/PersonaImg/reports-black.png';
import ReportsRed from '../img/PersonaImg/reports-black.png';
import VisitsGrey from '../img/PersonaImg/visits-black.png';
import VisitsRed from '../img/PersonaImg/visits-black.png';

// Hooks
import { usePatientsValue, useSelectedPatient, useViewport } from '../hooks';

interface IDocs {
  avisos?: boolean;
  link: string;
  title: string;
}

const DatosPersonaDocumentos = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { patientId, docId } = useParams();
  const { patients, isLoading: isPatientsLoading, isQuotesLoading } = usePatientsValue();
  const { selectedPatient, setSelectedPatient } = useSelectedPatient();
  const isDocumentosLoading = isPatientsLoading || isQuotesLoading;

  /**********
   * States *
   **********/
  const [documentos, setDocumentos] = useState<IDocs[]>([]);
  const { viewportWidth } = useViewport();

  useEffect(() => {
    if (patients.length === 0) return;
    /* Agafo les dades dels pacients */
    setSelectedPatient(patientId as string);
  }, [patients]);

  useEffect(() => {
    setDocumentos(createDocuments(selectedPatient));
  }, [selectedPatient]);

  /************
   * Handlers *
   ************/

  /* Navigates to the right url */
  const handleOnGoTo = (link: string) => {
    if (link === 'documentos') {
      // if (selectedPatient.dealDetails.Deal_Name) navigate(`/${link}/${patientId}`);
      navigate(`/${link}/${patientId}`);
    } else {
      navigate(`/${link}/${patientId}`);
    }
  };

  /***********
   * Helpers *
   ***********/
  const createDocuments = (patient: IPatient): IDocs[] => {
    return [
      { title: 'Documentations', link: 'documentos', avisos: patient.pendingDocs },
      { title: 'Visits', link: 'visitas', avisos: patient.pendingVisits },
      { title: 'Reports', link: 'informes', avisos: patient.pendingReports },
      { title: 'Invoices', link: 'facturas' },
    ];
  };

  /***************
   *  JSX values *
   ***************/
  const isScreenBig = viewportWidth > 768;
  const isScreenTiny = viewportWidth < 321;

  const documentosImages = [
    [DocumentationsGrey, DocumentationsRed],
    [VisitsGrey, VisitsRed],
    [ReportsGrey, ReportsRed],
    [InvoicesGrey, InvoicesRed],
  ];
  const noData = <p>{GlobalService.uppercaseFirstLetter(t('nodata'))}</p>;
  const pageNames: string[] = ['dentist', 'audiological', 'optics'];
  const pageName = pageNames[parseInt(docId as string) - 1];
  const pageTitle = `${GlobalService.uppercaseFirstLetter(t('historyOf'))}:`;

  // Desktop values
  const formattedDocumentos = documentos.map((item: IDocs, index: number) => (
    <div
      key={index}
      className={`m-auto Personas-Documentos${
        isScreenBig ? '' : '-Small flex flex-row'
      }  Personas-Documentos-${item.avisos ? 'Con-Aviso' : 'Sin-Aviso'}`}
      onClick={() => handleOnGoTo(item.link)}
    >
      <div
        className={`Personas-Circle${
          isScreenBig ? '' : isScreenTiny ? '-Very-Small' : '-Small'
        } Personas-${item.avisos ? 'Red' : 'Grey'}`}
      >
        <img
          className={`Personas-Documentos-Imagen-Little${
            isScreenTiny ? '-Tiny' : ''
          } Personas-Documentos-Imagen-Fixed${isScreenTiny ? '-Tiny' : ''}`}
          src={item.avisos ? documentosImages[index][1] : documentosImages[index][0]}
        ></img>
      </div>

      <p className={`Personas-Documentos-Texto${isScreenTiny ? '-Tiny' : ''}`}>
        {GlobalService.uppercaseFirstLetter(t(item.title.toLowerCase()))}
      </p>
      {item.avisos ? (
        <div
          className={`absolute ${
            !isScreenTiny ? `top-${isScreenBig ? '4' : '10'}` : 'red-Circle-Top-Tiny'
          } right-4 w-3 h-3 rounded-full bg-red-600`}
        ></div>
      ) : null}
    </div>
  ));

  // Responsive values
  const pageTitleUniversal = GlobalService.uppercaseFirstLetter(t(pageName), false);

  return (
    <div className='w-full'>
      {isScreenBig ? (
        <>
          <TopMenu title={pageTitle} userName={selectedPatient.title} />
          <div className='w-full p-5'>
            <div className='flex text-left'>
              <img
                onClick={() => {
                  navigate(`/datosPersona/${patientId}`);
                }}
                src={LeftArrow}
                alt='Back'
                className='Notificaciones-Arrow cursor-pointer'
              />

              <Title
                text={pageTitleUniversal}
                color='black'
                size={'24px'}
                margin={'0px 0px 0px 30px'}
              />
            </div>

            <div className='flex flex-col-4 gap-4 w-full p-5'>
              {isDocumentosLoading ? (
                <RegularSpinner />
              ) : (
                <div className='w-full grid grid-cols-2 xl:grid-cols-4 gap-5 mt-6'>
                  {documentos.length ? formattedDocumentos : noData}
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        <>
          <TopMenu
            title={pageTitleUniversal}
            leftIcon={LeftArrowSmall}
            leftIconLink={`/datosPersona/${patientId}`}
          />
          <div className='ml-2 mr-1 my-24'>
            {isDocumentosLoading ? (
              <RegularSpinner />
            ) : (
              <div className='flex flex-col gap-y-4'>
                {documentos.length ? formattedDocumentos : noData}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default DatosPersonaDocumentos;
