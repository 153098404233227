import styles from './login-input.module.css';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { GlobalService } from '../../../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import Input from '../../Inputs/InputOld';
import { FC } from 'react';

const cx = classNames.bind(styles);

interface LoginInputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  id: 'username' | 'password';
  label: string;
  placeholder: string;
  type: 'password' | 'text';
  enableShowPassword?: boolean;
  value: string;
  onChange: (id: 'username' | 'password', value: string) => void;
  errorMessage: string | null;
}

export const LoginInput: FC<LoginInputProps> = ({
  id,
  label,
  placeholder,
  type,
  enableShowPassword,
  value,
  onChange,
  errorMessage,
  ...rest
}) => {
  const { t } = useTranslation();

  return (
    <div className={cx('login-input--container')}>
      <label htmlFor={id} className={cx('login-input--label')}>
        {GlobalService.uppercaseFirstLetter(t(label))}
      </label>

      <div className='mt-1'>
        <Input
          enableShowPassword={enableShowPassword}
          id={id}
          name={id}
          placeholder={GlobalService.uppercaseFirstLetter(t(placeholder))}
          type={type}
          value={value}
          customClass={cx('login-input--input')}
          onChange={e => onChange(id, e)}
          {...rest}
        />
        {errorMessage != null ? (
          <p className='error-message my-2 mx-4'>
            {GlobalService.uppercaseFirstLetter(t(errorMessage))}
          </p>
        ) : null}
      </div>
    </div>
  );
};
