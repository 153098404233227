import { FC } from 'react';
import styles from './signature-iframe.module.css';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

interface SignatureIframeProps {
  url: string;
  onLoad: () => void;
}

export const SignatureIframe: FC<SignatureIframeProps> = ({ url, onLoad }) => {
  return <iframe title='signature-frame' src={url} className={cx('iframe')} onLoad={onLoad} />;
};
