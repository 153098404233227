const regExps = {
  username: new RegExp(/^[A-Za-zÁÉÍÓÚÀÈÌÒÙÄËÏÖÜÂÊÎÔÛáéíóúàèìòùäëïöüâêîôûçÇñÑ\-· ]+$/g),
  email: new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  ),
  phone: new RegExp(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]{1,11}$/g),
  dni: new RegExp(/^[0-9]{8}[A-Z]$/i),
  nie: new RegExp(/^[XYZ][0-9]{7}[A-Z]$/i),
  password: new RegExp(/^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])((?=.*\W)|(?=.*_)).{8,}$/),
  postal_code: new RegExp(/^[0-9\s]{5}$/g),
  city: new RegExp(/^[A-Za-zÁÉÍÓÚÀÈÌÒÙÄËÏÖÜÂÊÎÔÛáéíóúàèìòùäëïöüâêîôûçÇñÑ\-· ]{1,60}$/g),
  country: new RegExp(/^[A-Za-zÁÉÍÓÚÀÈÌÒÙÄËÏÖÜÂÊÎÔÛáéíóúàèìòùäëïöüâêîôûçÇñÑ\-· ]{1,60}$/g),
  iban: new RegExp(/^(?:[A-Z]{2}[0-9]{2})(?:[A-Z0-9]{4})+$/)
};

export const validate = (
  value: string,
  validation:
    | 'dni'
    | 'email'
    | 'name'
    | 'password'
    | 'phone'
    | 'Direcci_n'
    | 'Codigo_postal'
    | 'Ciudad'
    | 'Pa_s'
    | 'iban',
): boolean => {
  switch (validation) {
    case 'dni':
      return Boolean(value.match(regExps.dni) || value.match(regExps.nie));
    case 'email':
      return Boolean(value.match(regExps.email));
    case 'name':
      return Boolean(value.match(regExps.username) && value.length <= 50);
    case 'password':
      return Boolean(value.match(regExps.password));
    case 'phone':
      return Boolean(value.match(regExps.phone));
    case 'Direcci_n':
      return Boolean(true);
    case 'Codigo_postal':
      return Boolean(value.match(regExps.postal_code));
    case 'Ciudad':
      return Boolean(value.match(regExps.city));
    case 'Pa_s':
      return Boolean(value.match(regExps.country));
    case 'iban':
      return Boolean(value.match(regExps.iban));
  }
};

export const isValidDni = (dni: string): boolean => {
  return Boolean(dni.match(regExps.dni) || dni.match(regExps.nie));
};

export const isValidEmail = (email: string): boolean => {
  return Boolean(email.match(regExps.email));
};

export const isValidPassword = (password: string): boolean => {
  // min 8 characters, max 10 a lowercase, an uppercase, a number and a special character
  return Boolean(password.match(regExps.password));
};

export const isValidUserName = (username: string): boolean => {
  return Boolean(username.match(regExps.username));
};

export const matchPasswords = (password: string, confirmation: string): boolean => {
  return password === confirmation;
};