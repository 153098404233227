import { PopupOptions, PopupOptionsSetter } from '../../ts';
import { createNewBuffer } from './create-new-buffer';

export const convertPopupOptionsSetterToPopupOptions = (
  popupOptionsSetter: PopupOptionsSetter,
  previousBuffer: PopupOptions['buffer'],
): PopupOptions => {
  const newBuffer = createNewBuffer(
    previousBuffer ?? [],
    popupOptionsSetter.buffer ?? [],
    popupOptionsSetter.setBufferAction ?? 'push',
  );

  return {
    isOpen: true,
    isKeepOpenForced: popupOptionsSetter.isOpenForced ?? false,
    isClickOutsideDisabled: popupOptionsSetter.disableClickOutside ?? false,
    title: popupOptionsSetter.title,
    children: popupOptionsSetter.children,
    buttons: popupOptionsSetter.buttons,
    onClose: popupOptionsSetter.onClose,
    isXl: popupOptionsSetter.isXl,
    buffer: newBuffer,
  };
};
