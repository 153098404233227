import { useState, useEffect } from 'react';
import { INewPatient } from '../../hexagonal-architecture-frontend-base/src/domain/models/ITutor';
import { type TutorInfoErrors } from '../../hexagonal-architecture-frontend-base/src/domain/models/User';
import { validate } from '../../hexagonal-architecture-frontend-base/src/domain/services/user.service';
import { useSession, useSetPopup, useShowMobileVerificationOpup, useUpdateUser } from '../';

export const useEditProfile = () => {
  const { userInfo: user } = useSession();
  const { handleUpdateUser } = useUpdateUser();
  const [isEditing, setIsEditing] = useState(false);
  const { showMobileVerificationPopup } = useShowMobileVerificationOpup();
  const { closePopup } = useSetPopup();
  // Setter => no return
  const [isErrorInFields, setIsErrorInFields] = useState<boolean>(true);
  // Value => no return & Setter => no return
  const [userBackup, setUserBackup] = useState<INewPatient>({
    name: user?.name ?? '',
    dni: user?.dni ?? '',
    mobilePhone: user?.phone ?? '',
    email: user?.email ?? '',
  });
  // Value => no return & Setter => no return
  const [userEdit, setUserEdit] = useState<INewPatient>({
    name: user?.name ?? null,
    dni: user?.dni ?? null,
    mobilePhone: user?.phone ?? '',
    email: user?.email ?? '',
  });
  // Setter => no return
  const [errorTutorMessage, setErrorTutorMessage] = useState<TutorInfoErrors>({
    nameErr: '',
    dniErr: '',
    emailErr: '',
  });
  // Setter => no return

  useEffect(() => {
    if (user == null) return;

    const userInfo: INewPatient = {
      name: user.name,
      dni: user.dni,
      mobilePhone: user.phone ?? '',
      email: user.email,
    };

    setUserBackup(userInfo);
    setUserEdit(userInfo);
  }, [user]);

  const handleStartEdit = () => {
    setIsEditing(true);
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    setUserEdit(userBackup);
    setErrorTutorMessage({
      nameErr: '',
      dniErr: '',
      emailErr: '',
    });
  };

  const handleUpdateUserInfo = async () => {
    // setIsEditing(false);
    if (user == null) return;

    const newDni = userEdit.dni ?? null;
    const newName = userEdit.name;
    const newPhone = userEdit.mobilePhone ?? '';
    const newEmail = userEdit.email ?? null;

    const updateUser = async (smsCode?: string) => {
      await handleUpdateUser(
        { newDni, newName, newPhone, newEmail, smsCode },
        {
          onSuccess: () => {
            setIsErrorInFields(true);
            setIsEditing(false);
            closePopup();
          },
        },
      );
    };

    if (newPhone !== user.phone) {
      showMobileVerificationPopup({
        mobile: newPhone,
        handleSubmit: updateUser,
      });

      return;
    }

    await updateUser();
  };

  const handleTutorValidation = (field: string, value: string) => {
    if (value) {
      const errMsg: string = tutorFieldsDefinition.filter(item => item.field === field)[0].error;
      const fieldToSet = `${field}Err`;
      const validation = validate(value, field as 'dni' | 'phone' | 'name');
      setErrorTutorMessage(error => ({
        ...error,
        [fieldToSet]: validation ? '' : errMsg,
      }));
      setIsErrorInFields(!validation);
    }
    const newUser = structuredClone(userEdit) as INewPatient;
    const parsedField = field === 'phone' ? 'mobilePhone' : field;
    newUser[parsedField as 'name' | 'dni' | 'mobilePhone'] = value;
    setUserEdit(newUser);
  };

  const tutorFieldsDefinition = [
    {
      field: 'name',
      error: 'errors.noNumbersSpecCharsAllowedMax50',
      onChange: (e: string) => handleTutorValidation('name', e),
      title: 'fullName',
      value: userEdit.name ?? '',
    },
    {
      field: 'phone',
      error: 'errors.phoneInvalid',
      onChange: (e: string) => handleTutorValidation('phone', e),
      title: 'phoneno',
      value: userEdit.mobilePhone,
    },
    {
      field: 'dni',
      error: 'errors.dniInvalid',
      onChange: (e: string) => handleTutorValidation('dni', e),
      title: 'dni',
      value: userEdit.dni ?? '',
    },
    {
      field: 'email',
      error: 'errors.emailInvalid',
      onChange: (e: string) => handleTutorValidation('email', e),
      title: 'email',
      value: userEdit.email ?? '',
    },
  ];

  const areNewFieldsDifferent =
    userEdit.name !== userBackup.name ||
    userEdit.dni !== userBackup.dni ||
    userEdit.mobilePhone !== userBackup.mobilePhone ||
    userEdit.email !== userBackup.email;

  return {
    isEditing,
    setIsEditing,
    isSaveTutorButtonDisabled: isErrorInFields || !areNewFieldsDifferent,
    errorTutorMessage,
    handleStartEdit,
    handleCancelEdit,
    handleUpdateUser: handleUpdateUserInfo,
    tutorFieldsDefinition,
  };
};
