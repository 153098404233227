import { useSetPopup } from '../use-popup';
import { PopupOptionsSetter } from '../../../ts';
import { useTranslation } from 'react-i18next';
import { AddPatientFromBst } from '../../../components';

export const useAddPatientFromBstPopup = () => {
  const { t } = useTranslation();
  const { openPopup } = useSetPopup();

  const popupOptions: PopupOptionsSetter = {
    children: <AddPatientFromBst />,
    title: t('modal.addPatientFromBst.title'),
    disableClickOutside: true,
  };

  const showAppPatientFromBstPopup = () => {
    openPopup(popupOptions);
  };

  return { showAppPatientFromBstPopup };
};
