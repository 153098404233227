import { MutateOptions } from '@tanstack/query-core';
import { useAtomValue } from 'jotai';
import { QUOTE_PAYMENT_TYPES } from '../../../consts';
import { updateQuotePaymentTypeAtom } from '../../../store/global/atoms';
import { ApiResponse, UpdateQuotePaymentTypeParams } from '../../../ts';

type Options = MutateOptions<ApiResponse<any>, Error, UpdateQuotePaymentTypeParams>;

export const useUpdateQuotePaymentType = () => {
  const updateQuotePaymentTypeBase = useAtomValue(updateQuotePaymentTypeAtom);

  const updateQuotePaymentType = {
    transfer: (quoteId: string, options?: Options) =>
      updateQuotePaymentTypeBase.mutate(
        { quoteId, paymentType: QUOTE_PAYMENT_TYPES.TRANSFER },
        options,
      ),
    receipt: (quoteId: string, options?: Options) =>
      updateQuotePaymentTypeBase.mutate(
        { quoteId, paymentType: QUOTE_PAYMENT_TYPES.RECEIPT },
        options,
      ),
  };

  return {
    updateQuotePaymentTypeResult: updateQuotePaymentTypeBase.data,
    updateQuotePaymentType,
    isLoading: updateQuotePaymentTypeBase.isPending,
    error: updateQuotePaymentTypeBase.error,
  };
};
