import { atomEffect } from 'jotai-effect';
import { updatePatientAtom } from '../put';
import { patientsAtom } from '../patients-atoms';

export const afterUpdatePatientEffect = atomEffect((get, set) => {
  const newPatient = get(updatePatientAtom);

  if (newPatient.data == null || newPatient.isPending || newPatient.isError) return;

  const newPatientData = newPatient.data.newValues;

  const patientsList = get.peek(patientsAtom);

  const updatedPatients = patientsList.map(patient => {
    if (patient.id !== newPatientData.id) return patient;

    return {
      ...patient,
      dni: newPatientData.dni,
    };
  });

  set(patientsAtom, updatedPatients);
});
