import styles from './user-profile.module.css';
import classNames from 'classnames/bind';
import { useEditProfile } from '../../hooks/profile/use-edit-profile';
import { useUpdateUser } from '../../hooks/use-user/use-update-user';
import { useTranslation } from 'react-i18next';
import { TutorTitle } from './tutor-title/tutor-title';
import { TutorFields } from './tutor-fields/tutor-fields';
import { TutorButtons } from './tutor-buttons/tutor-buttons';
import RegularSpinner from '../Spinner/RegularSpinner';
import { TopMenu } from '../Navigation';
import { GlobalService } from '../../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import { LanguageSelector } from '../../components/language';
import { useSession, useShowErrorPopup } from '../../hooks';
import { useEffect } from 'react';
import { SignoutButton } from '../Buttons/signout-button/signout-button';

const cx = classNames.bind(styles);

export const UserProfile = () => {
  const {
    isEditing,
    isSaveTutorButtonDisabled,
    errorTutorMessage,
    handleStartEdit,
    handleCancelEdit,
    handleUpdateUser,
    tutorFieldsDefinition,
  } = useEditProfile();
  const { t } = useTranslation();
  const { userInfo } = useSession();
  const { isLoading: isUserLoading, error: updateUserError } = useUpdateUser();
  const { showErrorPopup } = useShowErrorPopup();

  const pageTitle = `${GlobalService.uppercaseFirstLetter(t('hi'))},`;

  const handleClickUpdateUser = async () => {
    await handleUpdateUser();
  };

  useEffect(() => {
    if (updateUserError) {
      showErrorPopup(updateUserError.message);
    }
  }, [updateUserError]);

  return (
    <div className={cx('container')}>
      <TopMenu title={pageTitle} userName={userInfo?.name} />

      <div className={cx('inner-container')}>
        <div className={cx('top-navbar')}>
          <TutorTitle />
          <LanguageSelector />
        </div>

        {isUserLoading ? (
          <RegularSpinner />
        ) : (
          <div className={cx('tutor-info--container')}>
            <div className={cx('tutor-info--card', { 'tutor-info--card__editing': isEditing })}>
              <TutorFields
                tutorFieldsDefinition={tutorFieldsDefinition}
                isEditing={isEditing}
                errorTutorMessage={errorTutorMessage}
              />

              <TutorButtons
                isEditing={isEditing}
                isSaveTutorButtonDisabled={isSaveTutorButtonDisabled}
                handleClickEdit={handleStartEdit}
                handleCancelEdit={handleCancelEdit}
                handleClickUpdateUser={handleClickUpdateUser}
              />
            </div>

            <SignoutButton />
          </div>
        )}
      </div>
    </div>
  );
};
