import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './popup.module.css';
import classNames from 'classnames/bind';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { useClickOutside, usePopup } from '../../hooks';
import { useRef } from 'react';
import { Button } from '../Buttons/button/button';

const cx = classNames.bind(styles);

export const Popup = () => {
  const { isOpen, title, children, buttons, isClickOutsideDisabled, closePopup, onClose, isXl } =
    usePopup();
  const popupRef = useRef<HTMLDialogElement>(null);

  const handleClose = () => {
    onClose && onClose();
    closePopup();
  };

  useClickOutside(popupRef, () => {
    if (isClickOutsideDisabled) return;
    handleClose();
  });

  if (!isOpen) return null;

  return (
    <div className={cx('main-container')}>
      <dialog className={cx('popup', { popup__xl: isXl })} ref={popupRef} open>
        <div className={cx('popup--header')}>
          <h2 className={cx('popup--header--title')}>{title}</h2>

          <FontAwesomeIcon
            className={cx('poput--header--close')}
            icon={faXmark}
            onClick={() => handleClose()}
          />
        </div>

        <div className={cx('popup--body')}>
          <div className={cx('popup--body--content')}>{children}</div>

          {buttons != null && buttons.length > 0 ? (
            <div className={cx('popup--body--buttons')}>
              {buttons.map((button, index) => (
                <Button key={index} {...button}></Button>
              ))}
            </div>
          ) : null}
        </div>
      </dialog>
    </div>
  );
};
