import Input from '../../../Inputs/InputOld';
import { GlobalService } from '../../../../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface AddressInputProps {
  id: string;
  name: string;
  value: string | number;
  placeholder: string;
  customClass: string;
  onChange: (e: string) => void;
  errorMessage: string;
}

export const AddressInput: FC<AddressInputProps> = ({
  id,
  name,
  value,
  placeholder,
  customClass,
  onChange,
  errorMessage,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <label htmlFor={id} className='block text-sm font-medium text-gray-700 purple-text'>
        {name ? GlobalService.uppercaseFirstLetter(t(name)) : ''}
      </label>
      <div className='mt-1'>
        <Input
          id={id}
          name={id}
          placeholder={placeholder}
          type={'text'}
          value={value as string}
          customClass={customClass}
          onChange={onChange}
        />
        {errorMessage !== '' ? <p className='error-message my-2 mx-4'>{errorMessage}</p> : null}
      </div>
    </div>
  );
};
