import { AddressData } from '../../ts';

export const DEFAULT_ADDRESS: AddressData = {
  Pa_s: '',
  Codigo_postal: '',
  Direcci_n: '',
  id: '',
  Ciudad: '',
  Name: 'Principal',
};
