import '../../styles/Personas/Personas.css';
import styles from './visits.module.css';
import classNames from 'classnames/bind';

import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// Components
import { ButtonBase } from '../../components/Buttons/button-base';
import { Pagination } from '../../components/Pagination';
import RegularSpinner from '../../components/Spinner/RegularSpinner';
import { Button, GoBackArrow, TopMenu } from '../../components';

// Services
import { GlobalService } from '../../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import { useTranslation } from 'react-i18next';

// Interfaces
import { IPatientVisit } from 'src/hexagonal-architecture-frontend-base/src/domain/models/IPersona';

// Images

// Hooks
import { useViewport } from '../../hooks/use-viewport';
import { usePatientsValue, useSelectedPatient, useShowErrorPopup } from '../../hooks';
import { AvailableSetting, PatientVisit } from '../../ts';

const cx = classNames.bind(styles);

export const Visits = () => {
  const { patientId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { patients, visitsError, isLoading } = usePatientsValue();
  const { selectedPatient, pendingVisits, nonPendingVisits, setSelectedPatient } =
    useSelectedPatient();
  const { showErrorPopup } = useShowErrorPopup();

  /**********
   * States *
   **********/
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageDone, setCurrentPageDone] = useState(1);
  const [longView, setLongView] = useState<string[]>([]);

  const [showPendingVisits, setShowPendingVisits] = useState<boolean>(true);
  const [totalPages, setTotalPages] = useState<number>(1);
  const { viewportWidth } = useViewport();

  const visitas = (showPendingVisits ? pendingVisits : nonPendingVisits) ?? [];

  useEffect(() => {
    if (visitsError != null) {
      showErrorPopup(`errors.${visitsError.message}`);
    }
  }, [visitsError]);

  /*********
   * Hooks *
   *********/

  useEffect(() => {
    setSelectedPatient(patientId as string);
  }, [patients]);

  /************
   * Handlers *
   ************/

  const handlePagination = (e: number) => {
    if (showPendingVisits) setCurrentPage(e);
    else setCurrentPageDone(e);
    // setLoading(true);
  };

  const handleRequestServiceClick = (key: AvailableSetting) => {
    navigate('/user/help');
    // const link = getSettingKey(key);
    // if (link && link.length && link !== 'settingNotFound') window.open(link, '_blank');
    // else {
    //   setErrorText('urlRedirectionNotFound');
    //   setShowErrorModal(true);
    // }
  };

  /***********
   * Helpers *
   ***********/
  const setVisitVisibility = (index: string) => {
    if (longView.includes(index)) {
      setLongView(longView.filter(item => item !== index));
    } else {
      setLongView([...longView, index]);
    }
  };

  /**************
   * JSX Values *
   **************/
  const isScreenBig = viewportWidth > 768;
  const isScreenTiny = viewportWidth < 321;

  const pageTitle = `${GlobalService.uppercaseFirstLetter(t('historyOf'))}:`;
  const pendingLabel = GlobalService.uppercaseFirstLetter(t('pending'));
  const doneLabel = GlobalService.uppercaseFirstLetter(t('done'));
  const noData = <p>{GlobalService.uppercaseFirstLetter(t('nodata'))}</p>;

  const pendingDoneLabels = (
    <div className={`flex flex-row Personas-Title space-between gap-8`}>
      {showPendingVisits ? (
        <div>
          <p className={cx('cursor-pointer', 'px-2')}>{pendingLabel}</p>
          <div className={'Personas-Visitas-Menu-Selected'} />
        </div>
      ) : (
        <p className={cx('cursor-pointer', 'px-2')} onClick={() => setShowPendingVisits(true)}>
          {pendingLabel}
        </p>
      )}
      {!showPendingVisits ? (
        <div>
          <p className={cx('cursor-pointer', 'px-2')}>{doneLabel}</p>
          <div className={`Personas-Visitas-Menu-Selected${isScreenTiny ? '-Tiny' : ''}`} />
        </div>
      ) : (
        <p className={cx('cursor-pointer', 'px-2')} onClick={() => setShowPendingVisits(false)}>
          {doneLabel}
        </p>
      )}
    </div>
  );

  const visitasTitlesValues = [
    ['date', 'Fecha_de_la_visita'],
    ['specialist', 'Doctor'],
    ['type', 'Tipo_de_visita'],
    ['treatment', 'Tratamiento'],
    ['status', 'Estado_de_la_visita'],
    ['comments', 'Notas_del_dentista'],
  ];
  const visitaButtons = (
    <div className='flex flex-col gap-4 mt-6 w-full'>
      <Button onClick={() => handleRequestServiceClick('cambiarVisitaSurvey')} fullWidth={true}>
        {GlobalService.uppercaseFirstLetter(t('pages.visit.changeVisit'))}
      </Button>

      <Button
        styleType='secondary'
        onClick={() => handleRequestServiceClick('solicitarCancelacionSurvey')}
        fullWidth={true}
      >
        {GlobalService.uppercaseFirstLetter(t('pages.visit.requestCancellation'))}
      </Button>
    </div>
  );

  const formattedLongVisita = (visita: IPatientVisit) => {
    return visitasTitlesValues.map((item: string[], index: number) => {
      let value = visita[item[1] as keyof PatientVisit] as string;

      item[1] === 'Doctor' && (value = visita.Doctor.name);

      (value == null || value === '') && (value = '-');

      return (
        <div key={`field${index}`} className={index ? 'mt-3' : ''}>
          <p className='Personas-Visitas'>
            {GlobalService.uppercaseFirstLetter(t(`pages.visit.${item[0]}`))}:{' '}
          </p>
          <p className={`${isScreenBig ? '' : 'text-base'}`}>
            <strong>{value as string}</strong>
          </p>
        </div>
      );
    });
  };

  const formattedVisitas =
    visitas.length > 0
      ? visitas.map((visita: IPatientVisit) => (
          <div
            key={visita.VisitId}
            className={`bg-white p-4 relative ${
              isScreenBig
                ? 'w-full rounded-md'
                : 'Personas-Card-Gray rounded-lg ml-2 mb-3 mr-2 drop-shadow-lg text-left text-sm'
            }`}
          >
            {formattedLongVisita(visita)}

            {isScreenBig && showPendingVisits
              ? visitaButtons
              : longView.includes(visita.VisitId)
              ? visitaButtons
              : ''}

            {isScreenBig || !showPendingVisits ? (
              ''
            ) : (
              <div className='text-center p-3 underline underline-offset-4'>
                <p onClick={() => setVisitVisibility(visita.VisitId)}>
                  {GlobalService.uppercaseFirstLetter(
                    t(longView.includes(visita.VisitId) ? 'seeLess' : 'seeMore'),
                  )}
                </p>
              </div>
            )}
          </div>
        ))
      : noData;

  const backLink = `/datosDocumentos/${patientId}/1`;

  // Responsive values
  const responsivePageTitle = GlobalService.uppercaseFirstLetter(t('pages.visit.responsiveTitle'));

  return (
    <div className={cx('main-container')}>
      <TopMenu
        title={isScreenBig ? pageTitle : responsivePageTitle}
        userName={isScreenBig ? selectedPatient.title : undefined}
        leftIcon={!isScreenBig}
        leftIconLink={backLink}
      />

      {isScreenBig ? (
        <div className={cx('title--container')}>
          <GoBackArrow to={backLink} />

          <h2 className={cx('title--text')}>
            {GlobalService.uppercaseFirstLetter(t('pages.visit.title'))}
          </h2>
        </div>
      ) : null}

      <div className={cx('inner-container')}>
        <div className={cx('visits-menu')}>
          {pendingDoneLabels}

          <Button
            className={cx('request-service-btn')}
            onClick={() => handleRequestServiceClick('solicitarServicioSurvey')}
          >
            {GlobalService.uppercaseFirstLetter(t('pages.visit.requestService'))}
          </Button>
        </div>

        <div className='grid grid-cols-1 md:grid-cols-2 gap-6 w-full'>
          {isLoading ? <RegularSpinner /> : formattedVisitas}
        </div>

        {totalPages > 1 && showPendingVisits ? (
          <div className='m-auto'>
            <Pagination
              totalPages={totalPages}
              currentPage={currentPage}
              onChange={handlePagination}
            />
          </div>
        ) : null}

        {totalPages > 1 && !showPendingVisits ? (
          <div className='m-auto'>
            <Pagination
              totalPages={totalPages}
              currentPage={currentPageDone}
              onChange={handlePagination}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};
