import { useAtom, useAtomValue } from 'jotai';
import {
  betweenBlocksIntervalAtom,
  firstSMSTimestampAtom,
  timeLeftForNextBlockAtom,
} from '../../../../store/global/atoms';
import { useEffect, useLayoutEffect } from 'react';
import { BLOCK_TIME } from '../../../../consts';

export const useBetweenBlocksIntervalEffect = () => {
  const [timeLeftForNextBlock, setTimeLeftForNextBlock] = useAtom(timeLeftForNextBlockAtom);
  const [betweenBlocksInterval, setBetweenBlocksInterval] = useAtom(betweenBlocksIntervalAtom);
  const firstSMSTimestamp = useAtomValue(firstSMSTimestampAtom);

  useLayoutEffect(() => {
    if (firstSMSTimestamp == null) return;

    const updateTimeForNextBlock = () => {
      const now = new Date().getTime();
      const blockStart = new Date(firstSMSTimestamp).getTime();
      const timeSinceBlockStart = now - blockStart;
      let timeLeft = BLOCK_TIME - timeSinceBlockStart;
      timeLeft = Math.floor(timeLeft / 1000);
      if (timeLeft < 0) timeLeft = 0;

      setTimeLeftForNextBlock(timeLeft);
    };

    const interval = setInterval(updateTimeForNextBlock, 100);

    updateTimeForNextBlock();
    setBetweenBlocksInterval(() => interval);

    return () => clearInterval(interval);
  }, [firstSMSTimestamp]);

  useEffect(() => {
    if (
      (firstSMSTimestamp == null || timeLeftForNextBlock === 0) &&
      betweenBlocksInterval != null
    ) {
      clearInterval(betweenBlocksInterval);
      setBetweenBlocksInterval(null);
    }
  }, [firstSMSTimestamp, timeLeftForNextBlock]);

  useEffect(() => {
    if (firstSMSTimestamp == null && betweenBlocksInterval == null) {
      setTimeLeftForNextBlock(-1);
    }
  }, [firstSMSTimestamp, betweenBlocksInterval]);
};
