import { atom } from 'jotai';
import { QUERY_KEYS } from '../../../../consts';
import { getBstInfoService } from '../../../../services';
import { getAtomWithMutation } from '../../../../utils';
import { setTutorToPatientInBstService } from '../../../../services/bst/set-tutor-to-patient-in-bst.service';

export const blindSignupTokenAtom = atom<string | undefined>(undefined);

export const getBstInfoAtom = getAtomWithMutation({
  mutationKey: QUERY_KEYS.BST.GET_INFO,
  mutationFn: () => getBstInfoService,
});

export const setTutorToPatientInBstAtom = getAtomWithMutation({
  mutationKey: QUERY_KEYS.BST.UPDATE_PATIENT_TUTOR,
  mutationFn: () => setTutorToPatientInBstService,
});
