import styles from './tutor-fields.module.css';
import classNames from 'classnames/bind';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { GlobalService } from '../../../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
// import Input from '../../Inputs/InputOld';
import { TutorInfoErrors } from '../../../hexagonal-architecture-frontend-base/src/domain/models/User';
import { Input } from '../../../components/Inputs';

const cx = classNames.bind(styles);

interface TutorFieldsDefinition {
  field: string;
  error: string;
  onChange: (e: string) => void;
  title: string;
  value: string | null;
}

interface TutorFieldsProps {
  tutorFieldsDefinition: TutorFieldsDefinition[];
  isEditing: boolean;
  errorTutorMessage: TutorInfoErrors;
}

export const TutorFields: FC<TutorFieldsProps> = ({
  tutorFieldsDefinition,
  isEditing,
  errorTutorMessage,
}) => {
  const { t } = useTranslation();

  return (
    <div className={cx('container')}>
      {tutorFieldsDefinition.map((field, index) => {
        const title =
          field.field === 'dni'
            ? t(field.title).toUpperCase()
            : GlobalService.uppercaseFirstLetter(t(field.title));

        return (
          <div
            key={index}
            className={cx('input-container', { 'input-container__editing': isEditing })}
          >
            {!isEditing ? (
              <>
                <p className={cx('label')}>{title}: </p>

                <p className={cx('content')}>
                  {field.value?.substring(0, 50)}
                  {field.value?.length && field.value.length > 50 ? '...' : ''}
                </p>
              </>
            ) : (
              <Input
                id={field.field}
                name={field.field}
                label={title}
                placeholder={title}
                type='text'
                value={field.value || ''}
                onChange={field.onChange}
                errorMessage={errorTutorMessage[`${field.field}Err` as keyof TutorInfoErrors]}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};
