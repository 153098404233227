import styles from './pages-layout.module.css';
import classNames from 'classnames/bind';
import { FC } from 'react';
import { TopMenuBase } from '../../../components/Navigation';
import { useLocation } from 'react-router-dom';

const cx = classNames.bind(styles);

const STRAIGHTFORWARD_ROUTES = ['/changePassword', '/login', '/resetPassword', '/signup'];

interface PagesLayoutProps {
  children: React.ReactNode;
}

export const PagesLayout: FC<PagesLayoutProps> = ({ children }) => {
  const location = useLocation();

  if (STRAIGHTFORWARD_ROUTES.includes(location.pathname)) {
    return <>{children}</>;
  }

  return (
    <div className={cx('main-container')}>
      <TopMenuBase />

      <div className={cx('body-container')}>
        <div className={cx('scroll-container')}>{children}</div>
      </div>
    </div>
  );
};
