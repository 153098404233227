import axios from 'axios';
import { LOCAL_STORAGE_KEYS } from '../../../../../consts/local-storage-keys';

// Services
import { authServices } from '../../../infrastructure/services/auth.services';
import { PatientsService } from '../../../domain/services/patients.service.deprecated';
import { ActiveUserService } from '../../../domain/services/activeUser.service';
import { IAuth } from '../../../domain/models/ITutor';

// TODO - Move AXIOS to a hook
// @ts-ignore
axios.defaults.baseURL = process.env.REACT_APP_BASE_API_URL;

const PAGES_REQUIRE_AUTH = [
  'users/me',
  'patients',
  'products',
  'categories',
  'stripe',
  'cart',
  'orders',
  'visits',
  'addresses',
  'invoices',
  'services',
  'notifications',
  'signdoc'
];

const PAGES_NO_REFRESH_ON_401 = ['login', 'signup'];

// Request interceptors
axios.interceptors.request.use(
  config => {
    if (PAGES_REQUIRE_AUTH.find(page => config.url?.includes(page)) != null) {
      const auth = window.localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH);

      if (auth) {
        const token = (JSON.parse(auth) as IAuth).accessToken;
        config.headers['Authorization'] = `Bearer ${token}`;
      }
      return config;
    } else return config;
  },
  error => {
    Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (401 === error.response.status) {
      if (PAGES_NO_REFRESH_ON_401.find(page => window.location.pathname.includes(page)) == null) {
        ActiveUserService.reloadCache();
        PatientsService.reloadCache();
        authServices().logout();
        window.location.replace('/login');
      }
    }
    return Promise.reject(error);
  }
);

const headers = {
  'Content-Type': 'application/json'
};

const get = async <T>(url: string) => {
  const response = await axios({
    url,
    method: 'GET',
    headers
  });
  return response.data as T;
};

const post = async <T>(url: string, body: any) => {
  const response = await axios({
    url,
    method: 'POST',
    headers,
    data: body
  });

  return response?.data as T;
};

const put = async <T>(url: string, body: any) => {
  const response = await axios({
    data: body,
    headers,
    method: 'PUT',
    url
  });
  return response.data as T;
};

const patch = async <T>(url: string, body: any) => {
  const response = await axios({
    data: body,
    headers,
    method: 'PATCH',
    url
  });
  return response.data as T;
};

const cleanGet = async (url: string, params?: object) => {
  const response = await axios({
    url,
    params,
    method: 'GET',
    headers
  });
  return response;
};

const altGet = async (url: string, params?: object) => {
  const response = await axios({
    url,
    params,
    responseType: 'blob',
    method: 'GET',
    headers: {
      'Content-Type': 'application/octet-stream'
    }
  });
  return response;
};

const cleanPost = async (url: string, body: any, params?: object) => {
  const result = await axios({
    data: body,
    headers,
    method: 'POST',
    params,
    url
  });

  const toReturn = {
    status: result.status,
    data: result.data
  };
  return toReturn;
};

const cleanDelete = async (url: string, body: any, params?: object) => {
  const result = await axios({
    data: body,
    headers,
    method: 'DELETE',
    params,
    url
  });
  return result;
};
const _delete = async <T>(url: string) => {
  const response = await axios({
    url,
    method: 'DELETE',
    headers
  });
  return response.data as T;
};

export const http = {
  get,
  post,
  put,
  patch,
  delete: _delete,
  cleanDelete,
  cleanGet,
  altGet,
  cleanPost
};
