import styles from './add-patients-from-bst.module.css';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../components/Buttons';
import {
  useBlindSignupToken,
  usePatients,
  useSession,
  useSetPopup,
  useShowErrorPopup,
} from '../../../hooks';

const cx = classNames.bind(styles);

export const AddPatientFromBst = () => {
  const { t } = useTranslation();
  const { bstInfo, setTutorToPatient, isSetTutorToPatientLoading } = useBlindSignupToken();
  const { userInfo } = useSession();
  const { closePopup, setForceKeepOpen } = useSetPopup();
  const { showErrorPopup } = useShowErrorPopup();
  const { getAllPatients } = usePatients();

  const handleAddPatient = async () => {
    if (userInfo == null) return;

    setForceKeepOpen.enable();

    setTutorToPatient(userInfo.id, {
      onSuccess: () => {
        setForceKeepOpen.disable();
        getAllPatients();
        closePopup();
      },
      onError: () => {
        setForceKeepOpen.disable();
        showErrorPopup(t('modal.addPatientFromBst.unexpectedError'));
      },
    });
  };

  return (
    <div className={cx('container')}>
      {t('modal.addPatientFromBst.bodyText', {
        tutorName: userInfo?.name,
        contactName: bstInfo?.contactName,
      })}

      <div className={cx('btns-container')}>
        <Button
          styleType='secondary'
          onClick={() => closePopup()}
          fullWidth={true}
          disabled={isSetTutorToPatientLoading}
        >
          {t('modal.addPatientFromBst.cancel')}
        </Button>
        <Button onClick={handleAddPatient} fullWidth={true} isLoading={isSetTutorToPatientLoading}>
          {t('modal.addPatientFromBst.add')}
        </Button>
      </div>
    </div>
  );
};
