import styles from './pagos.module.css';
import classNames from 'classnames/bind';
import { useNavigate } from 'react-router-dom';

const cx = classNames.bind(styles);

// Components
import RegularSpinner from '../../components/Spinner/RegularSpinner';
import { Button, ItemCard, TopMenu } from '../../components';

// Services
import { GlobalService } from '../../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import { useTranslation } from 'react-i18next';

// Interfaces
// CSS
import '../../styles/Pagos/Pagos.css';

// Hooks
import { usePatientsValue, useSessionValue } from '../../hooks';

export const Pagos = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { userInfo } = useSessionValue();
  const { allQuotes, isLoading: loadingServiciosItems } = usePatientsValue();

  const patientsAccess = !!userInfo?.patientsAccess;

  /**************
   * JSX Values *
   **************/
  document.title = `LuraCare - ${GlobalService.uppercaseFirstLetter(t('pages.pagos.title'))}`;

  return (
    <div className={cx('main-container')}>
      <TopMenu title={GlobalService.uppercaseFirstLetter(t('pages.pagos.title'))} />
      {patientsAccess ? (
        <div className={cx('container')}>
          <p className={cx('title')}>
            <strong>{GlobalService.uppercaseFirstLetter(t('pages.pagos.services'))}:</strong>
          </p>

          <div className={cx('items-container')}>
            {loadingServiciosItems ? (
              <RegularSpinner />
            ) : allQuotes?.length ? (
              allQuotes
                .slice(0, 3)
                ?.map((item: any, index: any) => <ItemCard key={index} patientQuote={item} />)
            ) : (
              <p>{GlobalService.uppercaseFirstLetter(t('nodata'))}</p>
            )}

            {!loadingServiciosItems && allQuotes != null && allQuotes.length > 3 ? (
              <div className='flex items-center justify-center gap-2'>
                <Button
                  styleType='onlyText'
                  className='border-b 3xs:mt-0 md:mt-3'
                  onClick={() => {
                    navigate('/servicios');
                  }}
                >
                  {GlobalService.uppercaseFirstLetter(t('seeMore'))}
                </Button>
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
    </div>
  );
};
