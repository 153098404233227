import { useAtomValue } from 'jotai';
import { selectedPatientAtom } from '../../../store/global/atoms';
import { useEffect } from 'react';
import { useShowSelectedPatientErrorPopup } from '../../../hooks';
import { isVisitPending } from '../../../utils';

export const useSelectedPatientValue = () => {
  const selectedPatient = useAtomValue(selectedPatientAtom);
  const { showSelectedPatientErrorPopup } = useShowSelectedPatientErrorPopup();

  const error = selectedPatient.id === '-1' ? 'patientNotFound' : null;

  useEffect(() => {
    if (error != null) {
      showSelectedPatientErrorPopup();
    }
  }, [selectedPatient]);

  const pendingVisits = selectedPatient.visitsDetails?.filter(visit => isVisitPending(visit));

  const nonPendingVisits = selectedPatient.visitsDetails?.filter(visit => !isVisitPending(visit));

  return { selectedPatient, pendingVisits, nonPendingVisits, error };
};
