import styles from './go-back-arrow.module.css';
import classNames from 'classnames/bind';
import { Link, useNavigate } from 'react-router-dom';
import LeftArrow from '../../../img/PersonaImg/left-arrow.png';
import { FC } from 'react';

const cx = classNames.bind(styles);

interface GoBackArrowProps {
  to?: string | number;
}

export const GoBackArrow: FC<GoBackArrowProps> = ({ to = -1 }) => {
  const navigate = useNavigate();

  if (typeof to === 'number') {
    return (
      <div className={cx('container')}>
        <img src={LeftArrow} alt='Back' className={cx('img')} onClick={() => navigate(to)} />
      </div>
    );
  }

  return (
    <Link to={to} className={cx('container')}>
      <img src={LeftArrow} alt='Back' className={cx('img')} />
    </Link>
  );
};
