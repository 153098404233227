import { useSetAtom } from 'jotai';
import { topMenuPropsAtom } from '../../store/global/atoms/top-menu/top-menu-atoms';
import { TopMenuProps } from '../../ts';

export const useSetTopMenu = () => {
  const setTopMenuProps = useSetAtom(topMenuPropsAtom);

  const setTopMenu = (props: TopMenuProps) => {
    setTopMenuProps(props);
  };

  return { setTopMenuProps: setTopMenu };
};
