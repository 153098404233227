import { Button } from '../../../components/Buttons';
import styles from './bst-set-and-mobile-already-in-use-popup.module.css';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useLoginSignupSwitchLink, useSetPopup } from '../../../hooks';
import { Link } from 'react-router-dom';
import { FC } from 'react';

const cx = classNames.bind(styles);

interface BstSetAndMobileAlreadyInUsePopupProps {
  mobile: string;
}

export const BstSetAndMobileAlreadyInUsePopup: FC<BstSetAndMobileAlreadyInUsePopupProps> = ({
  mobile,
}) => {
  const { t } = useTranslation();
  const { link } = useLoginSignupSwitchLink('signup');
  const { closePopup } = useSetPopup();

  return (
    <div className={cx('container')}>
      <p>{t('pages.signup.bstSetMobileInUse', { mobile })}</p>

      <div className={cx('btn-container')}>
        <Button styleType='secondary' fullWidth={true} onClick={() => closePopup()}>
          {t('cancel')}
        </Button>

        <Link to={link}>
          <Button fullWidth={true} onClick={() => closePopup()}>
            {t('pages.signup.login')}
          </Button>
        </Link>
      </div>
    </div>
  );
};
