import { GlobalService } from '../../../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import Title from '../../../components/Title';
import { useViewport } from '../../../hooks/use-viewport';
import { useTranslation } from 'react-i18next';

export const TutorTitle = () => {
  const { t } = useTranslation();
  const { viewportWidth } = useViewport();

  const isScreenBig = viewportWidth > 768;

  return (
    <div className='flex flex-row justify-between'>
      <Title
        text={`${GlobalService.uppercaseFirstLetter(t('pages.personas.tutorInfo'))}:`}
        color='black'
        size={isScreenBig ? '20px' : '18px'}
      />
    </div>
  );
};
