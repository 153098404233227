import { atom } from 'jotai';
import { atomEffect } from 'jotai-effect';
import { getAllPatientsAtom } from '../getters/get-all-patients-atom';
import { chunkPatientsIds, chunkPatientsNames } from '../../../../../utils';
import { getPatientsDealsAtom } from '../getters/get-patients-deals-atom';
import { getPatientsQuotesAtom } from '../getters/get-patients-quotes-atom';
import { getPatientsVisitsAtom } from '../getters/get-patients-visits-atom';
import { accumulatedPatientsAtom } from '../patients-atoms';

export const isGettingAllPatientsAtom = atom(false);
export const getPatientsExtraInfoAtomEffect = atomEffect((get, set) => {
  const getAllPatients = get(getAllPatientsAtom);

  if (getAllPatients.isPending) return;

  if (getAllPatients.isError || getAllPatients.data == null) {
    set(isGettingAllPatientsAtom, false);
    return;
  }

  set(accumulatedPatientsAtom, getAllPatients.data);

  set(isGettingAllPatientsAtom, true);

  const chunkedPatientsNames = chunkPatientsNames(getAllPatients.data);
  const chunkedPatientsIds = chunkPatientsIds(getAllPatients.data);

  const getAllPatientsDeals = get.peek(getPatientsDealsAtom);
  const getAllPatientsReports = get.peek(getPatientsQuotesAtom);
  const getAllPatientsVisits = get.peek(getPatientsVisitsAtom);

  const extraInfoCalls = [
    getAllPatientsDeals.mutate({ chunkedPatientsNames }),
    getAllPatientsReports.mutate({ chunkedPatientsNames: chunkedPatientsIds }),
    getAllPatientsVisits.mutate({ chunkedPatientsNames }),
  ] as const;

  Promise.allSettled(extraInfoCalls);
});
