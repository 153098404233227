import styles from './help.module.css';
import classNames from 'classnames/bind';
// Components
import { ButtonBase } from '../../components/Buttons/button-base';
import { TopMenu } from '../../components';

// Services
import { GlobalService } from '../../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import { useTranslation } from 'react-i18next';

// CSS
import '../../styles/Help/Help.css';

// Images
import Callcenter from '../../img/HelpImg/helpLuracare.png';

// Hooks
import { useHelpButtons, useSettings, useShowSettingsErrorPopup } from '../../hooks';
import { AvailableSetting } from '../../ts/types/settings/available-setting';

import { AVAILABLE_SETTINGS } from '../../consts';

const cx = classNames.bind(styles);

export const Help = () => {
  const { t } = useTranslation();
  const { getSettingKey } = useSettings();
  const { showSingleSettingErrorPopup } = useShowSettingsErrorPopup();
  const textBase = 'pages.help';
  const { helpButtons } = useHelpButtons(textBase);

  const handleFooterClick = (item: number) => {
    const link1 = getLink(AVAILABLE_SETTINGS.AVISO_LEGAL) ?? null;
    const link2 = getLink(AVAILABLE_SETTINGS.PRIVACIDAD) ?? null;
    const links = [link1, link2];
    const linkValue = links[item];
    if (linkValue) {
      window.open(linkValue, '_blank');
    }
  };

  /***********
   * Helpers *
   ***********/
  const getLink = (key: AvailableSetting): string | undefined => {
    const link = getSettingKey(key);
    if (link === 'settingNotFound') {
      showSingleSettingErrorPopup();
      return '';
    }
    return link;
  };

  /**************
   * JSX Values *
   **************/
  document.title = `LuraCare - ${GlobalService.uppercaseFirstLetter(t(`${textBase}.title`))}`;
  // Icons definitions

  // Footer buttons text definitions
  const footerButtonsTextDefinition = [`${textBase}.legalNotice`, `${textBase}.privacyPolicy`];

  return (
    <div className={cx('main-container')}>
      <TopMenu title={GlobalService.uppercaseFirstLetter(t(`${textBase}.title`))} />

      <div className={cx('inner-container')}>
        <p className={cx('text')}>
          {GlobalService.uppercaseFirstLetter(t(`${textBase}.firstText`))}
        </p>

        <div className={cx('btns-image-container')}>
          <div className={cx('btns-container')}>{helpButtons}</div>

          <div className={cx('img-container')}>
            <img src={Callcenter} className={cx('img')} alt='help image' />
          </div>
        </div>

        <p className={cx('text')}>
          {GlobalService.uppercaseFirstLetter(t(`${textBase}.secondText`))}
        </p>
      </div>

      <div className='flex flex-col sm:flex-row w-full items-center justify-center gap-2'>
        {footerButtonsTextDefinition.map((text: string, index: number) => (
          <ButtonBase
            text={GlobalService.uppercaseFirstLetter(t(text))}
            color='#808080'
            extraClass='underline decoration-solid'
            key={index}
            buttonHeight='25px'
            minHeight='25px'
            paddingX={5}
            paddingY={5}
            onClick={() => handleFooterClick(index)}
          />
        ))}
      </div>
    </div>
  );
};
