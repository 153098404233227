import axios from 'axios';
import type { ApiResponse, UpdatePatientParams } from 'src/ts';

export const updatePatientService = async (data: UpdatePatientParams) => {
  let result: ApiResponse<undefined>;

  try {
    const response = await axios.put<ApiResponse<any>>('/patients/update', data);
    result = response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }

  if (!result.isOk) {
    throw new Error(result.message);
  }

  return { isOk: result.isOk, newValues: data };
};
