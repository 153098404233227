import { useEffect, useState } from 'react';
import { makeObservable } from '../../utils/make-observable';
import { TutorInfo, UpdateUserObject, UserInfo } from '../../ts';
import { convertUserToTutor } from '../../utils';
import { useSession } from '../user-session';
import { useAtomValue } from 'jotai';
import { updateUserAtom } from '../../store/global/atoms';
import { MutateOptions } from '@tanstack/query-core';

const isLoadingStore = makeObservable(false);

interface HandleUpdateUserParams {
  newDni: UserInfo['dni'];
  newName: UserInfo['name'];
  newPhone: UserInfo['phone'];
  newEmail: UserInfo['email'];
  smsCode?: string;
}

export const useUpdateUser = () => {
  const { userInfo, setUserInfo, refreshUser } = useSession();
  const [isLoading, setIsLoading] = useState(isLoadingStore.get());
  const updateUser = useAtomValue(updateUserAtom);

  useEffect(() => {
    return isLoadingStore.subscribe(setIsLoading);
  }, [isLoading, setIsLoading]);

  const reloadUser = async () => {
    isLoadingStore.set(true);
    await refreshUser();
    isLoadingStore.set(false);
  };

  const handleUpdateUser = async (
    { newDni, newName, newPhone, newEmail, smsCode }: HandleUpdateUserParams,
    options?: MutateOptions<TutorInfo, Error, UpdateUserObject, unknown> | undefined,
  ) => {
    if (userInfo == null || isLoading || updateUser.isPending) return false;

    isLoadingStore.set(true);

    const newUser: UserInfo = structuredClone(userInfo);
    newUser.dni = newDni;
    newUser.name = newName;
    newUser.phone = newPhone;
    newUser.email = newEmail;

    setUserInfo(newUser);

    const newUserAsTutor = convertUserToTutor(newUser);

    try {
      const response = await updateUser.mutateAsync({ ...newUserAsTutor, smsCode }, options);

      const updatedUser: UserInfo = {
        ...newUser,
        dni: response.dni as string,
        name: response.name as string,
        phone: response.phone as string,
        email: response.email as string,
      };

      setUserInfo(updatedUser);

      return true;
    } catch (error: any) {
      return false;
    } finally {
      await reloadUser();
      isLoadingStore.set(false);
    }
  };

  return {
    isLoading: isLoading || updateUser.isPending,
    handleUpdateUser,
    error: updateUser.error,
  };
};
