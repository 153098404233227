import styles from './help-button.module.css';
import classNames from 'classnames/bind';
import { Button } from '../button';
import { FC } from 'react';
import { HelpButtonProps } from '../../../ts';
import { HelpButtonLink } from './help-button-link';

const cx = classNames.bind(styles);

export const HelpButton: FC<HelpButtonProps> = ({ icon, link, text, onClick = () => {} }) => {
  return (
    <HelpButtonLink link={link}>
      <Button onClick={onClick} fullWidth={true} styleType='tertiary'>
        <div className={cx('button-content')}>
          {icon}
          {text}
        </div>
      </Button>
    </HelpButtonLink>
  );
};
