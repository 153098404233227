import { useAtom, useAtomValue } from 'jotai';
import {
  canResendSMSAtom,
  limitPerBlockReachedAtom,
  mobileVerificationTimestampsAtom,
  timeLeftForNextBlockAtom,
  timeLeftForNextSMSAtom,
} from '../../../store/global/atoms';
import { RESET } from 'jotai/vanilla/utils';

export const useMobileVerificationTimestamps = () => {
  const [timestamps, setMobileVerificationTimestamps] = useAtom(mobileVerificationTimestampsAtom);

  const timeLeftForNextBlock = useAtomValue(timeLeftForNextBlockAtom);
  const timeLeftForNextSMS = useAtomValue(timeLeftForNextSMSAtom);
  const limitPerBlockReached = useAtomValue(limitPerBlockReachedAtom);
  const canResendSMS = useAtomValue(canResendSMSAtom);

  const setSMSTimestamp = (timestamp: string) => {
    const firstSMSTimestamp = timestamps == null ? timestamp : timestamps.firstSMSTimestamp;
    const lastSMSTimestamp = timestamp;
    const SMSCountSinceFirst = timestamps == null ? 1 : timestamps.SMSCountSinceFirst + 1;
    setMobileVerificationTimestamps({
      firstSMSTimestamp,
      lastSMSTimestamp,
      SMSCountSinceFirst,
    });
  };

  const cleanSMSTimestamps = () => {
    setMobileVerificationTimestamps(RESET);
  };

  return {
    mobileVerificationTimestamps: timestamps,
    setSMSTimestamp,
    cleanSMSTimestamps,
    timeLeftForNextSMS,
    timeLeftForNextBlock,
    limitPerBlockReached,
    canResendSMS,
  };
};
