import { GlobalService } from '../../../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import { Button } from '../button';
import { useTranslation } from 'react-i18next';
import { useShowPopupSignout } from '../../../hooks';
import { FC } from 'react';

interface SignoutButtonProps {
  fullWidth?: boolean;
}

export const SignoutButton: FC<SignoutButtonProps> = ({ fullWidth = false }) => {
  const { t } = useTranslation();
  const { showPopupSignout } = useShowPopupSignout();

  return (
    <Button styleType='error' onClick={showPopupSignout} fullWidth={fullWidth}>
      {GlobalService.uppercaseFirstLetter(t('pages.personas.signout'))}
    </Button>
  );
};
