import '../../styles/Personas/Personas.css';
import styles from './datos-persona-y-servicio.module.css';
import classNames from 'classnames/bind';

import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// Components
import RegularSpinner from '../../components/Spinner/RegularSpinner';
import Title from '../../components/Title';
import { PatientInfoCard, TopMenu } from '../../components';

// Services
import { GlobalService } from '../../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import { useTranslation } from 'react-i18next';

// Images
import LeftArrow from '../../img/PersonaImg/left-arrow.png';
import LeftArrowSmall from '../../img/PersonaImg/left-arrow-short.png';
import AudiologiaGrey from '../../img/PersonaImg/audiologia-grey.png';
import AudiologiaRed from '../../img/PersonaImg/audiologia-grey.png';
import DentistaGrey from '../../img/PersonaImg/dentista-red.png';
import DentistaRed from '../../img/PersonaImg/dentista-red.png';
import OpticaGrey from '../../img/PersonaImg/optica-grey.png';
import OpticaRed from '../../img/PersonaImg/optica-grey.png';

// Hooks
import { useViewport } from '../../hooks/use-viewport';
import { usePatientsValue, useSelectedPatient } from '../../hooks';

const cx = classNames.bind(styles);

interface IDocs {
  avisos: boolean;
  enabled: boolean;
  title: string;
  img: {
    alert: string;
    vanilla: string;
  };
}

export const DatosPersonaYServicio = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { patientId } = useParams();

  /**********
   * States *
   **********/
  const { patients, isLoading: isPatientsLoading } = usePatientsValue();
  const { selectedPatient, setSelectedPatient } = useSelectedPatient();
  const { isScreenBig } = useViewport();

  const documentos: IDocs[] = !selectedPatient.id
    ? []
    : [
        {
          avisos:
            !!selectedPatient.pendingVisits ||
            !!selectedPatient.pendingReports ||
            !!selectedPatient.pendingDocs,
          enabled: true,
          title: 'pages.documentos.dentist',
          img: {
            alert: DentistaRed,
            vanilla: DentistaGrey,
          },
        },
        // {
        //   avisos: false,
        //   enabled: false,
        //   title: 'pages.documentos.audiology',
        //   img: {
        //     alert: AudiologiaRed,
        //     vanilla: AudiologiaGrey,
        //   },
        // },
        // {
        //   avisos: false,
        //   enabled: false,
        //   title: 'pages.documentos.optic',
        //   img: {
        //     alert: OpticaRed,
        //     vanilla: OpticaGrey,
        //   },
        // },
      ];

  /*********
   * Hooks *
   *********/
  useEffect(() => {
    if (patients.length === 0) return;
    // Estableixo el pacient actiu
    setSelectedPatient(patientId as string);
  }, [patients]);

  /************
   * Handlers *
   ************/
  const handleLinkClick = (id: number) => {
    if (documentos[id].enabled) {
      navigate(`/datosDocumentos/${patientId}/${id + 1}`);
    }
  };

  const handleOnClickBack = () => {
    // const from = localStorage.getItem('AllPatients');
    navigate('/personas');
    //   if (from === 'true') {
    //   navigate('/allPatients');
    // } else {
    //   navigate('/personas');
    // }
  };

  /***********
   * Helpers *
   ***********/

  /******************************
   * JSX values (common values) *
   ******************************/
  const formattedDocuments = documentos.map((item, index: number) => (
    <div
      key={index}
      className={`m-auto Personas-Documentos${
        isScreenBig
          ? ''
          : index === documentos.length - 1 && !isScreenBig
          ? '-Small-End flex flex-row'
          : '-Small flex flex-row'
      } Personas-Documentos-${
        (selectedPatient.pendingDocs ||
          selectedPatient.pendingReports ||
          selectedPatient.pendingVisits) &&
        item.enabled
          ? 'Con-Aviso'
          : 'Sin-Aviso'
      }`}
      onClick={() => handleLinkClick(index)}
    >
      <img
        className={`Personas-Documentos-Imagen-${isScreenBig ? 'Big' : 'Small'}`}
        src={item.avisos ? item.img.alert : item.img.vanilla}
      ></img>
      <p className='Personas-Documentos-Texto'>
        {GlobalService.uppercaseFirstLetter(t(item.title))}
      </p>
      {selectedPatient.pendingDocs && item.enabled ? (
        <div
          className={`absolute ${
            isScreenBig ? 'top-4' : 'top-10'
          } right-4 w-3 h-3 rounded-full bg-red-600`}
        ></div>
      ) : null}
    </div>
  ));

  /******************
   * Desktop Values *
   ******************/
  const desktopPageTitle = `${GlobalService.uppercaseFirstLetter(t('historyOf'))}:`;

  const responsivePageTitle = GlobalService.uppercaseFirstLetter(t('patientHistory'));

  /*******
   * JSX *
   *******/
  return (
    <div className={cx('main-container')}>
      <TopMenu
        title={isScreenBig ? desktopPageTitle : responsivePageTitle}
        leftIcon={isScreenBig ? undefined : LeftArrowSmall}
        leftIconLink={'/personas'}
        userName={isScreenBig ? selectedPatient.title : undefined}
      />

      <div className={cx('inner-container')}>
        <div className={cx('services-container')}>
          <div className={cx('services-title--container')}>
            {isScreenBig ? (
              <img
                onClick={handleOnClickBack}
                src={LeftArrow}
                alt='Back'
                className='Notificaciones-Arrow cursor-pointer'
              />
            ) : null}

            <Title
              text={GlobalService.uppercaseFirstLetter(t('services'))}
              color='black'
              size={'24px'}
            />
          </div>

          {isPatientsLoading ? (
            <RegularSpinner />
          ) : (
            <div className={cx('services-wrapper')}>
              {documentos.length ? (
                formattedDocuments
              ) : (
                <p>{GlobalService.uppercaseFirstLetter(t('nodata'))}</p>
              )}
            </div>
          )}
        </div>

        <div className={cx('patient-info-container')}>
          <Title
            text={`${GlobalService.uppercaseFirstLetter(t('patientInfo'))}:`}
            color='black'
            size={'24px'}
            margin='0px'
          />

          {isPatientsLoading ? <RegularSpinner /> : <PatientInfoCard />}
        </div>
      </div>
    </div>
  );
};
