import { useAtomValue } from 'jotai';
import { saveUserSignDataAtom } from '../../store/global/atoms';
import { useSession } from '../user-session';
import { useSelectedPatient } from '../patients';
import { UserSignData } from 'src/ts/interfaces';

export const useUserSignData = () => {
  const saveUserSignData = useAtomValue(saveUserSignDataAtom);
  const { userInfo } = useSession();
  const { selectedPatient } = useSelectedPatient();

  const userSignData: UserSignData = {
    email: userInfo?.email ?? '',
    tutorFullName: userInfo?.name ?? '',
    tutorId: userInfo?.dni ?? '',
    patientCountryId: selectedPatient.dni ?? '',
    patientId: selectedPatient.id,
  };

  const isAllSignDataAvailable = Object.values(userSignData).every(value => value !== '');

  return {
    userSignData,
    isAllSignDataAvailable,
    userSignDataResponse: saveUserSignData.data,
    saveUserSignData: saveUserSignData.mutate,
    saveUserSignDataAsync: saveUserSignData.mutateAsync,
    isLoading: saveUserSignData.isPending,
    error: saveUserSignData.error,
  };
};
