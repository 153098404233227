import { QUERY_KEYS } from '../../../../consts';
import { paymentCheckoutService } from '../../../../services';
import { getAtomWithMutation } from '../../../../utils';
import { userInfoAtom } from '../session-atom';
import { QuotePaymentCheckoutParams } from '../../../../ts';

type MakePaymentCheckoutParams = Omit<Omit<QuotePaymentCheckoutParams, 'userId'>, 'stripeId'>;

export const makeQuotePaymentCheckoutAtom = getAtomWithMutation({
  mutationKey: QUERY_KEYS.PAYMENT.QUOTE_PAYMENT_CHECKOUT,
  mutationFn: get => async (body: MakePaymentCheckoutParams) => {
    console.log('body', body);
    const userInfo = get(userInfoAtom);

    if (userInfo == null) throw new Error('errors.userNotLogged');
    // TODO: Implement this text
    if (userInfo.stripeId == null) throw new Error('errors.userWithoutStripeId');

    const params: QuotePaymentCheckoutParams = {
      ...body,
      userId: userInfo.id,
      stripeId: userInfo.stripeId,
    };

    const response = await paymentCheckoutService(params);

    return response;
  },
});
