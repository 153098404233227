import { useAtomValue } from 'jotai';
import {
  sendMobileVerificationCodeAtom,
  verifyMobileVerificationCodeAtom,
} from '../../store/global/atoms';
import { useMobileVerificationTimestamps } from './use-mobile-verification-timestamps';

export const useVerifyMobile = () => {
  const sendMobileVerificationCodeStatus = useAtomValue(sendMobileVerificationCodeAtom);
  const verifyMobileVerificationCodeStatus = useAtomValue(verifyMobileVerificationCodeAtom);
  const { mobileVerificationTimestamps, setSMSTimestamp, cleanSMSTimestamps, canResendSMS } =
    useMobileVerificationTimestamps();

  const sendSMS: typeof sendMobileVerificationCodeStatus.mutate = (phone, options) => {
    if (!canResendSMS) return;
    const previousTimestamps =
      mobileVerificationTimestamps == null ? null : { ...mobileVerificationTimestamps };
    setSMSTimestamp(new Date().toISOString());

    sendMobileVerificationCodeStatus.mutate(phone, {
      ...options,
      onError: (error, variables, context) => {
        previousTimestamps == null
          ? cleanSMSTimestamps()
          : setSMSTimestamp(previousTimestamps.lastSMSTimestamp);
        options?.onError?.(error, variables, context);
      },
    });
  };

  return {
    dataOfSendSMS: sendMobileVerificationCodeStatus.data,
    sendSMS,
    isSendSMSLoading: sendMobileVerificationCodeStatus.isPending,
    sendSMSError: sendMobileVerificationCodeStatus.error,
    dataOfVerifyCode: verifyMobileVerificationCodeStatus.data,
    verifyCode: verifyMobileVerificationCodeStatus.mutate,
    verifyCodeAsync: verifyMobileVerificationCodeStatus.mutateAsync,
    isVerifyCodeLoading: verifyMobileVerificationCodeStatus.isPending,
    verifyCodeError: verifyMobileVerificationCodeStatus.error,
  };
};
