import { useAtomValue } from 'jotai';
import { getIsMobileRegisteredAtom } from '../../store/global/atoms';

export const useGetIsMobileRegistered = () => {
  const getIsMobileRegistered = useAtomValue(getIsMobileRegisteredAtom);

  return {
    isMobileRegistered: getIsMobileRegistered.data,
    getIsMobileRegistered: getIsMobileRegistered.mutate,
    getIsMobileRegisteredAsync: getIsMobileRegistered.mutateAsync,
    isLoading: getIsMobileRegistered.isPending,
    error: getIsMobileRegistered.error,
    cleanIsMobileRegistered: getIsMobileRegistered.reset,
  };
};
