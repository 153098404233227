import { signRepositoryInstance } from '../../../hexagonal-architecture-frontend-base/src/infrastructure/instances/signRepository';
import { signServices } from '../../../hexagonal-architecture-frontend-base/src/infrastructure/services/sign.service';
import { TemplateIdentifier } from '../../../ts';

const DOC_ID = process.env.REACT_APP_TO_SIGN_TEMPLATE_ID as string;

export const getTemplateInfoService = async ({ docId }: TemplateIdentifier) => {
  const response = await signServices(signRepositoryInstance).getTemplateInfo(docId ?? DOC_ID);
  if (typeof response === 'string') throw new Error(response);

  return response.data;
};
