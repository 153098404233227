import { PopupOptionsSetter } from 'src/ts';
import { useSetPopup } from '../use-popup';
import { GlobalService } from '../../../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import { useTranslation } from 'react-i18next';
import { useSettings } from '../../../hooks/use-settings';
import { AVAILABLE_SETTINGS, ETERNAL_AUTH_SURVEY_PARAMS } from '../../../consts';
import { useSelectedPatient, useSession } from '../../../hooks';
import { formatDateToSurvey } from '../../../utils';

export const useShowEternalReviewAuthPopup = () => {
  const { openPopup, closePopup } = useSetPopup();
  const { t } = useTranslation();
  const { getSettingKey } = useSettings();
  const { selectedPatient } = useSelectedPatient();
  const { userInfo } = useSession();

  const handleButtonClick = () => {
    const zohoSurveyBaseUrl = getSettingKey(AVAILABLE_SETTINGS.ETERNAL_REVIEW_AUTH_SURVEY);

    let zohoSurvey: URL = new URL('https://luracare.es');

    if (zohoSurveyBaseUrl !== 'settingNotFound') {
      zohoSurvey = new URL(zohoSurveyBaseUrl);
      zohoSurvey.searchParams.append(ETERNAL_AUTH_SURVEY_PARAMS.ID, selectedPatient.id);
      zohoSurvey.searchParams.append(
        ETERNAL_AUTH_SURVEY_PARAMS.CARE_HOME_NAME,
        selectedPatient.residence,
      );
      zohoSurvey.searchParams.append(
        ETERNAL_AUTH_SURVEY_PARAMS.PATIENT_NAME,
        selectedPatient.title,
      );
      if (selectedPatient.dni != null) {
        zohoSurvey.searchParams.append(ETERNAL_AUTH_SURVEY_PARAMS.PATIENT_DNI, selectedPatient.dni);
      }
      if (selectedPatient.birthDate != null) {
        zohoSurvey.searchParams.append(
          ETERNAL_AUTH_SURVEY_PARAMS.PATIENT_BIRTH_DATE,
          formatDateToSurvey(selectedPatient.birthDate),
        );
      }
      if (userInfo != null) {
        userInfo.name &&
          zohoSurvey.searchParams.append(ETERNAL_AUTH_SURVEY_PARAMS.TUTOR_LAST_NAME, userInfo.name);
        userInfo.dni &&
          zohoSurvey.searchParams.append(ETERNAL_AUTH_SURVEY_PARAMS.TUTOR_DNI, userInfo.dni);
        userInfo.email &&
          zohoSurvey.searchParams.append(ETERNAL_AUTH_SURVEY_PARAMS.EMAIL, userInfo.email);

        zohoSurvey.searchParams.append(ETERNAL_AUTH_SURVEY_PARAMS.MOBILE, userInfo.phone);
      }
    }

    window.open(zohoSurvey, '_blank');
    closePopup();
  };

  const eternalReviewAuthPopupOptions: PopupOptionsSetter = {
    title: GlobalService.uppercaseFirstLetter(t('pages.documentos.eternalReviewAuth.title')),
    children: (
      <p>
        {GlobalService.uppercaseAfterPointFirstsLetters(
          t('pages.documentos.eternalReviewAuth.explanation'),
        )}
      </p>
    ),
    buttons: [
      {
        children: GlobalService.uppercaseFirstLetter(
          t('pages.documentos.eternalReviewAuth.button'),
        ),
        styleType: 'primary',
        onClick: handleButtonClick,
      },
    ],
  };

  const showEternalReviewAuthPopup = () => openPopup(eternalReviewAuthPopupOptions);

  return { showEternalReviewAuthPopup };
};
