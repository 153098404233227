import { useAtomValue } from 'jotai';
import { signupAtom } from '../../store/global/atoms';

export const useSignup = () => {
  const signupBase = useAtomValue(signupAtom);

  return {
    signupData: signupBase.data,
    signup: signupBase.mutate,
    signupAsync: signupBase.mutateAsync,
    error: signupBase.error,
    isLoading: signupBase.isPending,
    clear: signupBase.reset,
  };
};
