import styles from './notifications-button.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell as faRegularBell } from '@fortawesome/free-regular-svg-icons';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { useRef, useState, type FC } from 'react';
import classNames from 'classnames/bind';
import { useLocation, useNavigate } from 'react-router-dom';
import { GlobalService } from '../../../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import { useTranslation } from 'react-i18next';
import { useClickOutside, useGetNotificationExtraInfo } from '../../../hooks';
import { Notification } from '../../../ts';
import { Button } from '../button';
import { NotificationCard } from './notification-card';
import { NotificationsContanier } from './notifications-container';

const cx = classNames.bind(styles);

interface NotificationsButtonProps {
  numberNotifications: number;
  notifications: Notification[];
  onClickNotificationLink: (id: number) => void;
}

export const NotificationsButton: FC<NotificationsButtonProps> = ({
  numberNotifications,
  notifications,
  onClickNotificationLink,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [showNotifications, setShowNotifications] = useState(false);
  const { t } = useTranslation();
  const notificationsButtonContainerRef = useRef(null);
  const { getNotificationExtraInfo } = useGetNotificationExtraInfo();

  useClickOutside(notificationsButtonContainerRef, () => setShowNotifications(false));

  const isDisabled = location.pathname === '/notificaciones';
  const isScreenBig = window.innerWidth >= 769;

  const handleSeeAllNotifications = () => {
    setShowNotifications(false);
    localStorage.setItem('backRoute', location.pathname);
    navigate('/notificaciones');
  };

  const handleClick = () => {
    if (isDisabled) return;
    if (isScreenBig) {
      setShowNotifications(!showNotifications);
    } else {
      handleSeeAllNotifications();
    }
  };

  return (
    <div className={cx('notifications_button_container')} ref={notificationsButtonContainerRef}>
      <button
        className={cx(
          'bell_container',
          { 'bell_container-colorized': showNotifications },
          { 'bell_container-clicked': showNotifications },
          { 'bell_container-disabled': isDisabled },
        )}
        onClick={handleClick}
      >
        {showNotifications}
        {!showNotifications ? (
          <FontAwesomeIcon icon={faRegularBell} className={cx('bell_icon')} />
        ) : (
          <FontAwesomeIcon
            icon={faRegularBell}
            className={cx('bell_icon', 'bell_icon-colorized')}
          />
        )}

        {numberNotifications > 0 ? (
          <div
            className={cx(
              'notification-advice',
              {
                'notification-advice-100': numberNotifications >= 100,
              },
              'flex',
              'items-center',
              'justify-center',
            )}
          >
            <p>{numberNotifications < 100 ? numberNotifications : '99+'}</p>
          </div>
        ) : null}
      </button>

      {showNotifications ? (
        <NotificationsContanier
          notifications={notifications}
          closeNotifications={() => setShowNotifications(false)}
          onClickNotificationLink={onClickNotificationLink}
          handleSeeAllNotifications={handleSeeAllNotifications}
        />
      ) : null}
    </div>
  );
};
