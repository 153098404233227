const blockTime = 10 * 60 * 1000;
const blockTimeTest = 1 * 20 * 1000;
const smsLimitPerBlock = 5;
const smsLimitPerBlockTest = 2;
const timeBetweenSMS = 1 * 60 * 1000;
const timeBetweenSMSTest = 1 * 5 * 1000;

const isTest = false;

export const BLOCK_TIME = isTest ? blockTimeTest : blockTime;
export const SMS_LIMIT_PER_BLOCK = isTest ? smsLimitPerBlockTest : smsLimitPerBlock;
export const TIME_BETWEEN_SMS = isTest ? timeBetweenSMSTest : timeBetweenSMS;
